import { Component, Input, OnInit } from '@angular/core';
import { BasePageComponent } from '../../shared/components/base-page.component';
import { LocalizerService, ScreenService } from '../../shared';
import { IntercomsManager } from '../services/intercoms-manager';
import { IntercomInput } from '../models/intercom_input';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-inputs-settings',
  templateUrl: './inputs-settings.component.html',
  styleUrl: './inputs-settings.component.scss'
})
export class InputsSettingsComponent extends BasePageComponent implements OnInit {

  @Input() installationId: string | null = null;
  @Input() unitId: string | null = null;
  @Input() intercomId: string | null = null;

  not_configured_intercom_inputs: IntercomInput[] = [];
  configured_intercom_inputs: IntercomInput[] = [];
  selected_intercom_input: IntercomInput | null = null;
  selected_action: string | null = null;

  actions = [
    { name: this.localizer.getLocalizedString('Inputs.FloorCall'), code: 'floorCall' },
  ];

  // utility variables

  isInputActionPopupVisible = false;
  isLoading: boolean = false;
  isAddingAction: boolean = false;

  constructor(private intercomManager: IntercomsManager,
    localizer: LocalizerService,
    screen: ScreenService) {
    super(screen, localizer);
  }

  ngOnInit() {
    if (!this.installationId) {
      this.displayLocalizedErrorNotification('Installations.InvalidInstallationId');
      return;
    }

    if (!this.unitId) {
      this.displayLocalizedErrorNotification('Units.InvalidUnitId');
      return;
    }

    if (!this.intercomId) {
      this.displayLocalizedErrorNotification('Devices.InvalidIntercomId');
      return;
    }

    this.loadIntercomInputs();
  }

  /**
   * This method is called to add the selected intercom input.
   */
  addIntercomInput() {
    if (!this.selected_intercom_input) return;

    this.isLoading = true;
    const content = {
      "address": this.selected_intercom_input!.address,
      "action": { "kind": this.selected_action! },
      "kind": this.selected_intercom_input!.kind
    };

    this.intercomManager.addIntercomInput(this.installationId!, this.unitId!, this.intercomId!, content)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: () => this.apiSuccessAction(),
        error: () => this.displayLocalizedErrorNotification('Inputs.ApiAddIntercomInputActionError')
      });
  }

  /**
   * This method is called to delete the selected intercom input.
   */
  deleteIntercomInput() {
    if (!this.selected_intercom_input) return;
    this.isLoading = true;

    this.intercomManager.deleteIntercomInput(this.installationId!, this.unitId!, this.intercomId!, this.selected_intercom_input?.id!)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: () => this.apiSuccessAction(),
        error: () => this.displayLocalizedErrorNotification('Inputs.ApiDeleteIntercomInputActionError')
      });
  }

  onSelectionChanged(event: any) {
    if (event.selectedRowKeys.length > 0) {
      this.selected_action = event.selectedRowKeys[0];
      this.isAddingAction = true;
    }
  }

  // Utiliy methods

  /**
   * Displays the popup for configuring actions associated with the selected intercom input.
   * @param event The event object that contains the selected intercom input.
   */
  showInputActionPopup(event: any) {
    const input_selected = event.data;
    this.selected_intercom_input = input_selected;
    this.selected_intercom_input!.kind = input_selected.kind;
    this.selected_action = input_selected.action ? input_selected.action.kind : null;
    this.isInputActionPopupVisible = true;
  }

  /**
   * This method is called to hide the action popup and clear any temporary data.
   */
  closePopup() {
    this.selected_action = null;
    this.isAddingAction = false;
    this.isInputActionPopupVisible = false;
  }

  /**
   * Utility method used to format strings where the first letter needs to be capitalized.
   * @param value The input string to be capitalized.
   * @returns The input string to be capitalized.
   */
  capitalizeFirstLetter(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  // Private methods

  /**
   * Fetches the list of intercom inputs from the API.
   */
  private loadIntercomInputs() {
    this.isBusy = true;

    this.intercomManager.getIntercomInputs(this.installationId!, this.unitId!, this.intercomId!)
      .pipe(finalize(() => this.isBusy = false))
      .subscribe({
        next: intercomInputs => {
          this.configured_intercom_inputs = intercomInputs.filter(input => input.action);
          this.not_configured_intercom_inputs = intercomInputs.filter(input => !input.action);
        },
        error: () => this.displayLocalizedErrorNotification('Devices.ApiGetIntercomInputsError')
      });
  }

  /**
   * Handles the actions to be performed after a successful API operation.
   */
  private apiSuccessAction() {
    this.configured_intercom_inputs = [];
    this.not_configured_intercom_inputs = [];
    this.closePopup();
    this.loadIntercomInputs();
  }

}
