import { createCancellableOperation } from '../../utils/createCancellableOperation.mjs';
import { parseSigningInfo } from '../../utils/parseSigningInfo.mjs';
import '@aws-amplify/core/internals/aws-client-utils';
import '@aws-amplify/core/internals/utils';
import '../../errors/validation.mjs';
import { resolveApiUrl } from '../../utils/resolveApiUrl.mjs';
import { logger } from '../../utils/logger.mjs';
import { transferHandler } from './handler.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const publicHandler = (amplify, options, method) => createCancellableOperation(async abortSignal => {
  const {
    apiName,
    options: apiOptions = {},
    path: apiPath
  } = options;
  const url = resolveApiUrl(amplify, apiName, apiPath, apiOptions?.queryParams);
  const libraryConfigHeaders = await amplify.libraryOptions?.API?.REST?.headers?.({
    apiName
  });
  const {
    headers: invocationHeaders = {}
  } = apiOptions;
  const headers = {
    // custom headers from invocation options should precede library options
    ...libraryConfigHeaders,
    ...invocationHeaders
  };
  const signingServiceInfo = parseSigningInfo(url, {
    amplify,
    apiName
  });
  logger.debug(method, url, headers, `IAM signing options: ${JSON.stringify(signingServiceInfo)}`);
  return transferHandler(amplify, {
    ...apiOptions,
    url,
    method,
    headers,
    abortSignal
  }, signingServiceInfo);
});
const get = (amplify, input) => publicHandler(amplify, input, 'GET');
const post = (amplify, input) => publicHandler(amplify, input, 'POST');
const put = (amplify, input) => publicHandler(amplify, input, 'PUT');
const del = (amplify, input) => publicHandler(amplify, input, 'DELETE');
const head = (amplify, input) => publicHandler(amplify, input, 'HEAD');
const patch = (amplify, input) => publicHandler(amplify, input, 'PATCH');
export { del, get, head, patch, post, put };
