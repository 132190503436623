<app-base-auth-form
  [message]="'Auth.CompleteResetPasswordMessage' | i18next"
  [action]="'Auth.ChangePassword' | i18next"
  slot="main-content"
  [isBusy]="isBusy"
  [errorMessage]="errorMessage"
  (performAction)="completeResetPassword()"
>
  <dx-text-box
    [label]="'Auth.Email' | i18next"
    labelMode="floating"
    valueChangeEvent="input"
    class="item large-top-space"
    [(value)]="email"
    (valueChange)="resetError()"
  ></dx-text-box>

  <dx-text-box
    [label]="'Auth.Code' | i18next"
    labelMode="floating"
    valueChangeEvent="input"
    class="item top-space"
    [(value)]="verificationCode"
    (valueChange)="resetError()"
  ></dx-text-box>

  <dx-text-box
    [label]="'Auth.NewPassword' | i18next"
    labelMode="floating"
    [mode]="passwordFieldMode"
    valueChangeEvent="input"
    class="item top-space"
    [(value)]="newPassword"
    (valueChange)="resetError()"
    (onEnterKey)="completeResetPassword()"
  >
    <dxi-button name="today" location="after" [options]="passwordButtonOptions">
    </dxi-button>
  </dx-text-box>
</app-base-auth-form>
