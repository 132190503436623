import { Injectable } from "@angular/core";
import { catchError, map, Observable, of, tap, throwError } from "rxjs";
import { IntercomStatus } from "../../intercoms/models/iot/intercom_status";
import { IntercomsManager } from "./intercoms-manager";
import { IntercomInput } from "../models/intercom_input";
import { UnitsManager } from "../../units/services/units-manager";
import { Intercom } from "../models/intercom";

@Injectable()
export class ApiIntercomsManager extends IntercomsManager {

    constructor(private unitsManager: UnitsManager) {
        super();
    }

    getIntercom(installation_id: string, unit_id: string, intercom_id: string): Observable<Intercom | null> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!intercom_id) return throwError(() => new Error('Invalid intercom id'));

        return this.fetchIntercomFromUnit(installation_id, unit_id, intercom_id);
    }

    updateIntercom(installation_id: string, unit_id: string, intercom_id: string, content: any): Observable<boolean> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!intercom_id) return throwError(() => new Error('Invalid intercom id'));

        return this.put(`/installations/${installation_id}/units/${unit_id}/intercoms/${intercom_id}`, content);
    }

    deleteIntercom(installation_id: string, unit_id: string, intercom_id: string): Observable<boolean> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!intercom_id) return throwError(() => new Error('Invalid intercom id'));

        return this.delete(`/installations/${installation_id}/units/${unit_id}/intercoms/${intercom_id}`);
    }

    getIntercomInputs(installation_id: string, unit_id: string, intercom_id: string): Observable<IntercomInput[]> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!intercom_id) return throwError(() => new Error('Invalid intercom id'));

        return this.get<IntercomInput[]>(`/installations/${installation_id}/units/${unit_id}/intercoms/${intercom_id}/inputs`);
    }

    addIntercomInput(installation_id: string, unit_id: string, intercom_id: string, content: any): Observable<boolean> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!intercom_id) return throwError(() => new Error('Invalid intercom id'));

        return this.post(`/installations/${installation_id}/units/${unit_id}/intercoms/${intercom_id}/inputs`, content);
    }

    deleteIntercomInput(installation_id: string, unit_id: string, intercom_id: string, input_id: string): Observable<boolean> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!intercom_id) return throwError(() => new Error('Invalid intercom id'));

        if (!input_id) return throwError(() => new Error('Invalid input id'));

        return this.delete(`/installations/${installation_id}/units/${unit_id}/intercoms/${intercom_id}/inputs/${input_id}`);
    }

    getIntercomStatus(installation_id: string, unit_id: string, intercom_id: string): Observable<IntercomStatus> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!intercom_id) return throwError(() => new Error('Invalid intercom id'));

        return this.get<IntercomStatus>(`/installations/${installation_id}/units/${unit_id}/intercoms/${intercom_id}/status`);
    }

    updateIntercomPreferences(installation_id: string, unit_id: string, intercom_id: string, content: any): Observable<boolean> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!intercom_id) return throwError(() => new Error('Invalid intercom id'));

        return this.post(`/installations/${installation_id}/units/${unit_id}/intercoms/${intercom_id}/preferences`, content).pipe(
            map(response => {
                return response === true;
            }
            ),
            catchError(_ => {
                return of(false);
            })
        );
    }

    /**
     * This method gets the intercom from the unit.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @returns the intercom or null if not found in the unit.
     */
    private fetchIntercomFromUnit(installation_id: string, unit_id: string, intercom_id: string): Observable<Intercom | null> {
        return this.unitsManager.getUnitDetails(installation_id, unit_id).pipe(
            map(unit => {
                if (!unit) return null;
                return unit.intercoms?.find(intercom => intercom.id === intercom_id) || null;
            })
        );
    }
}