import { Component, Input, OnInit } from "@angular/core";
import { BaseDialogContent } from "../../dialogs/base-dialog-component";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { InvitationsManager } from "../services/invitations-manager";
import { finalize } from "rxjs";
import { openCloseAnimation } from "../../dialogs/animations/show-hide.animation";
import { Installation } from "../../shared/models/installations/installation";
import { NavigationService } from "../../shared";

@Component({
  selector: "app-accept-invitation-dialog",
  templateUrl: "./accept-invitation-dialog.component.html",
  styleUrl: "./accept-invitation-dialog.component.scss",
  animations: [openCloseAnimation],
})
export class AcceptInvitationDialogComponent
  extends BaseDialogContent
  implements OnInit
{
  @Input()
  installationId: string | null = null;

  @Input()
  invitationCode: string | null = null;

  installation?: Installation;

  constructor(
    private invitationsManager: InvitationsManager,
    private navigation: NavigationService,
    dialogs: DialogsService
  ) {
    super(dialogs);
  }

  override passData(data: any): void {
    this.installationId = data.installation_id;
    this.invitationCode = data.invitation_code;
  }

  ngOnInit(): void {
    this.retrieveInvitation();
  }

  acceptInvitation() {
    if (!this.installationId || !this.invitationCode) {
      console.error("Installation id or invitation code is missing.");
      return;
    }

    this.isBusy = true;
    this.invitationsManager
      .acceptManagerInvitation(this.installationId, this.invitationCode)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (_) => this.openInstallationDetail(),
        error: (_) => this.showApiError("Invitations.ApiAcceptInvitationError"),
      });
  }

  private retrieveInvitation() {
    if (!this.installationId || !this.invitationCode) {
      console.error("Installation id or invitation code is missing.");
      return;
    }

    this.isBusy = true;
    this.invitationsManager
      .getManagerInvitationDetails(this.installationId, this.invitationCode)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (invitation) => (this.installation = invitation.installation),
        error: (_) => this.showApiError("Invitations.ApiAcceptInvitationError"),
      });
  }

  private openInstallationDetail() {
    if (this.installationId) {
      this.navigation.navigateToInstallationDetail(this.installationId);
    }
  }

  private showApiError(message: string) {
    this.dialogs.displayErrorNotification(message);
  }
}
