{
  "name": "infinity.frontend",
  "version": "1.0.2",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test-ci": "ng test --watch=false --browsers=ChromeHeadless"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.0",
    "@angular/cdk": "^18.0.0",
    "@angular/common": "^18.0.0",
    "@angular/compiler": "^18.0.0",
    "@angular/core": "^18.0.0",
    "@angular/forms": "^18.0.0",
    "@angular/platform-browser": "^18.0.0",
    "@angular/platform-browser-dynamic": "^18.0.0",
    "@angular/router": "^18.0.0",
    "@aws-crypto/sha256-js": "^5.2.0",
    "@ngrx/store": "^18.1.1",
    "angular-i18next": "^18.0.0",
    "angular-svg-icon": "^18.0.2",
    "aws-amplify": "^6.0.15",
    "devextreme": "24.1.6",
    "devextreme-angular": "24.1.6",
    "devextreme-schematics": "^1.6.4",
    "file-saver": "^2.0.5",
    "i18next": "^23.16.0",
    "i18next-browser-languagedetector": "^7.2.0",
    "i18next-http-backend": "^2.4.3",
    "lottie-web": "^5.12.2",
    "ngx-lottie": "^12.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.0",
    "@angular/cli": "^18.0.0",
    "@angular/compiler-cli": "^18.0.0",
    "@ngx-env/builder": "^18.0.2",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "devextreme-cli": "latest",
    "devextreme-themebuilder": "^24.1.6",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.5"
  }
}