import { Injectable } from '@angular/core';
import { Observable, debounceTime, fromEvent } from 'rxjs';

@Injectable()
export class ScreenService {

  private _screenSizeChangeObservable: Observable<Event>;

  constructor() {
    this._screenSizeChangeObservable = fromEvent(window, 'resize')
      .pipe(debounceTime(30));
  }

  onScreenSizeChange(): Observable<Event> {
    return this._screenSizeChangeObservable;
  }

}
