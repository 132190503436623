import { Component, Input } from '@angular/core';
import { BaseDialogContent } from '../../dialogs/base-dialog-component';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { Installation } from '../../shared/models/installations/installation';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { emailRegex } from '../../shared';
import { AccessLevel } from '../../shared/models/users/access-level';
import { InvitationsManager } from '../services/invitations-manager';
import { finalize } from 'rxjs';
import { CreateManagerInvitationDTO } from '../dto/create-manager-invitation-dto';

@Component({
  selector: 'app-create-manager-invitation-dialog',
  templateUrl: './create-manager-invitation-dialog.component.html',
  styleUrl: './create-manager-invitation-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class CreateManagerInvitationDialogComponent extends BaseDialogContent {

  @Input()
  installation: Installation | undefined;

  invitation_roles = [
    {
      title: "General.Manager",
      subtitle: "Invitations.ManagerDetails",
      value: AccessLevel.MANAGER
    },
    {
      title: "General.Installer",
      subtitle: "Invitations.InstallerDetails",
      value: AccessLevel.INSTALLER
    }
  ];

  sendInvitationButtonEnabled = false;
  email: string = '';
  notes: string = '';
  selectedRole: AccessLevel = AccessLevel.MANAGER;

  constructor(dialogs: DialogsService, private invitationsManager: InvitationsManager) {
    super(dialogs);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.installation = data as Installation;
  }

  //#endregion

  valueChanged() {
    this.sendInvitationButtonEnabled = this.email?.trim() != '' &&
      emailRegex.test(this.email) &&
      this.selectedRole != null;
  }

  sendInvitation() {
    if (!this.installation?.id) {
      return;
    }

    this.isBusy = true;
    const content = this.createSendInvitationContent();
    this.invitationsManager.createManagerInvitation(this.installation.id, content).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe({
      next: _ => this.onInvitationSent(),
      error: _ => this.dialogs.displayErrorNotification('Invitations.ApiCreateInvitationError')
    });
  }

  private onInvitationSent() {
    this.dialogs.sendDialogResult(true);
    this.dialogs.displaySuccessNotification('Invitations.ApiCreateInvitationSuccess');
    this.dialogs.hideDialog();
  }

  private createSendInvitationContent(): CreateManagerInvitationDTO {
    return {
      emails: [this.email],
      note: this.notes,
      access: this.selectedRole
    }
  }

}
