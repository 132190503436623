import { CanActivateFn } from "@angular/router";
import { AuthenticationManager } from "../../shared/services/authentication-manager";
import { inject } from "@angular/core";
import { catchError, map, of, tap } from "rxjs";
import { NavigationService } from "../../shared/services/navigation.service";

export const unauthenticatedGuard: CanActivateFn = () => {
    const authService = inject(AuthenticationManager);
    const navigation = inject(NavigationService);
    return authService.userLoggedIn().pipe(
        catchError(_ => of(false)),
        tap(loggedIn => {
            if (loggedIn) {
                // navigation.navigateToHome();
            }
        }),
        map(loggedIn => !loggedIn)  // Inverted logic: the navigation can continue only for unauth users
    );
};