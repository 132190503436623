<div class="container">

    <div *ngIf="isBusy" class="loading">
        <dx-load-indicator></dx-load-indicator>
        <div>{{'General.RetrievingData' | i18next}}</div>
    </div>

    <div class="content" *ngIf="!isBusy">

        <div class="title">
            {{'General.Inputs' | i18next}}
        </div>

        <div class="box">

            <div class="not-configured">
                <div class="box-title">
                    <div class="text-box-title">{{'Inputs.NotConfigured' | i18next}}</div>
                    <div class="subtext-box-title">{{'Inputs.NotConfiguredMessage' | i18next}}</div>
                </div>
                <ng-container *ngIf="not_configured_intercom_inputs.length > 0; else noNotConfiguredInputs">
                    <dx-tree-list id="notConfiguredInputs" [dataSource]="not_configured_intercom_inputs"
                        (onRowClick)="showInputActionPopup($event)" keyExpr="address" [showColumnHeaders]="false"
                        [hoverStateEnabled]="true" style="height: 100%;">
                        <dxi-column dataField="address" cellTemplate="template">
                            <div *dxTemplate="let data of 'template'" class="cell">
                                <div class="cell-box">
                                    <div class="description">{{'Inputs.ExternalButton' | i18next}}</div>
                                    <div class="data">{{data.data.address}}</div>
                                </div>
                                <i class="dx-icon-chevronright"></i>
                            </div>
                        </dxi-column>
                    </dx-tree-list>
                </ng-container>
                <ng-template #noNotConfiguredInputs>
                    <div class="no-data-message">
                        {{'Inputs.NoInputsToConfigure' | i18next}}
                    </div>
                </ng-template>
            </div>

            <div class="configured">
                <div class="box-title">
                    <div class="text-box-title">{{'Inputs.Configured' | i18next}}</div>
                    <div class="subtext-box-title">{{'Inputs.ConfiguredMessage' | i18next}}</div>
                </div>
                <ng-container *ngIf="configured_intercom_inputs.length > 0; else noConfiguredInputs">
                    <dx-tree-list id="configuredInputs" [dataSource]="configured_intercom_inputs"
                        (onRowClick)="showInputActionPopup($event)" keyExpr="address" [showColumnHeaders]="false"
                        [hoverStateEnabled]="true" style="height: 100%;">
                        <dxi-column dataField="address" cellTemplate="template">
                            <div *dxTemplate="let data of 'template'" class="box">
                                <i class="dx-icon-login"></i>
                                <div class="cell">
                                    <div class="cell-box">
                                        <div class="description">{{'Inputs.ExternalButton' | i18next}}</div>
                                        <div class="data">{{data.data.address}}</div>
                                    </div>
                                    <i class="dx-icon-chevronright"></i>
                                </div>
                            </div>
                        </dxi-column>
                    </dx-tree-list>
                </ng-container>
                <ng-template #noConfiguredInputs>
                    <div class="no-data-message">
                        {{'Inputs.NoInputsConfigured' | i18next}}
                    </div>
                </ng-template>
            </div>

        </div>

    </div>
</div>

<dx-popup *ngIf="selected_intercom_input" [width]="500" [height]="580" [showTitle]="false" [dragEnabled]="false"
    container=".dx-viewport" [(visible)]="isInputActionPopupVisible" (onHiding)="closePopup()">

    <div class="popup-content">

        <div class="popup-title">{{"Inputs.ConfigureInput" | i18next}}</div>

        <div class="text-box-title">{{'General.Typology' | i18next}}</div>
        <dx-text-box class="text-box" [value]="'Inputs.' + capitalizeFirstLetter(selected_intercom_input!.kind!) | i18next"
            valueChangeEvent="keyup" [disabled]="true">
        </dx-text-box>

        <div class="text-box-title">{{'Inputs.Action' | i18next}}</div>
        <dx-tree-list id="actionsList" [dataSource]="actions" keyExpr="code" [showColumnHeaders]="false"
            [hoverStateEnabled]="true" (onSelectionChanged)="onSelectionChanged($event)"
            [selectedRowKeys]="[selected_action]">
            <dxi-column dataField="name"></dxi-column>
            <dxo-selection mode="single"></dxo-selection>
        </dx-tree-list>

        <div class="popup-button">
            <dx-button class="cancel-button animated" [text]="'General.Close' | i18next" (onClick)="closePopup()">
            </dx-button>

            <dx-button *ngIf="!selected_intercom_input!.action" class="save-button animated"
                (onClick)="addIntercomInput()" [disabled]="isLoading || !isAddingAction">
                <div *dxTemplate="let data of 'content'" style="display: flex;">
                    <dx-load-indicator class="button-indicator" height="22" width="22" [visible]="isLoading">
                    </dx-load-indicator>
                    <span class="dx-button-text" style="padding: 8px;">{{'Inputs.SaveConfiguration' |
                        i18next}}</span>
                </div>
            </dx-button>

            <dx-button *ngIf="selected_intercom_input!.action" class="delete-button animated"
                (onClick)="deleteIntercomInput()" [disabled]="isLoading">
                <div *dxTemplate="let data of 'content'" style="display: flex;">
                    <dx-load-indicator class="button-indicator" height="22" width="22" [visible]="isLoading">
                    </dx-load-indicator>
                    <span class="dx-button-text" style="padding: 8px;">{{'Inputs.DeleteConfiguration' |
                        i18next}}</span>
                </div>
            </dx-button>
        </div>

    </div>

</dx-popup>