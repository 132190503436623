<div class="page">
  <app-page-header
    [title]="'Account.WelcomeMessage' | i18next : { username: userAttributes | username }"
    [showAreaPlaceholder]="isBusy"
  >
    <app-account-picture
      pre-title-view
      [image]="imageContent"
    ></app-account-picture>
    <dx-button
      [text]="'Account.EditProfile' | i18next"
      type="default"
      icon="edit"
      (onClick)="editProfile()"
    ></dx-button>
  </app-page-header>

  <app-widget-card
    *ngIf="displayLicenses"
    [showAreaPlaceholder]="isBusy"
    minHeight="450px"
    icon="assets/icons/ic_license.svg"
    [title]="'Installations.Licenses' | i18next"
    [showAreaPlaceholder]="isBusy"
  >
    <app-licenses-list
      [installationLicenses]="userLicenses"
      [installations]="userInstallations"
    ></app-licenses-list>
  </app-widget-card>

  <div class="installations-header" [ngClass]="{ 'area-placeholder': isBusy }">
    <div class="title">
      <div class="headline-small">{{ "General.Installations" | i18next }}</div>
      <dx-button
        type="default"
        icon="add"
        [text]="'Installations.CreateInstallation' | i18next"
        (onClick)="createInstallation()"
      ></dx-button>
    </div>

    <div class="left-buttons">
      <dx-button
        type="default"
        [class]="viewTypeCards ? 'active-button' : ''"
        (onClick)="viewAsCards()"
        stylingMode="outlined"
        [text]="'Installations.SlideView' | i18next"
      ></dx-button>
      <dx-button
        type="default"
        [class]="!viewTypeCards ? 'active-button' : ''"
        (onClick)="viewAsList()"
        stylingMode="outlined"
        [text]="'Installations.ListView' | i18next"
      ></dx-button>
    </div>
  </div>

  <div id="installations-body">
    <div *ngIf="viewTypeCards">
      <dx-button
        id="backButton"
        type="default"
        class="animated"
        icon="chevronleft"
        (click)="scrollLeft()"
        *ngIf="showBackButton"
      >
      </dx-button>

      <dx-button
        id="forwardButton"
        type="default"
        class="animated"
        icon="chevronright"
        (click)="scrollRight()"
        *ngIf="showForwardButton"
      >
      </dx-button>

      <dx-scroll-view
        #scrollView
        direction="horizontal"
        (onScroll)="updateScrollButtonsVisibility()"
        (onUpdated)="updateScrollButtonsVisibility()"
      >
        <div id="installations-cards">
          <div
            *ngFor="let installation of userInstallations"
            class="installation-card"
          >
            <img
              alt="{{ installation.name }}"
              [src]="installation.image_url ?? 'assets/images/background.jpeg'"
            />
            <div class="installation-info">
              <div class="installation-name">{{ installation.name }}</div>
              <div class="installation-address">
                <div ID="installation-address-content">
                  {{ installation?.address?.address }}
                </div>
              </div>
              <div class="installation-actions">
                <dx-button
                  text="Manage"
                  type="default"
                  icon="chart"
                  (onClick)="openInstallationDetail(installation)"
                ></dx-button>
              </div>
            </div>
          </div>

          <!-- Two placeholder divs -->
          <div
            *ngIf="isBusy && (userInstallations?.length ?? 0) <= 0"
            class="installation-card-placeholder"
          ></div>
          <div
            *ngIf="isBusy && (userInstallations?.length ?? 0) <= 0"
            class="installation-card-placeholder"
          ></div>
        </div>
      </dx-scroll-view>
    </div>

    <app-widget-card
      *ngIf="!viewTypeCards"
      [showAreaPlaceholder]="isBusy"
      minHeight="500px"
      [showAreaPlaceholder]="isBusy"
    >
      <dx-data-grid
        [dataSource]="userInstallations"
        id="installationsList"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [height]="400"
        (onRowClick)="openInstallationDetail($event.data)"
      >
        <dxo-pager [visible]="true" [showPageSizeSelector]="true" />
        <dxo-paging [pageSize]="25"></dxo-paging>

        <dxo-header-filter visible="true"></dxo-header-filter>

        <dxi-column
          dataField="name"
          [caption]="'General.Name' | i18next"
          [allowHiding]="false"
        >
        </dxi-column>

        <dxi-column
          dataField="timestamp"
          [caption]="'General.CreatedOn' | i18next"
          alignment="center"
          cellTemplate="dateCell"
          [allowFiltering]="false"
        >
          <div *dxTemplate="let cellInfo of 'dateCell'">
            {{ cellInfo.value * 1000 | date : "longDate" }}
          </div>
        </dxi-column>

        <dxi-column
          dataField="language"
          [caption]="'Installations.SelectedLanguage' | i18next"
          alignment="center"
        >
        </dxi-column>

        <dxi-column
          dataField="address.address"
          [caption]="'General.Address' | i18next"
          alignment="center"
        >
        </dxi-column>
      </dx-data-grid>
    </app-widget-card>
  </div>
</div>

<app-shimmer-overlay *ngIf="isBusy"></app-shimmer-overlay>
