import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-widget-card',
  templateUrl: './widget-card.component.html',
  styleUrl: './widget-card.component.scss'
})
export class WidgetCardComponent {

  @Input() title?: string;

  @Input() icon?: string;

  @Input() action?: string;

  @Input() actionIcon?: string;

  @Input() subtitleIcon?: string;

  @Input() subtitle?: string;

  @Input() infoIcon?: string;

  @Input() info?: string;

  @Input() bodyWithoutPadding: boolean = false;

  @Output() actionClick: EventEmitter<any> = new EventEmitter();

  @Input() showAreaPlaceholder: boolean = false;

  @Input() height: string = 'auto';

  @Input() minHeight: string = 'auto';

}
