import { Pipe, PipeTransform } from '@angular/core';
import { AccessLevel } from '../../shared/models/users/access-level';

@Pipe({ name: 'accessLevelIcon' })
export class AccessLevelIconPipe implements PipeTransform {

    transform(value: AccessLevel): string {
        switch (value) {
            case AccessLevel.INSTALLER_USER:
            case AccessLevel.INSTALLER:
                return 'assets/icons/ic_installer.svg';
            case AccessLevel.MANAGER_USER:
            case AccessLevel.MANAGER:
                return 'assets/icons/ic_manager.svg';
            default:
                return 'assets/icons/ic_user.svg';
        }
    }

}
