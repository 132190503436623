import { Component, Input } from '@angular/core';
import { Unit } from '../../shared/models/units/unit';
import { UnitsManager } from '../services/units-manager';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { finalize } from 'rxjs';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { EditUnitDto } from '../dto/edit-unit-dto';
import { Installation } from '../../shared/models/installations/installation';
import { areEqualsArray } from "../../shared/utils/array-utils";
import { BaseEditUnitDialogComponent } from '../base-edit-unit-dialog';
import { NavigationService } from '../../shared';
import { isInstallerAccess } from '../../shared/models/users/access-level';


@Component({
  selector: 'app-edit-unit-dialog',
  templateUrl: './edit-unit-dialog.component.html',
  styleUrl: './edit-unit-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class EditUnitDialogComponent extends BaseEditUnitDialogComponent {

  @Input()
  unit?: Unit;

  @Input()
  installation?: Installation;

  thereAreChanges: boolean = false;
  askForDeletion = false;
  hasFullAccess: boolean = false;

  constructor(private units: UnitsManager, private navigation: NavigationService, dialogs: DialogsService) {
    super(dialogs);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.unit = data.unit as Unit;
    if (this.unit) {
      this.unitName = this.unit.name || '';
      this.unitAlias = this.unit.alias || '';
      this.unitLocation = this.unit.location || '';
      this.unitNotes = this.unit.notes || '';
    }

    this.installation = data.installation as Installation;
    this.hasFullAccess = isInstallerAccess(this.installation.permissions?.access);
    this.populateInstallationDevices(this.installation);
    this.getUnitDeviceAndOutputs();
  }


  override permissionSelectionChanged(event: any) {
    if (!this.unit || !event) {
      return;
    }

    super.permissionSelectionChanged(event);
  }

  override updateConfirmButtonState() {
    this.thereAreChanges = this.hasFormChanges();
  }


  /**
   * Populates the enabled devices and outputs array.
   * This array contains the devices and outputs that are enabled for the unit.
   */

  private getUnitDeviceAndOutputs() {
    this.unitDevicesAndOutputs = this.initialUnitDevicesAndOutputs = [];
    const unitDevices = this.unit?.devices || [];
    const installationDevices = this.installation?.devices || [];
    if (unitDevices.length == 0 || installationDevices.length == 0) {
      return;
    }

    for (const installationDevice of installationDevices) {
      const unitDevice = unitDevices.find((device) => device.id == installationDevice.id);
      if (!unitDevice) {
        continue;
      }

      this.unitDevicesAndOutputs.push(unitDevice.id);
      unitDevice.outputs?.forEach((outputAddress) => {
        const output = installationDevice.available_outputs?.find((o) => o.address == outputAddress);
        if (output?.id) {
          this.unitDevicesAndOutputs.push(output.id);
        }
      });
    }

    this.initialUnitDevicesAndOutputs = [...this.unitDevicesAndOutputs];
  }

  //#endregion

  //#region installation deletion methods

  deleteUnit() {
    this.hideTitleRequested.emit(true);
    this.askForDeletion = true;
  }

  completeUnitRemoval() {
    if (!this.unit || !this.installation) {
      return;
    }

    this.isBusy = true;
    this.units.deleteUnit(this.installation.id, this.unit.id).subscribe({
      next: _ => {
        this.dialogs.hideDialog();
        this.navigation.navigateToInstallationDetail(this.installation!.id);
      },
      complete: () => this.isBusy = false
    });
  }

  updateUnit() {
    if (!this.unit) {
      return;
    }

    if (this.hasFormChanges()) {
      this.isBusy = true;
      const changes = this.buildDto();
      this.units.updateUnit(this.unit.installation_id, this.unit.id, changes).pipe(
        finalize(() => this.isBusy = false)
      ).subscribe({
        next: data => this.handleAPIResponse(true, 'Units.UnitDetailsEditSuccess', data),
        error: _ => this.handleAPIResponse(false, 'Units.ApiUpdateError')
      });
    }
  }

  private hasFormChanges(): boolean {
    let hasChanges = false;
    hasChanges = hasChanges || this.unitName != this.unit?.name;
    hasChanges = hasChanges || (this.unitAlias != this.unit?.alias && this.isValidUnitAlias());
    hasChanges = hasChanges || this.unitLocation != this.unit?.location;
    hasChanges = hasChanges || this.unitNotes != this.unit?.notes;
    if (hasChanges) {
      return true;
    }

    const sortedEnabledDevices = [...this.unitDevicesAndOutputs].sort();
    const sortedInitialEnabledDevices = [...this.initialUnitDevicesAndOutputs].sort();
    return !areEqualsArray(sortedEnabledDevices, sortedInitialEnabledDevices);
  }

  private buildDto(): EditUnitDto {
    const installationDevices = this.installation?.devices || [];
    return {
      name: this.unitName,
      alias: this.unitAlias,
      location: this.unitLocation,
      notes: this.unitNotes,
      devices: this.buildEditUnitDevicesDto(installationDevices)
    } as EditUnitDto;
  }

}
