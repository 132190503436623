import { Component } from '@angular/core';
import { BasePageComponent } from '../../shared/components/base-page.component';
import { AuthenticationManager } from '../../shared/services/authentication-manager';
import { NavigationService, LocalizerService, ScreenService } from '../../shared';

@Component({
  selector: 'app-complete-reset-password',
  templateUrl: './complete-reset-password.component.html',
  styleUrl: './complete-reset-password.component.scss'
})
export class CompleteResetPasswordComponent extends BasePageComponent {

  email: string = this.auth.currentPasswordResetUserEmail ?? "";
  verificationCode: string = "";
  newPassword: string = "";

  passwordFieldMode: "password" | "text" = "password";
  passwordButtonOptions = {
    icon: 'eyeopen',
    stylingMode: 'text',
    onClick: () => {
      this.passwordFieldMode = this.passwordFieldMode === "password" ? "text" : "password";
    },
  };

  constructor(private auth: AuthenticationManager,
    private navigation: NavigationService,
    localizer: LocalizerService,
    screen: ScreenService) {
    super(screen, localizer);
  }

  completeResetPassword() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.auth.completeResetPassword(this.email, this.verificationCode, this.newPassword).subscribe({
      next: this.resetPasswordCompleted.bind(this),
      error: this.errorDuringPasswordReset.bind(this)
    });
  }

  private resetPasswordCompleted() {
    this.isBusy = false;
    this.displaySuccessNotification(this.localizer.getLocalizedString("Auth.CompleteResetPasswordSuccess"));
    this.navigation.navigateToLogin();
  }

  private errorDuringPasswordReset(error: any) {
    console.log(error);
    this.isBusy = false;
    const message = (typeof error === "string") ? error : null;
    this.displayError(message);
  }

}
