import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Installation } from "../../shared/models/installations/installation";
import { License } from "../../licenses/models/license";


export const InstallationActions = createActionGroup({
  source: "Installation",
  events: {
    "Select Installation": props<{ installation: Installation }>(),
    "Save licenses": props<{ licenses: License[] }>(),
    "Reset Installation": emptyProps()
  },
});
