import { Pipe, PipeTransform } from '@angular/core';
import { Installation } from '../../shared/models/installations/installation';
import { LocalizerService } from '../../shared';

@Pipe({ name: 'installationUnits' })
export class InstallationUnitsPipe implements PipeTransform {

  constructor(private localizer: LocalizerService) { }

  transform(value?: Installation): string {
    if (!value) {
      return '';
    }

    const unitsCardinality = value.units?.length ?? 0;
    return `${unitsCardinality} ${this.localizer.getLocalizedString('General.Units')}`;
  }

}
