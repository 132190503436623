<div class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">
  <div>{{ "Invitations.AcceptInvitationSubTitle" | i18next }}:</div>

  <div class="installation-data">
    <svg-icon src="assets/icons/ic_home.svg" class="icon"></svg-icon>
    <div class="content" *ngIf="installation">
      <div class="installation-name">{{ installation.name }}</div>
      <div *ngIf="installation?.address != null" class="installation-address">
        {{ installation.address | fullAddress }}
      </div>
    </div>
  </div>

  <div class="dialog-action-bar">
    <dx-button
      [text]="'General.Cancel' | i18next"
      (onClick)="closeDialog()"
      stylingMode="text"
    >
    </dx-button>
    <dx-button
      [text]="'General.Confirm' | i18next"
      type="default"
      (onClick)="acceptInvitation()"
    >
    </dx-button>
  </div>
</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
  <app-spinner [message]="'Invitations.AcceptingInvitation'"></app-spinner>
</div>
