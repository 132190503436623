import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Unit } from "../../shared/models/units/unit";
import { finalize, map, forkJoin, throwError, tap, takeUntil } from "rxjs";
import { UnitsManager } from "../services/units-manager";
import { BasePageComponent } from "../../shared/components/base-page.component";
import {
  LocalizerService,
  NavigationService,
  profileColorPalette,
  ScreenService,
} from "../../shared";
import { Installation } from "../../shared/models/installations/installation";
import { isUserAccess } from "../../shared/models/users/access-level";
import { InvitationsManager } from "../../invitations/services/invitations-manager";
import { BreadcrumbItem } from "../../shared/utils/breadcrumb-item";
import { Invitation } from "../../shared/models/invitations/invitation";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { EditUnitDialogComponent } from "../edit-unit-dialog/edit-unit-dialog.component";
import { CreateUserInvitationDialogComponent } from "../../invitations/create-user-invitation/create-user-invitation-dialog.component";
import { EditUserInvitationDialogComponent } from "../../invitations/edit-user-invitation/edit-user-invitation-dialog.component";
import { EditUnitUserDialogComponent } from "../edit-unit-user-dialog/edit-unit-user-dialog.component";
import { UserOrInvitation } from "../models/user-or-invitation";
import { UserAccess } from "../models/user-access";
import { UnitDevice } from "../models/unit-device";
import { User } from "../../shared/models/users/user";
import { Store } from "@ngrx/store";
import { selectInstallation } from "../../state/installation/installation.selector";
import { InstallationsManager } from "../../installations/services/installations-manager";
import { InstallationActions } from "../../state/installation/installation.actions";

@Component({
  selector: "app-unit-detail",
  templateUrl: "./unit-detail.component.html",
  styleUrl: "./unit-detail.component.scss",
})
export class UnitDetailComponent
  extends BasePageComponent
  implements OnInit, OnDestroy
{
  @Input() installationId: string | null = null;
  @Input() unitId: string | null = null;

  installation: Installation | null = null;
  hasFullAccess = false;

  unit: Unit | null = null;
  unitUsers: UserAccess[] = [];
  invitations: Invitation[] = [];
  unitUsersAndInvitations: UserOrInvitation[] = [];

  breadcrumbItems: BreadcrumbItem[] = [
    { label: "General.Installations", url: "/installations" },
  ];

  constructor(
    private units: UnitsManager,
    private invitationsManager: InvitationsManager,
    private installations: InstallationsManager,
    private navigation: NavigationService,
    private dialogs: DialogsService,
    private store: Store,
    screen: ScreenService,
    localizer: LocalizerService
  ) {
    super(screen, localizer);
  }

  ngOnInit() {
    if (!this.unitId) {
      this.dialogs.displayErrorNotification("Units.InvalidUnitId");
      return;
    }

    if (!this.installationId) {
      this.dialogs.displayErrorNotification(
        "Installations.InvalidInstallationId"
      );
      return;
    }

    this.store
      .select(selectInstallation)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((i) => {
        if (i.installation == null) {
          this.retrieveInstallation();
          return;
        }

        this.installation = i.installation;
        this.hasFullAccess = i.hasFullAccess;
        this.loadPageData();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  navigateToIntercomDetails(event: any) {
    if (!this.installationId || !this.unitId) {
      return;
    }

    const intercom = event.key;
    this.navigation.navigateToIntercomDetails(
      this.installationId,
      this.unitId,
      intercom.id
    );
  }

  editUnitDetails() {
    this.showDalog(
      EditUnitDialogComponent,
      { unit: this.unit, installation: this.installation },
      "assets/icons/ic_edit.svg",
      "Units.CustomizeUnit"
    );
  }

  createUserInvitation() {
    this.showDalog(
      CreateUserInvitationDialogComponent,
      this.unit,
      "assets/icons/ic_invitation.svg",
      "Invitations.Create"
    );
  }

  editUserOrInvitation(event: any) {
    const userOrInvitation = event.key as UserOrInvitation;
    if (userOrInvitation?.type == "user") {
      const user = this.unitUsers.find((it) => it.sub == userOrInvitation.id);
      this.showUserDialog(user!); // this methods handles the click on the item list, so the user always exists
    } else if (userOrInvitation?.type == "invitation") {
      const invitation = this.invitations.find(
        (it) => it.code == userOrInvitation.id
      );
      this.showInvitationDialog(invitation!); // this methods handles the click on the item list, so the invitation always exists
    }
  }

  //Private methods

  /**
   * Retrieves the users and invitations associated with the unit.
   * Return an observable to allow the caller making something after users has been loaded
   */

  private getUnitUsers() {
    if (!this.installationId || !this.unitId) {
      return throwError(() => new Error("Geolocation not available"));
    }

    return this.units
      .getUnitUsers(this.installationId, this.unitId)
      .pipe(
        map((users) => users.filter((user) => isUserAccess(user.access_level)))
      );
  }

  private getInvitations() {
    if (!this.installationId || !this.unitId) {
      return throwError(() => new Error("No installation id or unit id"));
    }

    return this.invitationsManager
      .getInvitations(this.installationId, this.unitId)
      .pipe(tap((invitations) => (this.invitations = invitations)));
  }

  private updateBreadcrumb() {
    if (this.breadcrumbItems.length > 1) {
      this.breadcrumbItems.splice(1);
    }

    if (this.installation) {
      this.breadcrumbItems.push({
        label: this.installation.name,
        url: `/${this.localizer.currentLanguage()}/installations/${
          this.installation.id
        }`,
      });

      if (this.unit) {
        const name = this.unit.name ?? this.unit.alias ?? "-";
        this.breadcrumbItems.push({ label: name });
      }
    }
  }

  /**
   * Retrieve the installation when is not already part of the state.
   * This could happen when navigating directly to this page, without pass through the installation.
   */

  private retrieveInstallation() {
    this.isBusy = true;
    this.installations.getInstallation(this.installationId!).subscribe({
      next: (i) =>
        this.store.dispatch(
          InstallationActions.selectInstallation({ installation: i })
        ),
    });
  }

  private loadPageData() {
    this.isBusy = true;
    const dataRetrievalTask = {
      unitDetails: this.units.getUnitDetails(
        this.installationId!,
        this.unitId!
      ),
      users: this.getUnitUsers(),
      invitations: this.getInvitations(),
    };

    forkJoin(dataRetrievalTask)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (data) => {
          this.showUnitDetails(data.unitDetails);
          this.updateUnitUsersItems(data.users, data.invitations);
          this.updateBreadcrumb();
        },
        error: () =>
          this.dialogs.displayErrorNotification(
            "Installations.ApiManagerInvitationsOrUsersError"
          ),
      });
  }

  private showUnitDetails(unit: Unit) {
    this.unit = unit;
    unit?.devices?.forEach((device) => {
      if (!device.available_outputs) {
        device.available_outputs = [];
      }

      const installationDevice = this.installation?.devices?.find(
        (it) => it.id == device.id
      );
      device.outputs?.forEach((output, index) => {
        const availableOutput = installationDevice?.available_outputs?.find(
          (o) => o.address == output
        );
        if (availableOutput) {
          device.available_outputs!.push(availableOutput);
        }
      });
    });
  }

  private updateUnitUsersItems(users: User[], invitations: Invitation[]) {
    if (!this.unit || !this.installation) {
      console.error("Missing required data");
      return;
    }

    this.unitUsers = users;
    let usersAndInvitations: UserOrInvitation[] = this.unitUsers.map((u) => {
      return {
        type: "user",
        id: u.sub,
        accessLevel: u.access_level,
        email: u.email,
        devices: u.devices,
      } as UserOrInvitation;
    });

    this.invitations = invitations;
    this.invitations.forEach((i, index) => {
      const devices: UnitDevice[] = (i.devices || [])
        .filter((d) => this.installation!.devices!.find((id) => id.id == d.id))
        .map((d) => {
          const device = this.installation!.devices!.find(
            (id) => id.id == d.id
          );
          return {
            outputs: d.outputs,
            thing_name: device?.thing_name || "",
            unit_id: this.unitId || "",
            device_id: d.id,
            name: device?.name || "",
          } as UnitDevice;
        });

      usersAndInvitations = usersAndInvitations.concat({
        type: "invitation",
        accessLevel: i.access,
        email: i.email,
        id: i.code,
        devices: devices,
      } as UserOrInvitation);
    });

    usersAndInvitations.forEach((item, index) => {
      item.color = profileColorPalette[index % profileColorPalette.length];
      item.textColor = "#000000";
    });

    this.unitUsersAndInvitations = usersAndInvitations;
  }

  private showUserDialog(user: User) {
    const data = {
      user: user,
      devices: this.unit?.devices,
      installationId: this.installationId,
      unitId: this.unitId,
    };
    this.showDalog(
      EditUnitUserDialogComponent,
      data,
      "assets/icons/ic_account.svg",
      "Users.Edit"
    );
  }

  private showInvitationDialog(invitation: Invitation) {
    const data = {
      invitation: invitation,
      installationId: this.installationId,
      unitId: this.unitId,
    };
    this.showDalog(
      EditUserInvitationDialogComponent,
      data,
      "assets/icons/ic_invitation.svg",
      "Invitations.Detail"
    );
  }

  private showDalog(component: any, data: any, icon: string, title: string) {
    const configuration = {
      title: title,
      icon: icon,
    };

    this.dialogs
      .showDialog(component, data, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.loadPageData());
  }
}
