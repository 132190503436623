import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { BaseDialogContent } from '../../dialogs/base-dialog-component';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { CsvImportService } from '../services/csv-import.service';
import { Installation } from '../../shared/models/installations/installation';
import { finalize } from 'rxjs';
import { UnitImportedViewModel } from '../models/unit_imported_view_model';

@Component({
  selector: 'app-import-units-dialog',
  templateUrl: './import-units-dialog.component.html',
  styleUrl: './import-units-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class ImportUnitsDialogComponent extends BaseDialogContent {

  @ViewChild('csvFileInput') csvFileInput!: ElementRef;

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showDragOverEffect = false;
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.showDragOverEffect = true;
  }

  @HostListener('drop', ['$event'])
  onFileDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    if ((event.dataTransfer?.files?.length ?? 0) > 0) {
      this.selectedFile = event.dataTransfer?.files[0];
      this.showDragOverEffect = false;
    }
  }

  selectedFile?: File;
  showDragOverEffect = false;
  installationId?: string;

  showImportResult = false;
  resultItems: UnitImportedViewModel[] = [];
  errorMessage?: string;

  constructor(dialogs: DialogsService, private csvImporter: CsvImportService) {
    super(dialogs);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.installationId = (data as Installation).id ?? undefined;
  }

  //#endregion

  onCSVFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input?.files?.[0];
    this.selectedFile = file;
    input.value = '';
  }

  showFileSystemDialog() {
    this.csvFileInput.nativeElement.click();
  }

  clearFileSelection = () => this.selectedFile = undefined;

  downlaodCsvTemplate = () => this.csvImporter.downloadFileTemplate();

  startCsvImport() {
    if (!this.selectedFile || !this.installationId) {
      console.error('No file selected');
      return;
    }

    this.isBusy = true;
    this.csvImporter.importUnits(this.installationId, this.selectedFile).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe({
      next: (items) => {
        this.showImportResult = true;
        this.resultItems = items;
        this.errorMessage = undefined;
      },
      error: (e: Error) => {
        this.showImportResult = true;
        this.resultItems = [];
        this.errorMessage = e.message;
      }
    });
  }

  closeDialogAfterImport() {
    this.resultItems = [];
    this.errorMessage = undefined;
    this.dialogs.sendDialogResult(true);
    this.dialogs.hideDialog();
  }

}
