export interface UnitImportedViewModel {

    row_id: string;

    state: UnitImportState;
}

export enum UnitImportState {

    UNIT_WRITE_SUCCESS_INVITATION_WRITE_SUCCESS = 0,

    UNIT_WRITE_SUCCESS_INVITATION_WRITE_FAILED = 1,

    UNIT_WRITE_SUCCESS = 2,

    UNIT_WRITE_FAILED = 3,

    INVITATION_WRITE_SUCCESS = 4,

    INVITATION_WRITE_FAILED = 5,

    CSV_ROW_MALFORMED = 6,

    UNIT_ALREADY_EXISTING = 7, // No more used

    NO_LICENSE_AVAILABLE = 8,

    UNIT_DUPLICATED = 9

}

export function isSuccessImportState(state: UnitImportState): boolean {
    return state == UnitImportState.UNIT_WRITE_SUCCESS_INVITATION_WRITE_SUCCESS ||
        state == UnitImportState.UNIT_WRITE_SUCCESS ||
        state == UnitImportState.INVITATION_WRITE_SUCCESS;
}