import { Pipe, PipeTransform } from "@angular/core";
import { Installation } from "../../shared/models/installations/installation";
import { InstallationLicensesDto } from "../../licenses/dto/installation-licenses-dto";
import { LicenseKind } from "../../licenses/models/license";

@Pipe({ name: "hasLicense" })
export class HasLicensePipe implements PipeTransform {
  transform(
    value: Installation,
    userLicenses: InstallationLicensesDto[]
  ): boolean {
    if (!value || !userLicenses || userLicenses.length <= 0) {
      return false;
    }

    const installationLicenses = userLicenses.find(
      (l) => l.installation?.id == value.id
    );
    if (!installationLicenses || installationLicenses.licenses.length <= 0) {
      return false;
    }

    return (
      installationLicenses.licenses?.find(
        (l) => l.kind == LicenseKind.APP_WEB
      ) != null
    );
  }
}
