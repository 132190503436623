import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DevicesManager } from '../services/devices-manager';
import { LocalizerService } from '../../shared';
import { DeviceStatus } from '../models/iot/device_status';
import { finalize } from 'rxjs';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { AnimationOptions } from 'ngx-lottie';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';

@Component({
  selector: 'app-device-preferences-settings',
  templateUrl: './device-preferences-settings.component.html',
  styleUrl: './device-preferences-settings.component.scss',
  animations: [openCloseAnimation]
})
export class DevicePreferencesSettingsComponent implements OnInit {

  @Input() installationId: string | null = null;
  @Input() deviceId: string | null = null;
  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();

  deviceStatus: DeviceStatus | null = null;
  newDeviceStatus: DeviceStatus | null = null;

  // Utility variables
  isBusy: boolean = false;
  isLoading: boolean = false;
  saveDetailsButtonDisabled: boolean = false;

  options: AnimationOptions = {
    path: '/assets/animations/loading.json',
    loop: true,
    autoplay: true
  };

  mode = [
    { value: this.localizer.getLocalizedString('Preferences.TonesAndVoices'), code: 'tonesAndVoices' },
    { value: this.localizer.getLocalizedString('Preferences.OnlyTones'), code: 'onlyTones' },
    { value: this.localizer.getLocalizedString('Preferences.Silent'), code: 'none' }
  ];

  resolution = [
    { value: this.localizer.getLocalizedString('Preferences.Auto'), code: 'auto' },
    { value: this.localizer.getLocalizedString('Preferences.Minimal'), code: 'minimal' },
    { value: this.localizer.getLocalizedString('Preferences.Low'), code: 'low' },
    { value: this.localizer.getLocalizedString('Preferences.Medium'), code: 'medium' },
    { value: this.localizer.getLocalizedString('Preferences.High'), code: 'high' },
  ];

  constructor(private devices: DevicesManager,
    private dialogs: DialogsService,
    private localizer: LocalizerService) { }

  ngOnInit() {
    if (!this.installationId) {
      this.dialogs.displayErrorNotification('Installations.InvalidInstallationId');
      return;
    }

    if (!this.deviceId) {
      this.dialogs.displayErrorNotification('Devices.InvalidDeviceId');
      return;
    }

    this.loadDevicePreferences();
  }

  // #region Update device preferences methods

  updatePreferencesSettings() {
    if (!this.deviceStatus) {
      return;
    }

    this.isLoading = true;
    this.devices.updateDevicePreferences(this.installationId!, this.deviceId!, this.newDeviceStatus!.device!.preferences!)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: _ => {
          this.deviceStatus = JSON.parse(JSON.stringify(this.newDeviceStatus));
          this.saveDetailsButtonDisabled = false;
        },
        error: _ => this.dialogs.displayErrorNotification('Preferences.ApiUpdateIntercomPreferencesError')
      });
  }

  onValueChanged() {
    if (!this.deviceStatus?.device?.preferences || !this.newDeviceStatus?.device?.preferences) {
      return;
    }

    const currentPreferences = this.deviceStatus!.device.preferences!;
    const newPreferences = this.newDeviceStatus!.device.preferences!;

    const audioChanged = currentPreferences.audio?.sounds?.tones_volume !== newPreferences.audio?.sounds?.tones_volume ||
      currentPreferences.audio?.sounds?.voices_volume !== newPreferences.audio?.sounds?.voices_volume ||
      currentPreferences.audio?.calls?.volume !== newPreferences.audio?.calls?.volume ||
      currentPreferences.audio?.sounds?.mode !== newPreferences.audio?.sounds?.mode;

    const videoChanged = currentPreferences.video?.balance?.brightness !== newPreferences.video?.balance?.brightness ||
      currentPreferences.video?.balance?.contrast !== newPreferences.video?.balance?.contrast ||
      currentPreferences.video?.stream?.quality !== newPreferences.video?.stream?.quality;

    const displayChanged = currentPreferences.display?.contrast !== newPreferences.display?.contrast ||
      currentPreferences.display?.behaviors?.call_alias_auto_select !== newPreferences.display?.behaviors?.call_alias_auto_select;

    this.saveDetailsButtonDisabled = audioChanged || videoChanged || displayChanged;
  }

  // #endregion

  // #region Utility methods

  /**
   * Retrieves the current audio mode value from the device's preferences.
   * @returns {string} The value of the audio mode corresponding to the current mode code.
   */
  getModeValue(): string {
    const modeCode = this.newDeviceStatus!.device.preferences!.audio!.sounds!.mode;
    return this.mode.find(item => item.code === modeCode)!.value
  }

  /**
   * Retrieves the current quality value from the device's preferences.
   * @returns {string} The value of the quality corresponding to the current quality code.
   */
  getQualityValue(): string {
    const qualityCode = this.newDeviceStatus!.device.preferences!.video!.stream!.quality;
    return this.resolution.find(item => item.code === qualityCode)!.value
  }

  /**
   * Handles the back navigation logic, resetting the view to the initial state
   */
  back() {
    this.backEvent.emit();
  }

  // #endregion

  // #region Private methods

  /**
   * Fetches the device preferences.
   */
  private loadDevicePreferences() {
    this.isBusy = true;

    this.devices.getDeviceStatus(this.installationId!, this.deviceId!)
      .pipe(finalize(() => this.isBusy = false))
      .subscribe({
        next: deviceStatus => {
          this.deviceStatus = deviceStatus;
          this.newDeviceStatus = JSON.parse(JSON.stringify(deviceStatus));
        },
        error: _ => this.dialogs.displayErrorNotification('Preferences.ApiGetIntercomStatusError')
      });
  }

}
