import { Observable } from "rxjs";
import { BaseAuthService } from "../utils/base-auth-service";
import { UserAttributes } from "../models/user-attributes";

export abstract class AuthenticationManager extends BaseAuthService {

    currentPasswordResetUserEmail: string | null = null;    // The email of the current user that is performing a password reset

    currentSignUpUserEmail: string | null = null;           // The email of the current user that is performing a sign up
    
    abstract login(username: string, password: string): Observable<boolean>;

    abstract signUp(username: string, newPassword: string, repeatPassword: string): Observable<boolean>;

    abstract confirmSignUp(username: string, code: string): Observable<boolean>;

    abstract logout(): Observable<void>;

    abstract getCurrentUserId(): Observable<string>;

    abstract currentUserAttributes(): Observable<UserAttributes>;

    abstract userLoggedIn(): Observable<boolean>;

    abstract userLoggedInUpdates(): Observable<boolean>;

    abstract resetPassword(email: string): Observable<boolean>;

    abstract completeResetPassword(email: string, code: string, newPassoword: string): Observable<boolean>;

    abstract cancelResetPassword(): void;

    abstract updatePassword(oldPassword: string, newPassword: string): Observable<boolean>;

    abstract cancelSignUp(): void;

    abstract updateUserAttributes(name: string, family_name: string): Observable<boolean>;

    abstract userAttributesUpdates(): Observable<UserAttributes | null>;

}