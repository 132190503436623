import { Injectable } from "@angular/core";
import { InvitationsManager } from "./invitations-manager";
import { Observable, throwError } from "rxjs";
import { ManagerInvitation } from "../../shared/models/invitations/manager-invitation";
import { UserInvitation } from "../../shared/models/invitations/user-invitation";
import { CreateManagerInvitationDTO } from "../dto/create-manager-invitation-dto";

@Injectable()
export class ApiInvitationsManager extends InvitationsManager {

    getInvitations(installation_id: string, unit_id: string): Observable<UserInvitation[]> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        return this.get<UserInvitation[]>(`/installations/${installation_id}/units/${unit_id}/invitations`);
    }

    createUserInvitation(installation_id: string, unit_id: string, content: any): Observable<UserInvitation[]> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        return this.post<UserInvitation[]>(`/installations/${installation_id}/units/${unit_id}/invitations`, content);
    }

    deleteUserInvitation(installation_id: string, unit_id: string, invitation_code: string): Observable<boolean> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!unit_id) return throwError(() => new Error('Invalid unit id'));

        if (!invitation_code) return throwError(() => new Error('Invalid invitation code'));

        return this.delete(`/installations/${installation_id}/units/${unit_id}/invitations/${invitation_code}`);
    }

    getManagerInvitations(installation_id: string): Observable<ManagerInvitation[]> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        return this.get<ManagerInvitation[]>(`/installations/${installation_id}/invitations`);
    }

    getManagerInvitationDetails(installation_id: string, invitation_code: string): Observable<ManagerInvitation> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!invitation_code) return throwError(() => new Error('Invalid invitation code'));

        return this.get<ManagerInvitation>(`/installations/${installation_id}/invitations/${invitation_code}`);
    }

    createManagerInvitation(installation_id: string, content: CreateManagerInvitationDTO): Observable<ManagerInvitation[]> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        return this.post<ManagerInvitation[]>(`/installations/${installation_id}/invitations`, content);
    }

    deleteManagerInvitation(installation_id: string, invitation_code: string): Observable<boolean> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!invitation_code) return throwError(() => new Error('Invalid invitation code'));

        return this.delete(`/installations/${installation_id}/invitations/${invitation_code}`);
    }

    acceptManagerInvitation(installation_id: string, invitation_code: string): Observable<boolean> {
        if (!installation_id) return throwError(() => new Error('Invalid installation id'));

        if (!invitation_code) return throwError(() => new Error('Invalid invitation code'));

        return this.post<boolean>(`/installations/${installation_id}/invitations/${invitation_code}/accept`);
    }
}