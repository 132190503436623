<dx-toolbar id="header-toolbar">
  <dxi-item location="before" widget="dxButton">
    <dx-button
      (onClick)="toggleSidebar()"
      [icon]="sidebarButtonIcon"
      type="default"
    ></dx-button>
  </dxi-item>

  <dxi-item location="after" widget="dxButton">
    <div *dxTemplate>
      <app-account-thumbnail
        [accountName]="userAttributes | username"
        id="account-item"
      ></app-account-thumbnail>
      <dx-context-menu
        id="header-context-menu"
        target="#account-item"
        [dataSource]="accountMenuItems"
        showEvent="click"
        (onItemClick)="onContextItemClicked($event)"
      >
        <div
          *dxTemplate="let data of 'item'; let i = index"
          [ngClass]="{ 'logout-action': i == 1 }"
        >
          {{ data.text | i18next }}
        </div>
      </dx-context-menu>
    </div>
  </dxi-item>
</dx-toolbar>
