import { Observable } from "rxjs";
import { BaseApiService } from "../../shared/utils/base-api-service";
import { IntercomStatus } from "../../intercoms/models/iot/intercom_status";
import { IntercomInput } from "../models/intercom_input";
import { Intercom } from "../models/intercom";

export abstract class IntercomsManager extends BaseApiService {

    /**
     * Retrieves a specific intercom from a unit.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @returns the intercom from the unit or null if it does not exist.
     */
    abstract getIntercom(installation_id: string, unit_id: string, intercom_id: string): Observable<Intercom | null>;

    /**
     * Updates an intercom.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @param content the new intercom content
     * @returns true if the intercom was updated successfully, false otherwise.
     */
    abstract updateIntercom(installation_id: string, unit_id: string, intercom_id: string, content: any): Observable<boolean>;

    /**
     * Deletes an intercom.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @returns true if the intercom was deleted successfully, false otherwise.
     */
    abstract deleteIntercom(installation_id: string, unit_id: string, intercom_id: string): Observable<boolean>;

    /**
     * Retrieves all input actions of an intercom.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @returns the list of input actions of the intercom.
     */
    abstract getIntercomInputs(installation_id: string, unit_id: string, intercom_id: string): Observable<IntercomInput[]>;

    /**
     * Adds an input action to an intercom.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @param content the input action content
     * @returns true if the input action was added successfully, false otherwise.
     */
    abstract addIntercomInput(installation_id: string, unit_id: string, intercom_id: string, content: any): Observable<boolean>;

    /**
     * Deletes an input action from an intercom.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @param input_id the input action id
     * @returns true if the input action was deleted successfully, false otherwise.
     */
    abstract deleteIntercomInput(installation_id: string, unit_id: string, intercom_id: string, input_id: string): Observable<boolean>;

    /**
     * Retrieves the status of an intercom.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @returns the status of the intercom.
     */
    abstract getIntercomStatus(installation_id: string, unit_id: string, intercom_id: string): Observable<IntercomStatus>;

    /**
     * Updates the preferences of an intercom.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param intercom_id the intercom id
     * @param content the new intercom preferences
     * @returns true if the intercom preferences were updated successfully, false otherwise.
     */
    abstract updateIntercomPreferences(installation_id: string, unit_id: string, intercom_id: string, content: any): Observable<boolean>;
}