import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogsService } from './services/dialogs.service';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
import { DialogComponent } from './dialog/dialog.component';
import { SharedModule } from '../shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [
    DialogComponent
  ],
  imports: [
    CommonModule,
    DxPopupModule,
    DxButtonModule,
    SharedModule,
    AngularSvgIconModule
],
  exports: [
    DialogComponent
  ],
  providers: [
    DialogsService
  ]
})
export class DialogsModule { }
