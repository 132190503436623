import { Component, Input } from "@angular/core";
import { BaseDialogContent } from "../../dialogs/base-dialog-component";
import { UnitsManager } from "../services/units-manager";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { finalize } from "rxjs";
import { openCloseAnimation } from "../../dialogs/animations/show-hide.animation";
import { FarfisaDevice } from "../../shared/models/devices/farfisa_device";
import { UserAccess } from "../models/user-access";
import { EditUnitUserDto } from "../dto/edit-unit-user-dto";

@Component({
  selector: "app-edit-unit-user-dialog",
  templateUrl: "./edit-unit-user-dialog.component.html",
  styleUrl: "./edit-unit-user-dialog.component.scss",
  animations: [openCloseAnimation],
})
export class EditUnitUserDialogComponent extends BaseDialogContent {
  @Input()
  user?: UserAccess;

  @Input()
  installationId?: string;

  @Input()
  unitId?: string;

  @Input()
  devices?: FarfisaDevice[];

  email: string = "";
  currentSelectedDevices: string[] = [];

  busyMessage = "Users.UpdatePermissionWaitMessage";

  constructor(private unitsManager: UnitsManager, dialogs: DialogsService) {
    super(dialogs);
  }

  override passData(data: any): void {
    this.user = data.user as UserAccess;
    this.devices = data.devices as FarfisaDevice[];
    this.installationId = data?.installationId;
    this.unitId = data?.unitId;
    this.email = this.user?.email;
    this.user.devices?.forEach((d) => {
      this.currentSelectedDevices.push(d.device_id);
    });
  }

  onSelectionChanged(event: any) {
    const selectedKeys = event.currentSelectedRowKeys;
    const deselectedKeys = event.currentDeselectedRowKeys;
    if (deselectedKeys.length > 0) {
      this.handleDeselection(deselectedKeys);
    } else if (selectedKeys.length > 0) {
      this.handleSelection(selectedKeys);
    }
  }

  updatePermission() {
    if (!this.installationId || !this.unitId || !this.user) {
      console.error("Missing required data");
      return;
    }

    this.isBusy = true;
    this.busyMessage = "Users.UpdatePermissionWaitMessage";
    const content: EditUnitUserDto = {
      devices_ids: this.currentSelectedDevices,
    };

    this.unitsManager
      .updateUnitUserDevicesPermissions(
        this.installationId,
        this.unitId,
        this.user.sub,
        content
      )
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (_) =>
          this.handleAPIResponse(
            true,
            "Invitations.ApiUpdateUnitUserDevicesPermissionsSuccess"
          ),
        error: (_) =>
          this.handleAPIResponse(
            false,
            "Invitations.ApiUpdateUnitUserDevicesPermissionsError"
          ),
      });
  }

  deleteUnitUser() {
    if (!this.installationId || !this.unitId || !this.user) {
      console.error("Missing required data");
      return;
    }

    this.isBusy = true;
    this.busyMessage = "Units.DeletingUserWaitMessage";
    this.unitsManager
      .deleteUnitUser(this.installationId, this.unitId, this.user.sub)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (success) =>
          this.handleAPIResponse(success, "Units.UnitUserDeleteSuccess"),
        error: (error) =>
          this.handleAPIResponse(false, "Units.UnitUserDeleteError"),
      });
  }

  private handleAPIResponse(success: boolean, message: string, data?: any) {
    if (success) {
      this.dialogs.hideDialog();
      this.dialogs.displaySuccessNotification(message);
      this.dialogs.sendDialogResult(data);
    } else {
      this.dialogs.displayErrorNotification(message);
    }
  }

  private handleDeselection(deselectedKeys: any[]) {
    deselectedKeys.forEach((element: any) => {
      this.currentSelectedDevices.splice(
        this.currentSelectedDevices.findIndex(
          (selectedDevice: any) => selectedDevice == element
        ),
        1
      );
    });
  }

  private handleSelection(selectedKeys: any[]) {
    selectedKeys.forEach((element: any) => {
      if (!this.currentSelectedDevices.includes(element))
        this.currentSelectedDevices.push(element);
    });
  }
}
