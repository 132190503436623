import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Installation } from '../../shared/models/installations/installation';
import { InstallationsManager } from '../services/installations-manager';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { EditInstallationDto } from '../dto/edit-installation-dto';
import { ImageUploadService } from '../../shared/services/image-upload.service';
import { finalize, switchMap } from 'rxjs';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { BaseInstallationDialog } from '../base-installation-dialog.component';
import { NavigationService } from '../../shared';

@Component({
  selector: 'app-edit-installation-dialog',
  templateUrl: './edit-installation-dialog.component.html',
  styleUrl: './edit-installation-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class EditInstallationDialogComponent extends BaseInstallationDialog {

  @ViewChild('fileInput') fileInput!: ElementRef;

  @Input()
  installation: Installation | undefined;

  @Input()
  askForDisassociation: boolean = false;

  expiration = this.installations.eventsExpirations();
  selectedExpiration: number = 1;

  thereAreChanges: boolean = false;

  askForDeletion: boolean = false;

  constructor(dialogs: DialogsService, installations: InstallationsManager,
    private imageUploader: ImageUploadService, private navigation: NavigationService) {
    super(dialogs, installations);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.installation = data.installation as Installation;
    if (this.installation) {
      this.installationName = this.installation.name;
      this.installationPasscode = this.installation.passcode || '';
      this.selectedExpiration = this.installation.call_events_expiration ?? 1;
      this.selectedLanguage = this.installation.language ?? 'en';
      this.imageContent = this.installation.image_url || 'assets/illustrations/installation_placeholder.svg';
    }

    if (data.askForDisassociation || false) {
      this.disassociateInstallation();
    }
  }

  //#endregion

  //#region installation deletion methods

  deleteInstallation() {
    this.hideTitleRequested.emit(true);
    this.askForDeletion = true;
  }

  completeInstallationRemoval() {
    this.isBusy = true;
    this.installations.deleteInstallation(this.installation!.id).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe({
      next: _ => {
        this.dialogs.hideDialog();
        this.navigation.navigateToInstallationsList();
      }
    });
  }

  //#endregion

  //#region disassociate installation methods 

  disassociateInstallation() {
    this.hideTitleRequested.emit(true);
    this.askForDisassociation = true;
  }

  completeInstallationDisassociation() {
    this.isBusy = true;
    this.installations.disassociateInstallation(this.installation!.id).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe({
      next: _ => {
        this.dialogs.hideDialog();
        this.navigation.navigateToInstallationsList();
      }
    });
  }

  //#endregion

  //#region installation image edit methods

  openFinderDialog() { this.fileInput.nativeElement.click(); }

  private hasImageChanges(): boolean {
    return this.selectedImage != null;
  }

  //#endregion

  //#region installation update methods

  updateInstallation() {
    if (!this.installation) {
      return;
    }

    if (this.hasFormChanges()) {
      this.isBusy = true;
      const changes = this.buildChangesDict();
      console.log(changes);

      this.installations.updateInstallation(this.installation.id, changes).pipe(
        finalize(() => this.isBusy = false)
      ).subscribe({
        next: _ => this.handleAPIResponse(true, 'Installations.InstallationDetailsEditSuccess'),
        error: _ => this.handleAPIResponse(false, 'Installations.ApiUpdateError')
      });
    }

    if (this.hasImageChanges()) {
      this.isBusy = true;
      this.installations.getInstallationImageUploadUrl(this.installation.id).pipe(
        switchMap(response => this.imageUploader.uploadFile(response, this.selectedImage!)),
        finalize(() => this.isBusy = false)
      ).subscribe({
        next: _ => this.handleAPIResponse(true, 'Installations.InstallationImageEditSuccess'),
        error: _ => this.handleAPIResponse(false, 'Installations.ApiUpdateError')
      });
    }
  }

  override updateConfirmButtonState() {
    this.thereAreChanges = (this.hasFormChanges() && this.isValidPasscode(this.installationPasscode)) ||
      this.hasImageChanges();
  }

  private hasFormChanges(): boolean {
    const changes = this.buildChangesDict();
    return Object.keys(changes).length > 0;
  }

  private buildChangesDict(): EditInstallationDto {
    const dto = {} as EditInstallationDto;
    if (this.installationName != this.installation?.name) {
      dto.name = this.installationName;
    }

    if (this.installationPasscode != this.installation?.passcode) {
      dto.passcode = this.installationPasscode;
    }

    if (this.selectedLanguage != (this.installation?.language || 'en')) {
      dto.language = this.selectedLanguage;
    }

    if (this.selectedExpiration != (this.installation?.call_events_expiration || 1)) {
      dto.call_events_expiration = this.selectedExpiration;
    }

    return dto;
  }

  private handleAPIResponse(success: boolean, message: string) {
    if (success) {
      this.dialogs.hideDialog();
      this.dialogs.displaySuccessNotification(message);
      const changes = this.buildChangesDict();
      this.dialogs.sendDialogResult(changes);
    } else {
      this.dialogs.displayErrorNotification(message);
    }
  }

}
