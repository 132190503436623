import { Component, Input } from '@angular/core';
import { UnitsManager } from '../services/units-manager';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { Installation } from '../../shared/models/installations/installation';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { BaseEditUnitDialogComponent } from '../base-edit-unit-dialog';
import { finalize } from 'rxjs';
import { CreateUnitDto } from '../dto/create-unit-dto';

@Component({
  selector: 'app-create-unit-detail',
  templateUrl: './create-unit-dialog.component.html',
  styleUrl: './create-unit-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class CreateUnitDialogComponent extends BaseEditUnitDialogComponent {

  @Input()
  installation?: Installation;

  createButtonEnabled = false;

  constructor(private units: UnitsManager, dialogs: DialogsService) {
    super(dialogs);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.installation = data as Installation;
    this.populateInstallationDevices(this.installation);
  }

  override updateConfirmButtonState() {
    this.createButtonEnabled = this.unitName.trim() != '' && this.isValidUnitAlias();
  }

  createUnit() {
    if (!this.installation) {
      return;
    }

    this.isBusy = true;
    const data = this.buildDto();
    this.units.createUnit(this.installation.id, data).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe({
      next: _ => this.handleAPIResponse(true, "Units.ApiCreateUnitSuccess", true),
      error: _ => this.handleAPIResponse(false, "Units.ApiCreateUnitError")
    });
  }

  private buildDto(): CreateUnitDto {
    const installationDevices = this.installation?.devices || [];
    return {
      name: this.unitName,
      alias: this.unitAlias,
      location: this.unitLocation,
      notes: this.unitNotes,
      devices: this.buildEditUnitDevicesDto(installationDevices)
    };
  }

}
