import { Observable } from "rxjs";
import { BaseApiService } from "../../shared/utils/base-api-service";
import { DeviceStatus } from "../models/iot/device_status";
import { DeviceInput, OutputAction } from "../models/device_input";
import { DeviceOutput } from "../models/device_output";
import { FarfisaDevice } from "../../shared/models/devices/farfisa_device";
import { DevicePreferences } from "../models/iot/device_status_preferences";
import { PasscodeACAction } from "../models/ac_action/passcode_ac_action";

export abstract class DevicesManager extends BaseApiService {

    /**
     * Retrieves a specific device from an installation.
     * @param installation_id the installation id
     * @param device_id the device id
     * @returns the device from the installation or null if it does not exist.
     */
    abstract getDevice(installation_id: string, device_id: string): Observable<FarfisaDevice | null>;

    /**
     * Retrieves the status for a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @returns the status of the device.
     */
    abstract getDeviceStatus(installation_id: string, device_id: string): Observable<DeviceStatus>;

    /**
     * Updates a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @param name the new name for the device
     * @returns the updated device.
     */
    abstract updateDevice(installation_id: string, device_id: string, name: string): Observable<boolean>;

    /**
     * Deletes a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @returns true if the device was deleted successfully, false otherwise.
     */
    abstract deleteDevice(installation_id: string, device_id: string): Observable<boolean>;

    /**
     * Retrieves all input actions of a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @returns the device inputs.
     */
    abstract getDeviceInputs(installation_id: string, device_id: string): Observable<DeviceInput[]>;

    /**
     * Adds a new device input action.
     * @param installation_id the installation id
     * @param device_id the device id
     * @param device_input the new device input action
     * @param actions the actions associated with the device input
     * @returns true if the device input action was added successfully, false otherwise.
     */
    abstract addDeviceInput(installation_id: string, device_id: string, device_input: DeviceInput, actions: OutputAction[]): Observable<boolean>;

    /**
     * Deletes an input action of a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @param input_id the input id
     * @returns true if the device input action was deleted successfully, false otherwise.
     */
    abstract deleteDeviceInput(installation_id: string, device_id: string, input_id: string): Observable<boolean>;

    /**
     * Retrieves all output actions of a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @returns the device outputs.
     */
    abstract getDeviceOutputs(installation_id: string, device_id: string): Observable<DeviceOutput[]>;

    /**
     * Adds a new device output action.
     * @param installation_id the installation id
     * @param device_id the device id
     * @param device_output the new device output action
     * @returns true if the device output action was added successfully, false otherwise.
     */
    abstract addDeviceOutput(installation_id: string, device_id: string, device_output: DeviceOutput): Observable<boolean>;

    /**
     * Deletes an output action of a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @param output_id the output id
     * @returns true if the device output action was deleted successfully, false otherwise.
     */
    abstract deleteDeviceOutput(installation_id: string, device_id: string, output_id: string): Observable<boolean>;

    /**
     * Updates the preferences of a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @param updated_device_preferences the new device preferences
     * @returns true if the device preferences were updated successfully, false otherwise.
     */
    abstract updateDevicePreferences(installation_id: string, device_id: string, updated_device_preferences: DevicePreferences): Observable<boolean>;

    /**
     * Retrieves all passcode actions of a device.
     * @param installation_id the installation id
     * @param device_id the device id
     * @returns the device passcodes.
     */
    abstract getDevicePasscodes(installation_id: string, device_id: string): Observable<PasscodeACAction[]>;

    /**
     * Adds a new device passcode for access control action.
     * @param installation_id the installation id
     * @param device_id the device id
     * @param code the passcode code
     * @param outputs the output actions associated with the passcode
     * @returns list of passcode actions updated.
     */
    abstract addDevicePasscode(installation_id: string, device_id: string, code: string, outputs: OutputAction[]): Observable<PasscodeACAction[]>;

    /**
     * Deletesa passcode for access control action.
     * @param installation_id the installation id
     * @param device_id the device id
     * @param passcode_id the passcode code to delete
     * @returns list of passcode actions updated.
     */
    abstract deletePasscode(installation_id: string, device_id: string, passcode_id: string): Observable<boolean>;
}