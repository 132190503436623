import { Injectable } from '@angular/core';
import { map, Observable, switchMap, throwError } from 'rxjs';
import { LocalizerService } from '../../shared';
import { UnitsManager } from '../../units/services/units-manager';
import { UnitImportedViewModel } from '../models/unit_imported_view_model';
import { saveAs } from 'file-saver';

@Injectable()
export class CsvImportService {

  maxFileSize = 5 * 1024 * 1024; // 5MB
  maxFileRows = 1000;

  constructor(private localizer: LocalizerService, private unitsManager: UnitsManager) { }

  isCompatibleFileSize(csvFile: File): boolean {
    if (!csvFile) {
      return false;
    }

    return csvFile.size <= this.maxFileSize;
  }

  processCsvContent(csvData: string): [boolean, string?] {
    const rows = csvData.split('\n').map(row => row.trim()).filter(row => row.length > 0);
    const headers = rows[0].split(';');

    if (rows.length == 0) {
      return [false, 'ImportUnits.EmptyFileError'];
    }

    if (rows.length > this.maxFileRows) {
      return [false, 'ImportUnits.MaxRowsError'];
    }

    const requiredHeaders = ['Unit', 'Alias', 'UnitNotes', 'Location', 'InvitationEmail', 'InvitationNotes'];
    const headerIndexes = requiredHeaders.map(header => headers.indexOf(header));
    const missingHeaders = requiredHeaders.filter((_, index) => headerIndexes[index] == -1);
    if (missingHeaders.length) {
      let message = this.localizer.getLocalizedString('ImportUnits.MissingHeadersError');
      message = message + ': ' + missingHeaders.join(', ');
      return [false, message];
    }

    const [nameIndex, aliasIndex] = headerIndexes;
    const missingValue = rows.slice(1).some(row => {
      const columns = row.split(';');
      return !columns[nameIndex] || !columns[aliasIndex];
    });

    if (missingValue) {
      return [false, 'ImportUnits.MissingValuesError'];
    }

    const finalData = csvData.replace(/\r/g, '');
    return [true, finalData];
  }

  getCsvFileContent(csvFile: File): Observable<string> {
    return new Observable<string>(observer => {
      const reader = new FileReader();
      reader.onload = () => {
        observer.next(reader.result as string);
        observer.complete();
      };

      reader.readAsText(csvFile);
    });
  }

  importUnits(installationId: string, csvFile: File): Observable<UnitImportedViewModel[]> {
    if (!installationId || !csvFile) {
      return throwError(() => new Error("Invalid parameters"));
    }

    if (!this.isCompatibleFileSize(csvFile)) {
      return throwError(() => new Error("ImportUnits.MaxSizeError"));
    }

    return this.getCsvFileContent(csvFile).pipe(
      map(content => {
        const [success, data] = this.processCsvContent(content);
        if (!success || !data) {
          throw new Error(data);
        }

        return data;
      }),
      switchMap(csvData => this.unitsManager.importUnits(installationId, csvData))
    );
  }

  downloadFileTemplate() {
    let content = "Unit;Alias;UnitNotes;Location;InvitationEmail;InvitationNotes";
    content += "\nCasa Rossi;1;Delle note, solo se necessarie;Primo piano;xxxx@gmail.com;Un messaggio da includere nell'invito";
    const blob = new Blob([content], { type: 'text/csv' });
    saveAs(blob, 'import_template.csv');
  }

}
