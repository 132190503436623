<div class="dialog-vertial-layout" [@openClose]="isBusy || askForDeletion || askForDisassociation ? 'closed' : 'open'">

    <div class="horizontal-flex-row full-width">

        <div id="image-container">
            <img [src]="imageContent" id="image" />
            <div id="layer"></div>
        </div>

        <div class="multiple-danger-button-container">
            <dx-button [text]="'Installations.Disassociate' | i18next" type="danger" stylingMode="outlined"
                (onClick)="disassociateInstallation()">
            </dx-button>
            <dx-button [text]="'Installations.DeleteInstallation' | i18next" type="danger" stylingMode="contained"
                (onClick)="deleteInstallation()">
            </dx-button>
        </div>

    </div>

    <div class="row-with-label">
        <dx-button class="installation-picture" type="default" stylingMode="outlined"
            [text]="'Installations.InstallationImage' | i18next" icon="refresh" (onClick)="openFinderDialog()">
        </dx-button>
        <input type="file" #fileInput accept="image/*" style="display:none" (change)="onFileSelected($event)" />
    </div>

    <dx-text-box [(value)]="installationName" [label]="'General.Name' | i18next" labelMode="static"
        stylingMode="outlined" [placeholder]="'Installations.NamePlaceholder' | i18next" valueChangeEvent="keyup"
        [showClearButton]="true" (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <div class="row-with-label">
        <dx-text-box class="full-width" [(value)]="installationPasscode" [label]="'Installations.PassCode' | i18next"
            labelMode="static" valueChangeEvent="keyup" (onValueChanged)="updateConfirmButtonState()"
            stylingMode="outlined" [mode]="passcodeFieldMode">
            <dxi-button name="toggleVisibility" location="after" [options]="passcodeButtonOptions">
            </dxi-button>
        </dx-text-box>
        <span class="label"> {{'Installations.PassCodeMessage' | i18next}}</span>
    </div>

    <div class="horizontal-flex-row full-width">
        <div>{{"Installations.SelectedLanguage" | i18next}}</div>
        <dx-select-box [(value)]="selectedLanguage" [dataSource]="languages" displayExpr="name" valueExpr="code"
            stylingMode="underlined" class="custom-select" (valueChange)="updateConfirmButtonState()"></dx-select-box>
    </div>

    <div class="row-with-label">
        <div class="horizontal-flex-row full-width">
            <div>{{"Installations.Expiration" | i18next}}</div>
            <dx-select-box [(value)]="selectedExpiration" [dataSource]="expiration" displayExpr="label"
                valueExpr="value" stylingMode="underlined" class="custom-select"
                (valueChange)="updateConfirmButtonState()">
            </dx-select-box>
        </div>
        <span class="label"> {{'Installations.ExpirationEvents' | i18next}}</span>
    </div>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="updateInstallation()"
            [disabled]="!thereAreChanges">
        </dx-button>
    </div>

</div>

<!-- Delete installation content -->
<div class="dialog-vertial-layout" [@openClose]="askForDeletion && !isBusy ? 'open' : 'closed'">
    <svg-icon src="assets/icons/ic_warning.svg" class="warning-icon"></svg-icon>
    <div class="headline-small">{{"General.Attention" | i18next}}</div>
    <div>{{"Installations.DeleteInstallationMessage" | i18next}}</div>
    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Delete' | i18next" type="danger" (onClick)="completeInstallationRemoval()">
        </dx-button>
    </div>
</div>

<!-- Disassociate installation content -->
<div class="dialog-vertial-layout" [@openClose]="askForDisassociation && !isBusy ? 'open' : 'closed'">
    <svg-icon src="assets/icons/ic_warning.svg" class="warning-icon"></svg-icon>
    <div class="headline-small">{{"General.Attention" | i18next}}</div>
    <div>{{"Installations.DisassociateInstallationMessage" | i18next}}</div>
    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Delete' | i18next" type="danger" (onClick)="completeInstallationDisassociation()">
        </dx-button>
    </div>
</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Installations.InstallationEditWaitMessage'"></app-spinner>
</div>