export const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const passwordRegex = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/);

export const profileColorPalette = [
    "#F8BFE3",
    "#D9F8FB",
    "#C8FDAA",
    "#FDD3AA",
    "#C9EAFF",
    "#CEC9FF",
    "#AAFDCF",
    "#FBA4A4",
    "#FFC9CB",
    "#F8EFBF"
];