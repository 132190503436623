<app-base-auth-form [message]="'Auth.SignUpMessage' | i18next" [action]="'Auth.SignUp' | i18next"
    slot="main-content" [isBusy]="isBusy" [errorMessage]="errorMessage" (performAction)="sendConfirmationCode()">

    <dx-text-box [label]="'Auth.Email' | i18next" labelMode="floating" valueChangeEvent="keyup"
        class="item large-top-space" [(value)]="email" (valueChange)="resetError()">
    </dx-text-box>

    <dx-text-box [label]="'Auth.NewPassword' | i18next" labelMode="floating" [mode]="passwordFieldMode"
        valueChangeEvent="keyup" class="item top-space" [(value)]="newPassword" (valueChange)="resetError()">

        <dxi-button name="today" location="after" [options]="passwordButtonOptions">
        </dxi-button>

    </dx-text-box>

    <dx-text-box [label]="'Auth.RepeatPassword' | i18next" labelMode="floating" [mode]="passwordFieldMode"
        valueChangeEvent="keyup" class="item top-space" [(value)]="repeatPassword" (valueChange)="resetError()"
        (onEnterKey)="sendConfirmationCode()">

        <dxi-button name="today" location="after" [options]="passwordButtonOptions">
        </dxi-button>

    </dx-text-box>

    <a [routerLink]="'/account/login' | i18nRoute" class="item top-space" id="login-link">
        {{"Auth.AlreadyRegistered" | i18next}}
    </a>

</app-base-auth-form>