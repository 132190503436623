import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AuthenticationManager } from "../../authentication";
import { SidebarService } from "../services/sidebar.service";
import { NavigationService } from "../../shared";
import { UserAttributes } from "../../shared/models/user-attributes";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { ProfileSettingsComponent } from "../../account/profile-settings/profile-settings.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
})
export class HeaderComponent implements OnInit {
  private _sidebarOpened: boolean = false;

  @Output()
  sidebarButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  sidebarButtonIcon = "menu";

  userAttributes: UserAttributes | null = null;

  accountMenuItems = [
    { text: "Sidebar.ProfileSettings" },
    { text: "Auth.Logout" },
  ];

  constructor(
    private auth: AuthenticationManager,
    private sidebar: SidebarService,
    private navigation: NavigationService,
    private dialogs: DialogsService
  ) {}

  ngOnInit(): void {
    this.auth.currentUserAttributes().subscribe({
      next: (attributes) => (this.userAttributes = attributes),
    });
  }

  toggleSidebar() {
    this._sidebarOpened = !this._sidebarOpened;
    this.sidebarButtonClicked.emit(this._sidebarOpened);
    this.sidebarButtonIcon = this._sidebarOpened ? "close" : "menu";
  }

  onContextItemClicked(event: any) {
    if (!event || !event.hasOwnProperty("itemIndex")) {
      console.error("Cannot handle context menu item click event", event);
      return;
    }

    if (event.itemIndex === 0) {
      this.showProfileSettings();
    } else if (event.itemIndex === 1) {
      this.logout();
    }
  }

  private showProfileSettings() {
    const configuration = {
      title: "Account.EditProfile",
      icon: "assets/icons/ic_edit.svg",
    };

    const data = { userAttributes: this.userAttributes};
    this.dialogs.showDialog(ProfileSettingsComponent, data, configuration);
  }

  private logout() {
    this.auth.logout().subscribe({
      next: () => this.navigation.navigateToLogin(),
    });
  }
}
