export enum AccessLevel {
  INSTALLER = 0,

  MANAGER = 1,

  ADMIN_USER = 2,

  GUEST_USER = 3,

  INSTALLER_USER = 4, // installer and admin user at the same time

  MANAGER_USER = 5, //manager and admin user at the same time
}

export function isManagerAccess(accessLevel?: AccessLevel): boolean {
  return (
    accessLevel === AccessLevel.MANAGER ||
    accessLevel === AccessLevel.MANAGER_USER ||
    accessLevel === AccessLevel.INSTALLER ||
    accessLevel === AccessLevel.INSTALLER_USER
  );
}

export function isUserAccess(accessLevel?: AccessLevel): boolean {
  return (
    accessLevel === AccessLevel.GUEST_USER ||
    accessLevel === AccessLevel.ADMIN_USER ||
    accessLevel === AccessLevel.MANAGER_USER ||
    accessLevel === AccessLevel.INSTALLER_USER
  );
}

export function isInstallerAccess(accessLevel?: AccessLevel): boolean {
  return (
    accessLevel === AccessLevel.INSTALLER ||
    accessLevel === AccessLevel.INSTALLER_USER
  );
}
