import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-account-thumbnail',
  templateUrl: './account-thumbnail.component.html',
  styleUrl: './account-thumbnail.component.scss'
})
export class AccountThumbnailComponent {

  @Input()
  accountName: string = 'Unknown';

}
