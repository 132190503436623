<div [@openClose]="isLoading ? 'closed' : 'open'" *ngIf="!isBusy">
    <div *ngIf="!isLoading && !configureDeviceSettings" class="dialog-vertial-layout">

        <div class="header">
            <svg-icon src="assets/icons/ic_output.svg" id="header-icon"></svg-icon>
            <span id="header-title">{{'General.Outputs' | i18next}}</span>
        </div>

        <div class="row-with-label">
            <span class="row-title">{{'General.NotConfigured' | i18next}}</span>
            <span class="label">{{'Outputs.NotConfiguredMessage' | i18next}}</span>
        </div>

        @if(notConfiguredDeviceOutputs.length > 0) {
        <dx-list id="dataConfigurationList" [dataSource]="notConfiguredDeviceOutputs" keyExpr="address"
            [hoverStateEnabled]="true" (onItemClick)="outputConfiguration($event)" style="height: 100%;"
            itemTemplate="template">
            <div *dxTemplate="let data of 'template'" class="cell">
                <div class="cell-box">
                    <svg-icon src="assets/icons/ic_key.svg" class="icon"></svg-icon>
                    <div class="row-with-label">
                        <span class="title">{{getDataAddressName(data.address)}}</span>
                        <span class="label">{{'Outputs.' +
                            capitalizeFirstLetter(data.kind) | i18next}}</span>
                    </div>
                </div>
                <svg-icon src="assets/icons/ic_arrow_bottom.svg" class="action-icon"></svg-icon>
            </div>
        </dx-list>
        } @else {
        <div class="no-data-message">
            {{'Outputs.NoOutputsToConfigure' | i18next}}
        </div>
        }

        <div class="row-with-label">
            <span class="row-title">{{'General.Configured' | i18next}}</span>
            <span class="label">{{'Outputs.ConfiguredMessage' | i18next}}</span>
        </div>

        @if(configuredDeviceOutputs.length > 0) {
        <dx-list id="dataConfigurationList" [dataSource]="configuredDeviceOutputs" keyExpr="address"
            [hoverStateEnabled]="true" (onItemClick)="outputConfiguration($event)" style="height: 100%;"
            itemTemplate="template">
            <div *dxTemplate="let data of 'template'" class="cell">
                <div class="cell-box">
                    <svg-icon src="assets/icons/ic_{{data.icon}}.svg" class="icon"></svg-icon>
                    <div class="row-with-label">
                        <span class="title">{{data.name}}</span>
                        <span class="label">{{'Outputs.' +
                            capitalizeFirstLetter(data.kind) | i18next}}</span>
                    </div>
                </div>
                <svg-icon src="assets/icons/ic_arrow_bottom.svg" class="action-icon"></svg-icon>
            </div>
        </dx-list>
        } @else {
        <div class="no-data-message">
            {{'Outputs.NoOutputsConfigured' | i18next}}
        </div>
        }

        <div class="dialog-action-bar">
            <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back()">
            </dx-button>
        </div>

    </div>

    <div *ngIf="!isLoading && configureDeviceSettings" class="dialog-vertial-layout">

        <div class="horizontal-flex-row full-width">
            <div class="header">
                <svg-icon src="assets/icons/ic_configure.svg" id="header-icon"></svg-icon>
                <span id="header-title">{{'Outputs.ConfigureOutput' | i18next}}</span>
            </div>

            @if(selectedDeviceOutput?.id) {
            <dx-button [text]="'Inputs.DeleteConfiguration' | i18next" type="danger" stylingMode="outlined"
                (onClick)="deleteDeviceOutput()">
            </dx-button>
            }
        </div>

        <form [formGroup]="form" class="dialog-vertial-layout full-width">

            <div class="row-with-label full-width">
                <div class="name-text-box-container">
                    <svg-icon src='assets/icons/ic_{{selectedIcon}}.svg' class="custom-placeholder select-icon"
                        (click)="toggleIconSelection()"></svg-icon>

                    <dx-text-box class="full-width" [label]="'General.Name' | i18next" labelMode="static"
                        formControlName="name" [placeholder]="'Outputs.OutputName' | i18next" [showClearButton]="true"
                        valueChangeEvent="keyup" stylingMode="outlined">
                    </dx-text-box>

                    <!-- Icon select box -->
                    <div *ngIf="selectIcons" class="icon-select-box">
                        <div *ngFor="let icon of icons" class="icon-option" (click)="selectIcon(icon)">
                            <svg-icon src='assets/icons/ic_{{icon}}.svg' class="select-icon"></svg-icon>
                        </div>
                    </div>
                </div>
                <span class="label">{{'Outputs.OutputIcon' | i18next}}</span>
            </div>

            <dx-text-box class="full-width" [label]="'General.Typology' | i18next" labelMode="static"
                [value]="'Outputs.' + capitalizeFirstLetter(selectedDeviceOutput!.kind!) | i18next"
                valueChangeEvent="keyup" stylingMode="outlined" [disabled]="true">
            </dx-text-box>

            <div class="row-with-label">
                <div class="horizontal-flex-row full-width">
                    <div class="title">{{'Outputs.Mode' | i18next}}</div>
                    <dx-select-box formControlName="mode" [dataSource]="mode" displayExpr="value" valueExpr="code"
                        stylingMode="underlined">
                    </dx-select-box>
                </div>
                <span class="label"> {{'Outputs.ModeMessage' | i18next}}</span>
            </div>

            <div class="row-with-label full-width">
                <div class="timer-text-box-container">
                    <span class=" label custom-placeholder">{{'Outputs.Duration' | i18next}}:</span>
                    <dx-text-box class="full-width" [label]="'Outputs.Timer' | i18next" labelMode="static"
                        [showClearButton]="true" formControlName="timeout" valueChangeEvent="keyup"
                        stylingMode="outlined">
                    </dx-text-box>
                </div>
                <span class="label">{{'Outputs.TimerMessage' | i18next}}</span>
            </div>

        </form>

        <div class="row-with-label">
            <span class="label"> {{'General.RequiredFieldDescription' | i18next}}</span>
        </div>

        <div class="dialog-action-bar">
            <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back()">
            </dx-button>
            <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="addDeviceOutput()"
                [disabled]="!isConfiguringOutput">
            </dx-button>
        </div>

    </div>
</div>

<ng-lottie *ngIf="isBusy && !isLoading" [options]="options"></ng-lottie>

<div *ngIf="isLoading" [@openClose]="'open'" class="dialog-spinner-container">
    <app-spinner [message]="'Outputs.OutputConfigureWaitMessage'"></app-spinner>
</div>