<div class="dialog-vertial-layout" [@openClose]="isBusy || showImportResult ? 'closed' : 'open'">

    <div class="headline-small">{{"ImportUnits.DialogTitle" | i18next}}</div>
    <span>{{"ImportUnits.DialogMessage" | i18next}}</span>

    <div id="file-import-box" [ngClass]="{'drag-over': showDragOverEffect}">
        <svg-icon src="assets/icons/ic_import.svg" class="icon"></svg-icon>
        <span class="medium-text">{{"ImportUnits.DragAndDropTip" | i18next}}</span>

        <div class="file-info">
            @if (selectedFile) {
            {{"ImportUnits.FileSelected" | i18next}}{{selectedFile.name}}
            <dx-button icon="close" (onClick)="clearFileSelection()" stylingMode="text"></dx-button>
            } @else {
            {{"ImportUnits.MaxSizeError" | i18next}}
            }
        </div>

        <dx-button text="browse" type="default" icon="search" (onClick)="showFileSystemDialog()"></dx-button>
        <input type="file" #csvFileInput accept=".csv" style="display:none" (change)="onCSVFileSelected($event)" />
    </div>

    <div class="dialog-action-bar">
        <dx-button [text]="'Template'" (onClick)="downlaodCsvTemplate()" stylingMode="text" type="success"
            icon="contentlayout" style="margin-right: auto;">
        </dx-button>
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" [disabled]="!selectedFile"
            (onClick)="startCsvImport()">
        </dx-button>
    </div>

</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Installations.InstallationEditWaitMessage'"></app-spinner>
</div>

<div [@openClose]="showImportResult ? 'open' : 'closed'">
    <app-import-result [resultItems]="resultItems" [errorMessage]="errorMessage"
        (confirmButtonClicked)="closeDialogAfterImport()" [fileName]="selectedFile?.name ?? ''"></app-import-result>
</div>