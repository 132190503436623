import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { I18NextModule } from 'angular-i18next';
import { NavigationService } from './services/navigation.service';
import { LocalizerService } from './services/localizer.service';
import { I18nRoutePipe } from './pipes/i18n-route.pipe';
import { ScreenService } from './services/screen.service';
import { PasswordPipe } from './pipes/password.pipe';
import { AccessLevelIconPipe } from './pipes/access-level-icon.pipe';
import { AccessLevelTitlePipe } from './pipes/access-level-title.pipe';
import { ProductLineNamePipe, ProductTypeNamePipe } from './pipes/product-line.pipe';
import { ThingNameToSerialNumberPipe } from './pipes/thingname-to-serial-number.pipe';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AccountThumbnailComponent } from './components/account-thumbnail/account-thumbnail.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { WidgetCardComponent } from './components/widget-card/widget-card.component';
import { DxButtonModule } from 'devextreme-angular';
import { FullAddressPipe } from './pipes/full-address.pipe';
import { LabelComponent } from './components/label/label.component';
import { NoDomainEmailPipe } from './pipes/no-domain-email.pipe';
import { ImageUploadService } from './services/image-upload.service';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LottieComponent } from 'ngx-lottie';
import { FarfisaDeviceToOutputsStringPipe, FarfisaDevicesToStringPipe, UnitDevicesToStringPipe } from './pipes/devices.pipe';
import { NetworkService } from './services/network.service';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ShimmerOverlayComponent } from './components/shimmer-overlay/shimmer-overlay.component';
import { CognitoAuthenticationManager } from './services/cognito-authentication-manager';
import { LabelBackgroundPipe } from './pipes/label-background.pipe';



@NgModule({
    declarations: [
        I18nRoutePipe,
        PasswordPipe,
        AccessLevelIconPipe,
        AccessLevelTitlePipe,
        ProductLineNamePipe,
        ProductTypeNamePipe,
        ThingNameToSerialNumberPipe,
        FarfisaDeviceToOutputsStringPipe,
        FarfisaDevicesToStringPipe,
        UnitDevicesToStringPipe,
        FullAddressPipe,
        NoDomainEmailPipe,
        LabelBackgroundPipe,
        AccountThumbnailComponent,
        BreadcrumbComponent,
        WidgetCardComponent,
        LabelComponent,
        SpinnerComponent,
        PageHeaderComponent,
        ShimmerOverlayComponent
    ],
    exports: [
        I18NextModule,
        I18nRoutePipe,
        PasswordPipe,
        AccessLevelIconPipe,
        AccessLevelTitlePipe,
        ProductLineNamePipe,
        ProductTypeNamePipe,
        ThingNameToSerialNumberPipe,
        FarfisaDeviceToOutputsStringPipe,
        FarfisaDevicesToStringPipe,
        UnitDevicesToStringPipe,
        FullAddressPipe,
        NoDomainEmailPipe,
        LabelBackgroundPipe,
        AccountThumbnailComponent,
        BreadcrumbComponent,
        WidgetCardComponent,
        LabelComponent,
        SpinnerComponent,
        PageHeaderComponent,
        ShimmerOverlayComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        AngularSvgIconModule,
        DxButtonModule,
        LottieComponent,
        I18NextModule.forRoot()
    ],
    providers: [
        ScreenService,
        NavigationService,
        LocalizerService,
        ImageUploadService,
        NetworkService,
        CognitoAuthenticationManager,
        AccessLevelTitlePipe,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class SharedModule { }
