import { defaultInterpolationFormat, I18NextModule } from "angular-i18next";

export const i18nextOptions = {
    supportedLngs: ['it', 'en'],
    fallbackLng: 'en',
    debug: false,
    returnEmptyString: false,
    ns: [
        'translations'
    ],
    interpolation: {
        format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
    },
    backend: {
        loadPath: '/assets/i18n/{{lng}}.json',
    },
    detection: {
        order: ['path', 'localStorage', 'navigator'],
        lookupQuerystring: 'lng',
        lookupFromPathIndex: 0,
        caches: ['localStorage']
    }
};