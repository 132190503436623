import { Injectable } from "@angular/core";
import { Observable, catchError, from, map, of, tap, throwError } from "rxjs";
import { AuthenticationManager } from "./authentication-manager";
import { LocalizerService } from "./localizer.service";
import { emailRegex, passwordRegex } from "../constants";
import { fetchUserAttributes } from "aws-amplify/auth";
import { UserAttributes } from "../models/user-attributes";


@Injectable()
export class CognitoAuthenticationManager extends AuthenticationManager {

    constructor(private localizer: LocalizerService) {
        super();
    }

    login(username: string, password: string): Observable<boolean> {
        if (!username) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryUsernameError"))
        }

        if (!password) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryPasswordError"))
        }

        return this.baseSignIn(username, password);
    }

    signUp(username: string, newPassword: string, repeatPassword: string): Observable<boolean> {
        if (!username) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryUsernameError"))
        }

        if (!emailRegex.test(username)) {
            return throwError(() => this.localizer.getLocalizedString("Auth.InvalidEmailFormatError"))
        }

        if (!newPassword || !repeatPassword) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryPasswordError"))
        }

        if (!passwordRegex.test(newPassword)) {
            return throwError(() => this.localizer.getLocalizedString("Auth.InvalidNewPasswordError"))
        }

        if (newPassword !== repeatPassword) {
            return throwError(() => this.localizer.getLocalizedString("Auth.PasswordsDoNotMatchError"))
        }

        this.currentSignUpUserEmail = username;
        return this.baseSignUp(username, newPassword);
    }

    confirmSignUp(username: string, code: string): Observable<boolean> {
        if (!code) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryCodeError"))
        }

        return this.baseConfirmSignUp(username, code);
    }

    logout(): Observable<void> {
        return this.baseSignOut();
    }

    getCurrentUserId(): Observable<string> {
        return this.baseGetCurrentUser();
    }

    userLoggedIn(): Observable<boolean> {
        return this.getCurrentUserId().pipe(
            map(userId => userId != null),
            catchError(e => of(false)),
            tap(loggedIn => this._userLoggedInUpdateSubject.next(loggedIn))
        );
    }

    userLoggedInUpdates(): Observable<boolean> {
        return this._userLoggedInUpdateSubject.asObservable();
    }

    currentUserAttributes(): Observable<UserAttributes> {
        return from(fetchUserAttributes()).pipe(
            map(output => output as UserAttributes),
            tap(attributes => this._userAttributesSubject.next(attributes))
        );
    }

    resetPassword(email: string): Observable<boolean> {
        if (!email) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryEmailError"));
        }

        if (!emailRegex.test(email)) {
            return throwError(() => this.localizer.getLocalizedString("Auth.InvalidEmailFormatError"));
        }

        this.currentPasswordResetUserEmail = email;
        return this.baseResetPassword(email);
    }

    completeResetPassword(email: string, code: string, newPassword: string): Observable<boolean> {
        if (!email) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryEmailError"));
        }

        if (!emailRegex.test(email)) {
            return throwError(() => this.localizer.getLocalizedString("Auth.InvalidEmailFormatError"));
        }

        if (!code) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryCodeError"));
        }

        if (!newPassword) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryNewPasswordError"));
        }

        if (!passwordRegex.test(newPassword)) {
            return throwError(() => this.localizer.getLocalizedString("Auth.InvalidNewPasswordError"));
        }

        return this.baseConfirmResetPassword(email, code, newPassword).pipe(
            tap(() => this.cancelResetPassword()),
            map(() => true)
        );
    }

    cancelResetPassword(): void {
        this.currentPasswordResetUserEmail = null;
    }

    updatePassword(oldPassword: string, newPassword: string): Observable<boolean> {
        if (!oldPassword) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryOldPasswordError"));
        }

        if (!newPassword) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryNewPasswordError"));
        }

        if (!passwordRegex.test(newPassword)) {
            return throwError(() => this.localizer.getLocalizedString("Auth.InvalidNewPasswordError"));
        }

        return this.baseUpdatePassword(oldPassword, newPassword);
    }

    cancelSignUp(): void {
        this.currentSignUpUserEmail = null;
    }

    updateUserAttributes(name: string, family_name: string): Observable<boolean> {
        if (!name) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryNameError"));
        }

        if (!family_name) {
            return throwError(() => this.localizer.getLocalizedString("Auth.MissingMandatoryFamilyNameError"));
        }

        return this.baseUpdateUserAttributes(name, family_name);
    }

    userAttributesUpdates(): Observable<UserAttributes | null> {
        return this._userAttributesSubject.asObservable();
    }

}