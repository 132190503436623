import { Component, Input } from '@angular/core';
import { FarfisaDevice } from '../../shared/models/devices/farfisa_device';
import { BaseDialogContent } from '../../dialogs/base-dialog-component';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { DevicesManager } from '../services/devices-manager';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-edit-device-dialog',
  templateUrl: './edit-device-dialog.component.html',
  styleUrl: './edit-device-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class EditDeviceDialogComponent extends BaseDialogContent {

  @Input()
  device: FarfisaDevice | undefined;

  @Input()
  installationId: string | undefined;

  @Input()
  canEditDevice: boolean = true;

  deviceName: string = '';

  activeSection: string = '';

  thereAreChanges: boolean = false;

  constructor(private devices: DevicesManager,
    dialogs: DialogsService) {
    super(dialogs);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.device = data.device;
    this.installationId = data.installationId;
    if (this.device) {
      this.deviceName = this.device.name;
    }

    this.canEditDevice = data.canEditDevice ?? true;
  }

  //#endregion

  //#region device deletion methods

  deleteDevice() {
    const title = 'General.Attention';
    const message = 'Devices.DeleteDeviceMessage';
    this.dialogs.askConfirmation(title, message, "danger").subscribe({
      next: confirmed => {
        if (confirmed) {
          this.completeDeviceRemoval();
        }
      }
    });
  }

  private completeDeviceRemoval() {
    if (!this.device || !this.installationId) {
      return;
    }

    this.devices.deleteDevice(this.installationId, this.device!.id).subscribe({
      next: _ => this.dialogs.hideDialog()
    });
  }

  //#endregion

  //#region device update methods

  updateDevice() {
    if (!this.device || !this.installationId) {
      return;
    }

    this.isBusy = true;
    this.devices.updateDevice(this.installationId!, this.device!.id, this.deviceName!)
      .pipe(finalize(() => this.isBusy = false))
      .subscribe({
        next: _ => this.handleAPIResponse(true, 'Devices.ApiUpdateDeviceSuccess'),
        error: _ => this.handleAPIResponse(false, 'Devices.ApiUpdateDeviceError')
      });
  }

  updateConfirmButtonState() {
    this.thereAreChanges = this.deviceName != this.device?.name && this.deviceName.trim().length > 0;
  }

  //#endregion

  toggleSection(section?: string ) {
    this.activeSection = section || '';
  }

  private handleAPIResponse(success: boolean, message: string) {
    if (success) {
      this.dialogs.hideDialog();
      this.dialogs.displaySuccessNotification(message);
      const changes = { name: this.deviceName };
      this.dialogs.sendDialogResult(changes);
    } else {
      this.dialogs.displayErrorNotification(message);
    }
  }

}
