import { Injectable, Type } from '@angular/core';
import { from, Observable, Subject } from 'rxjs';
import { ShowDialogEvent } from '../models/show-dialog-event';
import { DialogButton } from '../models/dialog-button';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { LocalizerService } from '../../shared';
import { DialogConfiguration } from '../models/dialog-configuration';


@Injectable()
export class DialogsService {

  private _showDialogEvents: Subject<ShowDialogEvent> = new Subject<ShowDialogEvent>();
  private _dialogResultEvents: Subject<any> = new Subject<any>();


  constructor(private localizer: LocalizerService) { }

  //#region dialog management methods

  showDialog<T>(component: Type<T>, inputData: any, configuration: DialogConfiguration | undefined = undefined): Observable<any> {
    this._showDialogEvents.next({ component: component, data: inputData, configuration: configuration });
    return this._dialogResultEvents.asObservable();
  }

  hideDialog() {
    this._showDialogEvents.next({ component: null, data: null });
  }

  showDialogEvents(): Observable<ShowDialogEvent> {
    return this._showDialogEvents.asObservable();
  }

  sendDialogResult(result: any) {
    this._dialogResultEvents.next(result);
  }

  //#endregion

  //#region message dialog management methods

  askConfirmation(title: string, message: string, type: "danger" | "default" = "default"): Observable<any> {
    const localizedMessage = this.localizer.getLocalizedString(message);
    const localizedTitle = this.localizer.getLocalizedString(title);
    return from(custom(this.confirmationDialogOptions(localizedTitle, localizedMessage, type)).show());
  }

  showMessageDialog(title: string, message: string): Observable<any> {
    const localizedMessage = this.localizer.getLocalizedString(message);
    const localizedTitle = this.localizer.getLocalizedString(title);
    const buttons = [this.baseDialogButton("Ok", "default", () => true)];
    const configuration = this.buildDialogConfiguration(localizedTitle, localizedMessage, buttons);
    return from(custom(configuration).show()); // @ts-ignore
  }

  private confirmationDialogOptions(title: string, message: string, type: "danger" | "default"): any {
    const yesButtonTitle = this.localizer.getLocalizedString("General.Yes");
    const noButtonTitle = this.localizer.getLocalizedString("General.No");
    return this.baseDialogOptions(title, message, [
      this.baseDialogButton(noButtonTitle, "normal", () => false, "", "text"),
      this.baseDialogButton(yesButtonTitle, type, () => true)
    ]);
  }

  private baseDialogOptions(title: string, message: string, buttonsOptions: DialogButton[]): any {
    return {
      title: title,
      messageHtml: message,
      showTitle: true,
      dragEnabled: false,
      // @ts-ignore
      minWidth: 300,
      maxWidth: "70%",
      buttons: buttonsOptions
    };
  }

  private baseDialogButton(text: string, type: 'danger' | 'default' | 'normal' | 'success', action: () => boolean,
    icon?: string, stylingMode: "contained" | "outlined" | "text" = "contained"): DialogButton {
    if (action == null) {
      action = () => true;
    }

    return {
      text: text,
      type: type,
      stylingMode: stylingMode,
      icon: icon,
      onClick: action
    };
  }

  private buildDialogConfiguration(title: string, message: string, buttons: DialogButton[]): any {
    if (!message || buttons?.length <= 0) {
      console.error("Invalid buildDialogConfiguration parameters");
      return null;
    }

    return {
      title: title ?? "",
      messageHtml: message ?? "",
      showTitle: true,
      dragEnabled: false,
      buttons: buttons,
      width: 540
    };
  }

  //#endregion

  //#region notification messages methods

  //#TODO: use these methods, instead of the ones in the base-page.component.ts

  displaySuccessNotification(message: string) {
    const localizedMessage = this.localizer.getLocalizedString(message);
    this.displayToastNotification(localizedMessage, "success");
  }

  displayErrorNotification(message: string) {
    const localizedMessage = this.localizer.getLocalizedString(message);
    this.displayToastNotification(localizedMessage, "error");
  }

  displayWarningNotification(message: string) {
    const localizedMessage = this.localizer.getLocalizedString(message);
    this.displayToastNotification(localizedMessage, "warning");
  }

  private displayToastNotification(message: string, type: string) {
    const position: any = "bottom-right";
    const direction: any = "up-push";
    const stack = {
      position: position,
      direction: direction
    };

    const options = {
      message: message,
      width: "40vw",
      type: type,
      displayTime: 3500,
      animation: {
        show: {
          type: 'fade', duration: 400, from: 0, to: 1,
        },
        hide: { type: 'fade', duration: 40, to: 0 },
      }
    };

    notify(options, stack);
  }

  //#endregion

}
