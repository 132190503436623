<div class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">

    <dx-text-box [(value)]="country" [label]="'General.Country' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Installations.CountryPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-text-box [(value)]="address" [label]="'General.Address' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Installations.AddressPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-text-box [(value)]="city" [label]="'General.City' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Installations.CityPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-text-box [(value)]="zipCode" [label]="'General.ZipCode' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Installations.ZipCodePlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <span class="label"> {{'General.RequiredFieldDescription' | i18next}}</span>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" [disabled]="!confirmButtonEnabled"
            (onClick)="editInstallationLocation()">
        </dx-button>
    </div>

</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Installations.InstallationEditWaitMessage'"></app-spinner>
</div>