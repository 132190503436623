import { Component } from '@angular/core';

import { inOutAnimation } from '../animations/in-out.animation';
import { BasePageComponent } from '../../shared/components/base-page.component';
import { AuthenticationManager } from '../../shared/services/authentication-manager';
import { NavigationService } from '../../shared/services/navigation.service';
import { LocalizerService, ScreenService } from '../../shared';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  animations: [inOutAnimation]
})
export class LoginComponent extends BasePageComponent {

  username: string = this.auth.currentSignUpUserEmail ?? "";
  password: string = "";

  passwordFieldMode: "password" | "text" = "password";
  passwordButtonOptions = {
    icon: 'eyeopen',
    stylingMode: 'text',
    onClick: () => {
      this.passwordFieldMode = this.passwordFieldMode === "password" ? "text" : "password";
    },
  };

  constructor(private auth: AuthenticationManager,
    private navigation: NavigationService,
    localizer: LocalizerService,
    screen: ScreenService) {
    super(screen, localizer);
  }

  login() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.auth.login(this.username, this.password).subscribe({
      next: this.loginCompleted.bind(this),
      error: this.loginFailed.bind(this)
    });
  }

  private loginCompleted(success: boolean) {
    this.isBusy = false;
    if (success) {
      this.navigation.navigateToInstallationsList();
    } else {
      this.displayError();
    }
  }

  private loginFailed(error: any) {
    console.log(error);
    this.isBusy = false;
    const message = (typeof error === "string") ? error : null;
    this.displayError(message);
  }

}
