import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Unit } from "../../shared/models/units/unit";
import { User } from "../../shared/models/users/user";
import { UnitsManager } from "../../units/services/units-manager";
import { Installation } from "../../shared/models/installations/installation";
import { Store } from "@ngrx/store";
import { selectInstallation } from "../../state/installation/installation.selector";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-unit-sidebar",
  templateUrl: "./unit-sidebar.component.html",
  styleUrl: "./unit-sidebar.component.scss",
})
export class UnitSidebarComponent implements OnChanges, OnInit, OnDestroy {
  private onDestroy = new Subject<void>();

  installation: Installation | null = null;
  unit: Unit | null = null;
  users: User[] = [];

  @Input()
  installationId: string | null = null;

  @Input()
  unitId: string | null = null;

  constructor(private units: UnitsManager, private store: Store) {}

  ngOnInit(): void {
    this.store
      .select(selectInstallation)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((state) => {
        this.installation = state.installation;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  ngOnChanges(_: SimpleChanges): void {
    if (this.installationId && this.unitId) {
      this.units.getUnitDetails(this.installationId, this.unitId).subscribe({
        next: (unit) => (this.unit = unit),
      });

      this.units.getUnitUsers(this.installationId, this.unitId).subscribe({
        next: (users) => (this.users = users),
      });
    }
  }
}
