import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'noDomainEmail' })
export class NoDomainEmailPipe implements PipeTransform {

    transform(value: string | null): string {
        if (!value) {
            return '';
        }

        return value.split('@')[0];
    }

}
