import { Component } from '@angular/core';
import { BaseDialogContent } from '../../dialogs/base-dialog-component';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { Installation } from '../../shared/models/installations/installation';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { EditInstallationLocationDto } from '../dto/edit-installation-location-dto';
import { InstallationsManager } from '../services/installations-manager';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-edit-location-dialog',
  templateUrl: './edit-location-dialog.component.html',
  styleUrl: './edit-location-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class EditLocationDialogComponent extends BaseDialogContent {

  installation?: Installation;

  country: string = '';
  address: string = '';
  city: string = '';
  zipCode: string = '';

  confirmButtonEnabled: boolean = false;

  constructor(dialogs: DialogsService, private installations: InstallationsManager) {
    super(dialogs);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.installation = data as Installation;
    if (this.installation) {
      this.country = this.installation.address?.country || '';
      this.address = this.installation.address?.address || '';
      this.city = this.installation.address?.city || '';
      this.zipCode = this.installation.address?.zip_code || '';
    }
  }

  //#endregion

  updateConfirmButtonState(): void {
    const somethingChanged = this.country != this.installation?.address?.country ||
      this.address != this.installation?.address?.address ||
      this.city != this.installation?.address?.city ||
      this.zipCode != this.installation?.address?.zip_code;

    const everythingIsFilled = this.country?.trim() != '' && this.address?.trim() != '' && this.city?.trim() != '' && this.zipCode?.trim() != '';
    this.confirmButtonEnabled = somethingChanged && everythingIsFilled;
  }

  editInstallationLocation() {
    if (!this.installation) {
      return;
    }

    this.isBusy = true;
    const changes = this.buildChangesDict();
    this.installations.updateInstallation(this.installation.id, changes).pipe(
      finalize(() => this.isBusy = false)
    ).subscribe({
      next: (_) => this.handleAPIResponse(true, 'Installations.InstallationDetailsEditSuccess'),
      error: (_) => this.handleAPIResponse(false, 'Installations.ApiUpdateError')
    });
  }

  private buildChangesDict(): EditInstallationLocationDto {
    return {
      address: {
        country: this.country,
        address: this.address,
        city: this.city,
        zip_code: this.zipCode
      }
    } as EditInstallationLocationDto;
  }

  private handleAPIResponse(success: boolean, message: string) {
    if (success) {
      this.dialogs.hideDialog();
      this.dialogs.displaySuccessNotification(message);
      const changes = this.buildChangesDict();
      this.dialogs.sendDialogResult(changes);
    } else {
      this.dialogs.displayErrorNotification(message);
    }
  }

}
