import { Component, EventEmitter, Input, Output } from '@angular/core';
import { inOutAnimation } from '../animations/in-out.animation';

@Component({
  selector: 'app-base-auth-form',
  templateUrl: './base-auth-form.component.html',
  styleUrl: './base-auth-form.component.scss',
  animations: [inOutAnimation]
})
export class BaseAuthFormComponent {

  @Input()
  message: string = "";

  @Input()
  action: string = "";

  @Input()
  errorMessage: string | null = null;

  @Input()
  isBusy: boolean = false;

  @Output()
  performAction: EventEmitter<void> = new EventEmitter();

  onButtonClick() {
    this.performAction.emit();
  }

}
