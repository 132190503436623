<app-base-auth-form
  [message]="'Auth.LoginMessage' | i18next"
  [action]="'Auth.Login' | i18next"
  slot="main-content"
  [isBusy]="isBusy"
  [errorMessage]="errorMessage"
  (performAction)="login()"
>
  <dx-text-box
    [label]="'Auth.Username' | i18next"
    labelMode="floating"
    valueChangeEvent="input"
    class="item large-top-space"
    [(value)]="username"
    (valueChange)="resetError()"
  ></dx-text-box>

  <dx-text-box
    [label]="'Auth.Password' | i18next"
    labelMode="floating"
    [mode]="passwordFieldMode"
    valueChangeEvent="input"
    class="item top-space"
    [(value)]="password"
    (valueChange)="resetError()"
    (onEnterKey)="login()"
  >
    <dxi-button
      name="toggleVisibility"
      location="after"
      [options]="passwordButtonOptions"
    >
    </dxi-button>
  </dx-text-box>

  <a
    [routerLink]="'/account/reset-password' | i18nRoute"
    class="item top-space"
    id="forget-password-link"
  >
    {{ "Auth.ForgotPassword" | i18next }}
  </a>

  <a
    [routerLink]="'/account/signup' | i18nRoute"
    class="item top-space"
    id="signup-link"
  >
    {{ "Auth.Register" | i18next }}
  </a>
</app-base-auth-form>
