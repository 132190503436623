import { animate, state, style, transition, trigger } from "@angular/animations";

export const openCloseAnimation = trigger("openClose", [
    state(
        'open',
        style({
            height: '*',
            opacity: 1
        }),
    ),
    state(
        'closed',
        style({
            height: '0px',
            opacity: 0
        }),
    ),
    transition('open <=> closed', animate('.2s'))
]);