import { Component, EventEmitter, Input, Output } from '@angular/core';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { CognitoAuthenticationManager } from '../../shared/services/cognito-authentication-manager';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-edit-user-attributes',
  templateUrl: './edit-user-attributes.component.html',
  styleUrl: './edit-user-attributes.component.scss',
  animations: [openCloseAnimation]
})
export class EditUserAttributesComponent {

  @Input() name: string = '';
  @Input() familyName: string = '';
  @Output() backEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading: boolean = false;

  constructor(private dialogs: DialogsService,
    private auth: CognitoAuthenticationManager) { }

  updateUserAttributes() {
    if (!this.name || !this.familyName) {
      return;
    }

    this.isLoading = true;
    this.auth.updateUserAttributes(this.name, this.familyName)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: _ => this.back(true),
        error: _ => this.dialogs.displayErrorNotification('Account.ApiEditUserAttributesError')
      });
  }

  back(isUpdated: boolean) {
    this.backEvent.emit(isUpdated);
  }

}
