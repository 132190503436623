import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../models/installations/address';

@Pipe({ name: 'fullAddress' })
export class FullAddressPipe implements PipeTransform {

    transform(value?: Address): string {
        if (!value || !value.address) {
            return '';
        }

        let result = value.address;
        if (value.city) {
            result += `, ${value.city}`;
        }

        if (value.country) {
            result += `, ${value.country}`;
        }

        return result;
    }

}
