import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizerService } from '../../shared';
import { AccessLevel } from '../../shared/models/users/access-level';
import { User } from '../../shared/models/users/user';

@Component({
  selector: 'app-installation-user-details',
  templateUrl: './installation-user-details.component.html',
  styleUrl: './installation-user-details.component.scss'
})
export class InstallationUserDetailsComponent {

  @Input() isVisible: boolean = false;

  @Input() user: User | null = null;

  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  invitation_roles = [
    {
      title: this.localizer.getLocalizedString('General.Manager'),
      subtitle: this.localizer.getLocalizedString('Invitations.ManagerDetails'),
      values: [AccessLevel.MANAGER_USER, AccessLevel.MANAGER]
    },
    {
      title: this.localizer.getLocalizedString('General.Installer'),
      subtitle: this.localizer.getLocalizedString('Invitations.InstallerDetails'),
      values: [AccessLevel.INSTALLER_USER, AccessLevel.INSTALLER]
    }
  ];

  constructor(private localizer: LocalizerService) { }

  getMatchingRole(accessLevel: AccessLevel): any {
    return this.invitation_roles.find(role =>
      role.values.includes(accessLevel)
    );
  }

  closePopup() {
    this.isVisibleChange.emit(false);
  }
}
