import { EventEmitter } from "@angular/core";
import { DialogsService } from "./services/dialogs.service";

export class BaseDialogContent {

    hideTitleRequested: EventEmitter<boolean> = new EventEmitter<boolean>();

    isBusy: boolean = false;

    constructor(protected dialogs: DialogsService) { }

    passData(data: any) { }

    closeDialog() { this.dialogs.hideDialog(); }

}