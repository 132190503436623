import { CanActivateFn } from "@angular/router";

export const redirectGuard: CanActivateFn = (route, state) => {

  const targetUrl = route.data["targetUrl"];
  const queryParams = new URLSearchParams(state.url.split('?')[1]);

  const redirectUrl = new URL(targetUrl, window.location.origin);
  queryParams.forEach((value, key) => {
    redirectUrl.searchParams.append(key, value);
  });

  window.location.href = redirectUrl.toString();
  return true;
};
