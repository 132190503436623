import { Component } from '@angular/core';
import { BasePageComponent } from '../../shared/components/base-page.component';
import { ScreenService, NavigationService, LocalizerService } from '../../shared';
import { AuthenticationManager } from '../../shared/services/authentication-manager';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss'
})
export class SignupComponent extends BasePageComponent {

  email: string = "";
  newPassword: string = "";
  repeatPassword: string = "";

  passwordFieldMode: "password" | "text" = "password";
  passwordButtonOptions = {
    icon: 'eyeopen',
    stylingMode: 'text',
    onClick: () => {
      this.passwordFieldMode = this.passwordFieldMode === "password" ? "text" : "password";
    },
  };

  constructor(private auth: AuthenticationManager,
    private navigation: NavigationService,
    localizer: LocalizerService,
    screen: ScreenService) {
    super(screen, localizer);
    this.auth.cancelSignUp();
  }

  sendConfirmationCode() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.auth.signUp(this.email, this.newPassword, this.repeatPassword).subscribe({
      next: this.signUpCompleted.bind(this),
      error: this.signUpFailed.bind(this)
    });
  }

  private signUpCompleted() {
    this.isBusy = false;
    this.displaySuccessNotification(this.localizer.getLocalizedString("Auth.SendEmailSuccess"));
    this.navigation.navigateToConfirmSignup()
  }

  private signUpFailed(error: any) {
    console.log(error);
    this.isBusy = false;
    const message = (typeof error === "string") ? error : null;
    this.displayError(message);
  }

}
