import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {

  @Input() title?: string;

  @Input() info?: string;

  @Input() infoIcon?: string;

  @Input() locationInfo?: string;

  @Input() locationInfoIcon?: string;

  @Input() showAreaPlaceholder: boolean = false;

}
