import { Component, OnInit } from "@angular/core";
import { AppInfoService } from "../../shared/services/app-info.service";
import {
  ApplicationVersion,
  ServerApplicationVersion,
} from "../../shared/models/application-version";
import { firstValueFrom } from "rxjs";
import { LocalizerService } from "../../shared";
import { ApplicationLanguage } from "../../shared/utils/application-language";

@Component({
  selector: "app-apllication-info",
  templateUrl: "./apllication-info.component.html",
  styleUrl: "./apllication-info.component.scss",
})
export class ApllicationInfoComponent implements OnInit {
  applicationVersion: ApplicationVersion = this.appInfo.getApplicationVersion();
  serverVersion?: ServerApplicationVersion;

  languages: ApplicationLanguage[] = this.localizer.applicationLanguages();
  currentLanguage: ApplicationLanguage = this.localizer.currentApplicationLanguage();

  constructor(
    private appInfo: AppInfoService,
    private localizer: LocalizerService
  ) {}

  async ngOnInit() {
    this.serverVersion = await firstValueFrom(this.appInfo.getServerVersion());
  }

  selectLanguage(language: ApplicationLanguage) {
    this.currentLanguage = language;
    this.localizer.changeLanguage(language.ISOCode);
  }
}
