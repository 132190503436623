import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class AccountService {

    private _imageSource = new Subject<string | ArrayBuffer | null>();

    constructor() {
        const savedImage = localStorage.getItem('userImage');
        if (savedImage) {
            this._imageSource.next(JSON.parse(savedImage));
        }
    }

    imageSourceUpdated() {
        return this._imageSource.asObservable();
    }

    updateImage(image: string | ArrayBuffer | null) {
        this._imageSource.next(image);
        localStorage.setItem('userImage', JSON.stringify(image));
    }

}