import { Pipe, PipeTransform } from "@angular/core";
import { LicenseKind } from "../models/license";
import { LocalizerService } from "../../shared";

@Pipe({
  name: "licenseKind",
})
export class LicenseKindPipe implements PipeTransform {
  constructor(private localizer: LocalizerService) {}

  // If title is true, return the title of the license kind, otherwise return the icon path
  transform(value: LicenseKind, title = false): string {
    if (!title) {
      switch (value) {
        case LicenseKind.APP_WEB:
          return "assets/icons/ic_web.svg";
        default:
          return "assets/icons/ic_license.svg";
      }
    } else {
      switch (value) {
        case LicenseKind.APP_WEB:
          return "App web";
        default:
          return this.localizer.getLocalizedString("General.Units");
      }
    }
  }
}
