import { Injectable } from "@angular/core";
import {
  ApplicationVersion,
  ServerApplicationVersion,
} from "../models/application-version";
import { BaseApiService } from "../utils/base-api-service";
import { Observable } from "rxjs";
import { version } from "../../../../package.json";

@Injectable({ providedIn: "root" })
export class AppInfoService extends BaseApiService {
  getApplicationVersion(): ApplicationVersion {
    return {
      runtime: "Angular 18",
      version: version,
      releaseDate: "2025-02-26T10:00:00Z",
    } as ApplicationVersion;
  }

  getServerVersion(): Observable<ServerApplicationVersion> {
    return this.get("/app_info");
  }

  public get title() {
    return "IP Evo";
  }
}
