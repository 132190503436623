import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
export class SidebarService {

    private _installationDevicesClicked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    onInstallationDevicesClick(): Observable<boolean> {
        return this._installationDevicesClicked$.asObservable();
    }

    installationDevicesClicked() {
        this._installationDevicesClicked$.next(true);
    }


    private _userLicensesClicked$: Subject<boolean> = new Subject<boolean>();

    onUserLicensesClick(): Observable<boolean> {
        return this._userLicensesClicked$.asObservable();
    }

    userLicensesClicked() {
        this._userLicensesClicked$.next(true);
    }


    private _createManagerInvitationClicked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    onCreateManagerInvitationClick(): Observable<boolean> {
        return this._createManagerInvitationClicked$.asObservable();
    }

    createManagerInvitationClicked() {
        this._createManagerInvitationClicked$.next(true);
    }


    private _installationDisassociateClicked$: Subject<boolean> = new Subject<boolean>();

    onInstallationDisassociateClick(): Observable<boolean> {
        return this._installationDisassociateClicked$.asObservable();
    }

    installationDisassociateClicked() {
        this._installationDisassociateClicked$.next(true);
    }

    private _profileSettingsClicked$: Subject<boolean> = new Subject<boolean>();

    onProfileSettingsClick(): Observable<boolean> {
        return this._profileSettingsClicked$.asObservable();
    }

    profileSettingsClicked() {
        this._profileSettingsClicked$.next(true);
    }

}