<div class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">

    <div class="row-with-label full-width">
        <dx-text-box class="full-width" [(value)]="emails" [label]="'General.Email' | i18next" labelMode="static" stylingMode="outlined"
            [placeholder]="'Invitations.EmailsPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
            (onValueChanged)="updateConfirmButtonState()">
        </dx-text-box>
        <span class="label"> {{'Invitations.InviteUserEmailMessage' | i18next}}</span>
    </div>
    <div class="row-with-label full-width">
        <dx-text-box class="full-width" [(value)]="notes" [label]="'General.Notes' | i18next" labelMode="static" stylingMode="outlined"
            [placeholder]="'Invitations.NotesPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
            (onValueChanged)="updateConfirmButtonState()">
        </dx-text-box>
        <span class="label"> {{'Invitations.InviteUserNotesMessage' | i18next}}</span>
    </div>
    <div class="subtitle">*{{'Invitations.InviteUserRequiredDevices' | i18next}}</div>
    <div id="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="createInvitation()"
            [disabled]="!thereAreChanges">
        </dx-button>
    </div>

</div>


<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Invitations.CreatingWaitMessage'"></app-spinner>
</div>