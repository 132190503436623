import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

export class LanguageDetectorService extends I18nextBrowserLanguageDetector {

  private defaultLanguage = "en";

  language: string | null = null;

  override detect(detectionOrder?: string[]): string | string[] {
    const detectOrder = this.i18nOptions.detection.order as string[];
    for (const detection of detectOrder) {
      switch (detection) {
        case 'path':
          this.language = this.getLanguageWithoutLocale(window.location.pathname.split('/')[1]);
          break;
        case 'localStorage':
          this.language = this.getLanguageWithoutLocale(localStorage.getItem('i18nextLng'));
          break;
        case 'navigator':
          this.language = this.getLanguageWithoutLocale(navigator.languages[0]);
          break;
      }

      if (this.language) {
        return this.language;
      }
    }

    return this.defaultLanguage;
  }

  private getLanguageWithoutLocale(fullLng: string | null): string {
    return (fullLng != null) ? fullLng?.split('-')[0] : "";
  }

}
