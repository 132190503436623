export interface License {
  id: string;
  created_on: number;
  activated_on: number;
  enabled_units: number;
  version: string;
  kind: LicenseKind;
}


export enum LicenseKind {
  UNITS = 1,
  APP_WEB = 2
}