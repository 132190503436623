import { Component, Input } from '@angular/core';
import { BreadcrumbItem } from '../../utils/breadcrumb-item';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent {

  @Input()
  items: BreadcrumbItem[] = [];
  
  @Input()
  showAreaPlaceholder: boolean = false;

}
