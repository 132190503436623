import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  LocalizerService,
  NavigationService,
  profileColorPalette,
  ScreenService,
} from "../../shared";
import { finalize, forkJoin, takeUntil } from "rxjs";

import { Installation } from "../../shared/models/installations/installation";
import { Unit } from "../../shared/models/units/unit";
import { ManagerInvitation } from "../../shared/models/invitations/manager-invitation";
import { User } from "../../shared/models/users/user";
import { FarfisaDevice } from "../../shared/models/devices/farfisa_device";

import { BasePageComponent } from "../../shared/components/base-page.component";
import { InstallationsManager } from "../services/installations-manager";
import { InvitationsManager } from "../../invitations/services/invitations-manager";
import { BreadcrumbItem } from "../../shared/utils/breadcrumb-item";
import { environment } from "../../../environments/environment";
import { MapMarker } from "../../shared/models/maps/map-marker";
import { SidebarService } from "../../application-layout/services/sidebar.service";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { EditInstallationDialogComponent } from "../edit-installation-dialog/edit-installation-dialog.component";
import { EditDeviceDialogComponent } from "../../devices/edit-device-dialog/edit-device-dialog.component";
import { ImportUnitsDialogComponent } from "../import-units-dialog/import-units-dialog.component";
import { EditLocationDialogComponent } from "../edit-location-dialog/edit-location-dialog.component";
import { CreateManagerInvitationDialogComponent } from "../../invitations/create-manager-invitation-dialog/create-manager-invitation-dialog.component";
import { CreateUnitDialogComponent } from "../../units/create-unit-detail/create-unit-dialog.component";
import { LicensesManagerService } from "../../licenses/licenses-manager.service";
import { InstallationLicensesDto } from "../../licenses/dto/installation-licenses-dto";
import { CreateLicenseDialogComponent } from "../../licenses/create-license-dialog/create-license-dialog.component";
import { UserOrInvitation } from "../../units/models/user-or-invitation";
import { EditUserInvitationDialogComponent } from "../../invitations/edit-user-invitation/edit-user-invitation-dialog.component";
import { InstallationUserDialogComponent } from "../installation-user-dialog/installation-user-dialog.component";
import { isInstallerAccess } from "../../shared/models/users/access-level";
import { InstallationUnitViewModel } from "../view-models/installation_unit_view_model";
import { Store } from "@ngrx/store";
import { InstallationActions } from "../../state/installation/installation.actions";

@Component({
  selector: "app-installation",
  templateUrl: "./installation.component.html",
  styleUrl: "./installation.component.scss",
})
export class InstallationComponent
  extends BasePageComponent
  implements OnInit, OnDestroy
{
  private _invitations: ManagerInvitation[] = [];
  private _users: User[] = [];

  @Input() installationId: string | null = null;

  installation?: Installation;
  installationUnits: InstallationUnitViewModel[] = [];

  installationDevices: FarfisaDevice[] = [];
  filteringDevices: FarfisaDevice[] = [];

  displayLicensesCard = false;
  installationLicenses: InstallationLicensesDto[] = [];

  installationUsers: UserOrInvitation[] = [];

  hasFullAccess = false; // if current user is an installer has full access

  // Utility variables
  hidePassword = true;

  breadcrumbItems: BreadcrumbItem[] = [
    { label: "General.Installations", url: "/installations" },
  ];

  searchButtonOptions = {
    icon: "search",
    stylingMode: "text",
  };

  googleMapsApiKey = environment.mapAPIKey;
  markers: MapMarker[] = [];

  constructor(
    private installationsManager: InstallationsManager,
    private invitationsManager: InvitationsManager,
    private navigation: NavigationService,
    private sidebar: SidebarService,
    private dialogs: DialogsService,
    private licenses: LicensesManagerService,
    private store: Store,
    localizer: LocalizerService,
    screen: ScreenService
  ) {
    super(screen, localizer);
  }

  ngOnInit() {
    this.retrieveInstallation();
    this.retrieveInstallationUsers();
    this.retrieveInstallationLicenses();
    this.listenSidebarEvents();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  disassociateInstallation() {
    const data = {
      installation: this.installation,
      askForDisassociation: true,
    };

    this.dialogs
      .showDialog(EditInstallationDialogComponent, data)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((response) => this.applyInstallationDetailsChanges(response));
  }

  navigateToUnitDetails(event: any) {
    const unit = event.data as Unit;
    this.navigation.navigateToUnitDetail(this.installation!.id, unit.id);
  }

  editInstallationDetails() {
    const configuration = {
      title: "Installations.CustomizeInstallation",
      icon: "assets/icons/ic_star.svg",
    };
    const data = {
      installation: this.installation,
      askForDisassociation: false,
    };

    this.dialogs
      .showDialog(EditInstallationDialogComponent, data, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((response) => this.applyInstallationDetailsChanges(response));
  }

  editInstallationLocation() {
    const configuration = {
      title: "Installations.EditLocationTitle",
      icon: "assets/icons/ic_map.svg",
    };

    this.dialogs
      .showDialog(EditLocationDialogComponent, this.installation, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.retrieveInstallation());
  }

  addLicense() {
    const configuration = {
      title: "Installations.AddLicense",
      icon: "assets/icons/ic_license.svg",
    };

    this.dialogs
      .showDialog(
        CreateLicenseDialogComponent,
        this.installation,
        configuration
      )
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.retrieveInstallationLicenses());
  }

  createUnit() {
    const configuration = {
      title: "Units.NewUnit",
      icon: "assets/icons/ic_home.svg",
    };

    this.dialogs
      .showDialog(CreateUnitDialogComponent, this.installation, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.retrieveInstallation());
  }

  createManagerInvitation() {
    const configuration = {
      title: "Invitations.InviteResponsible",
      icon: "assets/icons/ic_ticket.svg",
    };

    this.dialogs
      .showDialog(
        CreateManagerInvitationDialogComponent,
        this.installation,
        configuration
      )
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.retrieveInstallationUsers(true));
  }

  private applyInstallationDetailsChanges(changes: any) {
    if (this.installation) {
      this.installation = { ...this.installation, ...changes };
    }
  }

  editDeviceDetails(device: FarfisaDevice) {
    const configuration = {
      title: "Devices.DeviceSettings",
      icon: "assets/icons/ic_settings.svg",
    };

    const data = {
      device: device,
      installationId: this.installationId,
      canEditDevice: this.hasFullAccess,
    };

    this.dialogs
      .showDialog(EditDeviceDialogComponent, data, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((response) =>
        this.applyDeviceDetailsChanges(response, device.id)
      );
  }

  private applyDeviceDetailsChanges(changes: any, deviceId: string) {
    if (!this.installationDevices) {
      return;
    }

    const deviceToUpdate = this.installationDevices.find(
      (device) => device.id === deviceId
    );
    if (deviceToUpdate) {
      Object.keys(changes).forEach((key) => {
        if (key in deviceToUpdate) {
          (deviceToUpdate as any)[key] = changes[key];
        }
      });
    }
  }

  showUserOrInvitation(event: any) {
    const item = event.data as UserOrInvitation;
    if (item.type === "user") {
      const user = this._users.find((u) => u.sub === item.id);
      this.showUserDialog(user!);
    } else if (item.type === "invitation") {
      const invitation = this._invitations.find((i) => i.code === item.id);
      this.showInvitationDialog(invitation!);
    }
  }

  showImportUnitsPopup() {
    const configuration = {
      title: "ImportUnits.ImportFromFile",
      icon: "assets/icons/ic_import.svg",
    };

    this.dialogs
      .showDialog(ImportUnitsDialogComponent, this.installation, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe({
        next: () => this.retrieveInstallation(),
      });
  }

  aliasSortingMethod(a: string, b: string) {
    return Number.parseInt(a) - Number.parseInt(b);
  }

  // Private methods

  /**
   * This method loads the installation.
   */
  private retrieveInstallation(silently = false) {
    if (!this.installationId) {
      console.error("Installation ID is not set.");
      return;
    }

    if (!silently) {
      this.isBusy = true;
    }

    this.installationsManager
      .getInstallation(this.installationId)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (installation) => this.updateInstallationData(installation),
        error: () =>
          this.displayLocalizedErrorNotification(
            "Installations.ApiDetailError"
          ),
      });
  }

  private updateInstallationData(installation: Installation) {
    this.store.dispatch(
      InstallationActions.selectInstallation({ installation: installation })
    );
    this.installation = installation;
    this.installationDevices = installation?.devices || [];
    this.updateInstallationUnits();
    this.hasFullAccess = isInstallerAccess(installation?.permissions?.access);
    const address = installation?.address;
    this.markers = address
      ? [
          {
            location: address.latitude + "," + address.longitude,
            tooltip: installation.name,
          },
        ]
      : [];

    if (this.breadcrumbItems.length > 1) {
      this.breadcrumbItems.pop();
    }

    this.breadcrumbItems.push({ label: installation?.name || "-" });
  }

  private updateInstallationUnits() {
    if (!this.installation || !this.installation.units) {
      return;
    }

    const units = this.installation?.units || [];
    this.installationUnits = units
      .map((unit) => {
        const alias = unit.alias || "";
        const deviceNames = unit.devices_ids?.map((id) => {
          const device = this.installation!.devices?.find((d) => d.id == id);
          return device?.name || "";
        });

        const unitUsers = unit.users?.map(u => u.email).join(", ") || "";
        return {
          id: unit.id,
          name: unit.name || "",
          alias: Number.parseInt(alias) || 0,
          devices: deviceNames?.join(", ") || "",
          users: unitUsers
        } as InstallationUnitViewModel;
      })
      .sort((a, b) => a.alias - b.alias);
  }

  private retrieveInstallationLicenses() {
    if (!this.installationId) {
      return;
    }

    this.licenses.getInstallationLicenses(this.installationId).subscribe({
      next: (license) => (this.installationLicenses = [license]),
    });
  }

  /**
   * Retrieve installation managers and installers and invitations, all together.
   */
  private retrieveInstallationUsers(silently = false) {
    if (!silently) {
      this.isBusy = true;
    }

    forkJoin({
      managers: this.installationsManager.getInstallationUsers(
        this.installationId!
      ),
      invitations: this.invitationsManager.getManagerInvitations(
        this.installationId!
      ),
    })
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (data) =>
          this.populateInstallationUsers(data.managers, data.invitations),
        error: () =>
          this.displayLocalizedErrorNotification(
            "Installations.ApiManagerInvitationsError"
          ),
      });
  }

  private populateInstallationUsers(
    users: User[],
    invitations: ManagerInvitation[]
  ) {
    this._users = users;
    let items: UserOrInvitation[] = users.map((user) => {
      return {
        type: "user",
        id: user.sub,
        email: user.email,
        accessLevel: user.access_level,
      } as UserOrInvitation;
    });

    this._invitations = invitations;
    items = items.concat(
      invitations.map((invitation) => {
        return {
          type: "invitation",
          id: invitation.code,
          email: invitation.email,
          accessLevel: invitation.access,
        } as UserOrInvitation;
      })
    );

    items.forEach((item, index) => {
      item.color = profileColorPalette[index % profileColorPalette.length];
      item.textColor = "#000000";
    });

    this.installationUsers = items;
  }

  private listenSidebarEvents() {
    this.sidebar
      .onCreateManagerInvitationClick()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.createManagerInvitation());

    this.sidebar
      .onInstallationDisassociateClick()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.disassociateInstallation());

    this.sidebar
      .onUserLicensesClick()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((value) => {
        this.displayLicensesCard = value;
      });
  }

  private showInvitationDialog(invitation: ManagerInvitation) {
    const data = {
      invitation: invitation,
      installationId: this.installationId,
    };

    const configuration = {
      title: "Invitations.Detail",
      icon: "assets/icons/ic_invitation.svg",
    };

    this.dialogs.showDialog(
      EditUserInvitationDialogComponent,
      data,
      configuration
    );
  }

  private showUserDialog(user: User) {
    const configuration = {
      title: "General.UserManagement",
      icon: "assets/icons/ic_account.svg",
    };

    this.dialogs.showDialog(
      InstallationUserDialogComponent,
      user,
      configuration
    );
  }
}
