import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isSuccessImportState, UnitImportedViewModel } from '../view-models/unit_imported_view_model';

@Component({
  selector: 'app-import-result',
  templateUrl: './import-result.component.html',
  styleUrl: './import-result.component.scss'
})
export class ImportResultComponent implements OnChanges {

  @Input()
  resultItems: UnitImportedViewModel[] = [];

  @Input()
  errorMessage?: string;

  @Input()
  fileName: string = "";

  @Output()
  confirmButtonClicked = new EventEmitter<void>();

  icon: string = "";
  title: string = "";
  message: string = "";
  iconClass: string = "";

  errorItems: UnitImportedViewModel[] = [];

  totalRows: number = 0;
  successRows: number = 0;

  ngOnChanges(_: SimpleChanges): void {
    if (this.resultItems?.length <= 0 && !this.errorMessage) {
      return;
    }

    this.totalRows = this.resultItems.length;
    if (this.errorMessage) {
      this.icon = 'assets/icons/ic_warning.svg';
      this.iconClass = 'error';
      this.title = 'ImportUnits.Error';
      this.message = this.errorMessage;
      return;
    }

    this.errorItems = this.resultItems.filter(i => !isSuccessImportState(i.state));
    this.successRows = this.resultItems.length - this.errorItems.length;
    if (!this.errorItems || this.errorItems.length <= 0) {
      this.icon = 'assets/icons/ic_success.svg';
      this.iconClass = 'success';
      this.title = 'ImportUnits.Success';
      this.message = 'ImportUnits.SuccessfullyImportedRows';
    } else {
      this.icon = 'assets/icons/ic_warning.svg';
      this.iconClass = 'warning';
      this.title = 'ImportUnits.PartialSuccess';
      this.message = 'ImportUnits.PartiallyImportedRows';
    }
  }

}
