import { Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DialogsService } from '../services/dialogs.service';
import { BaseDialogContent } from '../base-dialog-component';
import { Subscription } from 'rxjs';
import { ShowDialogEvent } from '../models/show-dialog-event';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss'
})
export class DialogComponent implements OnInit, OnDestroy {

  private _dialogEventsSubscription: Subscription | undefined;

  @ViewChild('container', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;

  visible = false;

  title: string | undefined;
  icon: string | undefined;
  titleHidden: boolean = false;

  constructor(private dialogs: DialogsService) { }

  ngOnInit() {
    this._dialogEventsSubscription = this.dialogs.showDialogEvents().subscribe(event => this.handleShowDialogEvent(event));
  }

  ngOnDestroy(): void {
    this._dialogEventsSubscription?.unsubscribe();
  }

  private handleShowDialogEvent(event: ShowDialogEvent) {
    this.visible = event?.component && event?.data;
    if (!this.visible) {
      return;
    }

    this.container.clear();
    const componentRef: ComponentRef<BaseDialogContent> = this.container.createComponent(event.component);
    componentRef.instance.passData(event.data);
    componentRef.instance.hideTitleRequested.subscribe(hide => this.titleHidden = hide);
    this.title = event.configuration?.title ?? "";
    this.icon = event.configuration?.icon ?? "";
    this.titleHidden = this.title == "";
  }

}
