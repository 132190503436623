<div class="container">

    <div *ngIf="isBusy" class="loading">
        <dx-load-indicator></dx-load-indicator>
        <div>{{'General.RetrievingData' | i18next}}</div>
    </div>

    <div class="content" *ngIf="!isBusy">

        <div class="content-title">
            {{'General.Preferences' | i18next}}
        </div>

        <div class="title">{{'Preferences.Sounds' | i18next}}</div>

        <div class="box bottom-space">
            <div class="box-title">{{'Preferences.TonesVolume' | i18next}}</div>
            <dx-slider id="slider1" [min]="1" [max]="10"
                [value]="this.intercomStatus!.device.preferences!.audio!.sounds!.tones_volume!" [step]="1"
                (onValueChanged)="onValueChanged($event)">
                <dxo-tooltip [enabled]="true"></dxo-tooltip>
                <dxo-label [visible]="true"></dxo-label>
            </dx-slider>
        </div>

        <div class="box bottom-space">
            <div class="box-title">{{'Preferences.RingtoneVolume' | i18next}}</div>
            <dx-slider id="slider2" [min]="1" [max]="10" (onValueChanged)="onValueChanged($event)"
                [value]="this.intercomStatus!.device.preferences!.audio!.sounds!.ringtones_volume!" [step]="1">
                <dxo-tooltip [enabled]="true"></dxo-tooltip>
                <dxo-label [visible]="true"></dxo-label>
            </dx-slider>
        </div>

        <div class="title">{{'Preferences.Ringtones' | i18next}}</div>
        <dx-button id="button1" type="default" class="button animated bottom-space"
            (onClick)="showRingtonesPopup($event)">
            <div *dxTemplate="let data of 'content'" style="display: flex;">
                <div class="button-box">
                    <i class="dx-icon-music"></i>
                    <div class="button-container">
                        <div class="text-button-container">
                            <span class="text-button">{{'Preferences.RingtoneCall' | i18next}}</span>
                            <span class="sub-text-button">
                                {{'Ringtones.' +
                                this.newIntercomStatus!.device.preferences?.audio?.sounds?.call_ringtone | i18next}}
                            </span>
                        </div>
                        <i class="dx-icon-chevronright"></i>
                    </div>
                </div>
            </div>
        </dx-button>

        <dx-button id="button2" type="default" class="button animated bottom-space"
            (onClick)="showRingtonesPopup($event)">
            <div *dxTemplate="let data of 'content'" style="display: flex;">
                <div class="button-box">
                    <i class="dx-icon-music"></i>
                    <div class="button-container">
                        <div class="text-button-container">
                            <span class="text-button">{{'Preferences.FloorCallRingtone' | i18next}}</span>
                            <span class="sub-text-button">
                                {{'Ringtones.' +
                                this.newIntercomStatus!.device.preferences?.audio?.sounds?.floor_call_ringtone |
                                i18next}}
                            </span>
                        </div>
                        <i class="dx-icon-chevronright"></i>
                    </div>
                </div>
            </div>
        </dx-button>

        <div class="title top-space">{{'Preferences.Call' | i18next}}</div>

        <div class="box">
            <div class="box-title">{{'Preferences.Volume' | i18next}}</div>
            <dx-slider id="slider3" [min]="1" [max]="10"
                [value]="this.intercomStatus!.device.preferences!.audio!.calls!.volume!" [step]="1"
                (onValueChanged)="onValueChanged($event)">
                <dxo-tooltip [enabled]="true"></dxo-tooltip>
                <dxo-label [visible]="true"></dxo-label>
            </dx-slider>
        </div>

        <div *ngIf="intercomStatus!.status === 'offline'" class="status">{{'Devices.IntercomOffline' | i18next}}</div>

        <dx-button type="default" class="save-button animated" (onClick)="updatePreferencesSettings()"
            [disabled]="isUpdatingIntercom || intercomStatus!.status === 'offline' || !saveDetailsButtonDisabled">
            <div *dxTemplate="let data of 'content'" style="display: flex;">
                <dx-load-indicator class="button-indicator" height="24" width="24" [visible]="isUpdatingIntercom">
                </dx-load-indicator>
                <span class="dx-button-text">{{'General.Save' | i18next}}</span>
            </div>
        </dx-button>

    </div>

</div>


<dx-popup *ngIf="isRingtonesPopupVisible" [width]="500" [height]="580" [showTitle]="false" [dragEnabled]="false"
    container=".dx-viewport" [(visible)]="isRingtonesPopupVisible" [hideOnOutsideClick]="true"
    (onHiding)="closePopup()">

    <div class="popup-content">

        <div class="popup-title">{{"Preferences.SelectRingtone" | i18next}}</div>

        <dx-tree-list id="ringtonesList" [dataSource]="ringtones" keyExpr="code" [showColumnHeaders]="false"
            [hoverStateEnabled]="true" [selectedRowKeys]="[selectedRingtone?.name]"
            (onSelectionChanged)="changeRingtone($event)">
            <dxi-column dataField="name"></dxi-column>
            <dxo-selection mode="single"></dxo-selection>
        </dx-tree-list>

    </div>

</dx-popup>