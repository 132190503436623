<div [@openClose]="isLoading ? 'closed' : 'open'" *ngIf="!isBusy && newDeviceStatus">
    <div *ngIf="!isLoading" class="dialog-vertial-layout">

        <div class="header">
            <svg-icon src="assets/icons/ic_preferences.svg" id="header-icon"></svg-icon>
            <span id="header-title">{{'General.Preferences' | i18next}}</span>
        </div>

        <div class="dialog-vertial-layout scrollable-content full-width">

            <span class="row-title">{{'Preferences.Sounds' | i18next}}</span>
            <div class="full-width" *ngIf="newDeviceStatus.device?.preferences?.audio?.sounds?.tones_volume != null">
                <span class="title" style="margin-left: 180px;">{{'Preferences.TonesVolume' | i18next}}</span>
                <div class="header">
                    <svg-icon src="assets/icons/ic_lowsound.svg" id="header-icon"></svg-icon>
                    <dx-slider class="slider full-width" [min]="1" [max]="10" [step]="1"
                        (onValueChanged)="onValueChanged()"
                        [(value)]="newDeviceStatus.device.preferences!.audio!.sounds!.tones_volume!">
                        <dxo-tooltip [enabled]="true"></dxo-tooltip>
                        <dxo-label [visible]="true"></dxo-label>
                    </dx-slider>
                    <svg-icon src="assets/icons/ic_highsound.svg" id="header-icon"></svg-icon>
                </div>
            </div>

            <div class="full-width" *ngIf="newDeviceStatus.device?.preferences?.audio?.sounds?.voices_volume != null">
                <span class="title" style="margin-left: 180px;">{{'Preferences.VoiceVolume' | i18next}}</span>
                <div class="header">
                    <svg-icon src="assets/icons/ic_lowsound.svg" id="header-icon"></svg-icon>
                    <dx-slider class="slider full-width" [min]="1" [max]="10" [step]="1"
                        (onValueChanged)="onValueChanged()"
                        [(value)]="newDeviceStatus.device.preferences!.audio!.sounds!.voices_volume!">
                        <dxo-tooltip [enabled]="true"></dxo-tooltip>
                        <dxo-label [visible]="true"></dxo-label>
                    </dx-slider>
                    <svg-icon src="assets/icons/ic_highsound.svg" id="header-icon"></svg-icon>
                </div>
            </div>

            <div class="full-width" *ngIf="newDeviceStatus.device.preferences?.audio?.sounds?.mode != null">
                <div class="horizontal-flex-row full-width">
                    <div class="header">
                        <svg-icon src="assets/icons/ic_mode.svg" id="header-icon"></svg-icon>
                        <span class="title">{{'Preferences.Mode' | i18next}}</span>
                    </div>
                    <dx-select-box [(value)]="newDeviceStatus.device.preferences!.audio!.sounds!.mode!" [items]="mode"
                        displayExpr="value" valueExpr="code" stylingMode="underlined"
                        (onSelectionChanged)="onValueChanged()">
                    </dx-select-box>
                </div>
            </div>

            <span class="row-title">{{'Preferences.Call' | i18next}}</span>
            <div class="full-width" *ngIf="newDeviceStatus.device.preferences?.audio?.calls?.volume != null">
                <span class="title" style="margin-left: 180px;">{{'Preferences.Volume' | i18next}}</span>
                <div class="header">
                    <svg-icon src="assets/icons/ic_lowsound.svg" id="header-icon"></svg-icon>
                    <dx-slider class="slider full-width" [min]="1" [max]="10" [step]="1"
                        (onValueChanged)="onValueChanged()"
                        [(value)]="newDeviceStatus.device.preferences!.audio!.calls!.volume!">
                        <dxo-tooltip [enabled]="true"></dxo-tooltip>
                        <dxo-label [visible]="true"></dxo-label>
                    </dx-slider>
                    <svg-icon src="assets/icons/ic_highsound.svg" id="header-icon"></svg-icon>
                </div>
            </div>

            <div class="full-width" *ngIf="newDeviceStatus.device.preferences?.video?.balance?.brightness != null">
                <span class="title" style="margin-left: 180px;">{{'Preferences.Brightness' | i18next}}</span>
                <div class="header">
                    <svg-icon src="assets/icons/ic_lowbrightness.svg" id="header-icon"></svg-icon>
                    <dx-slider class="slider full-width" [min]="-10" [max]="10" [step]="1"
                        (onValueChanged)="onValueChanged()"
                        [(value)]="newDeviceStatus.device.preferences!.video!.balance!.brightness!">
                        <dxo-tooltip [enabled]="true"></dxo-tooltip>
                        <dxo-label [visible]="true"></dxo-label>
                    </dx-slider>
                    <svg-icon src="assets/icons/ic_highbrightness.svg" id="header-icon"></svg-icon>
                </div>
            </div>

            <div class="full-width" *ngIf="newDeviceStatus.device.preferences?.video?.balance?.contrast != null">
                <span class="title" style="margin-left: 180px;">{{'Preferences.Contrast' | i18next}}</span>
                <div class="header">
                    <svg-icon src="assets/icons/ic_lowcontrast.svg" id="header-icon"></svg-icon>
                    <dx-slider class="slider full-width" [min]="-10" [max]="10" [step]="1"
                        (onValueChanged)="onValueChanged()"
                        [(value)]="newDeviceStatus.device.preferences!.video!.balance!.contrast!">
                        <dxo-tooltip [enabled]="true"></dxo-tooltip>
                        <dxo-label [visible]="true"></dxo-label>
                    </dx-slider>
                    <svg-icon src="assets/icons/ic_highcontrast.svg" id="header-icon"></svg-icon>
                </div>
            </div>

            <div class="full-width" *ngIf="newDeviceStatus.device.preferences?.audio?.sounds?.mode != null">
                <div class="horizontal-flex-row full-width">
                    <div class="header">
                        <svg-icon src="assets/icons/ic_resolution.svg" id="header-icon"></svg-icon>
                        <span class="title">{{'Preferences.Resolution' | i18next}}</span>
                    </div>
                    <dx-select-box [(value)]="newDeviceStatus.device.preferences!.video!.stream!.quality"
                        [items]="resolution" displayExpr="value" valueExpr="code" stylingMode="underlined"
                        (onSelectionChanged)="onValueChanged()" dropDownPosition="top">
                    </dx-select-box>
                </div>
            </div>

            <span class="row-title">{{'Preferences.Screen' | i18next}}</span>
            <div style="width: 97%;"
                *ngIf="newDeviceStatus.device?.preferences?.display?.behaviors?.call_alias_auto_select != null">
                <div class="horizontal-flex-row full-width">
                    <div class="header">
                        <svg-icon src="assets/icons/ic_selection.svg" id="header-icon"></svg-icon>
                        <span class="title">{{'Preferences.CallAutoSelect' | i18next}}</span>
                    </div>
                    <dx-switch (onValueChanged)="onValueChanged()"
                        [(value)]="newDeviceStatus.device.preferences!.display!.behaviors!.call_alias_auto_select!"></dx-switch>
                </div>
            </div>

            <div class="full-width" *ngIf="newDeviceStatus.device.preferences?.display?.contrast != null">
                <span class="title" style="margin-left: 180px;">{{'Preferences.Contrast' | i18next}}</span>
                <div class="header">
                    <svg-icon src="assets/icons/ic_lowcontrast.svg" id="header-icon"></svg-icon>
                    <dx-slider class="slider full-width" [min]="-10" [max]="10" [step]="1"
                        (onValueChanged)="onValueChanged()"
                        [(value)]="newDeviceStatus.device.preferences!.display!.contrast!">
                        <dxo-tooltip [enabled]="true"></dxo-tooltip>
                        <dxo-label [visible]="true"></dxo-label>
                    </dx-slider>
                    <svg-icon src="assets/icons/ic_highcontrast.svg" id="header-icon"></svg-icon>
                </div>
            </div>
        </div>

        @if(newDeviceStatus.status === 'offline') {
        <div class="row-with-label">
            <span class="label"> {{'Devices.DevicesOffline' | i18next}}</span>
        </div>
        }

        <div class="dialog-action-bar">
            <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back()">
            </dx-button>
            <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="updatePreferencesSettings()"
                [disabled]="newDeviceStatus.status === 'offline' || !saveDetailsButtonDisabled">
            </dx-button>
        </div>

    </div>
</div>

<ng-lottie *ngIf="isBusy" [options]="options"></ng-lottie>

<div *ngIf="isLoading" [@openClose]="'open'" class="dialog-spinner-container">
    <app-spinner [message]="'Outputs.OutputConfigureWaitMessage'"></app-spinner>
</div>