import { BaseDialogContent } from "../dialogs/base-dialog-component";
import { FarfisaDevice } from "../shared/models/devices/farfisa_device";
import { Installation } from "../shared/models/installations/installation";
import { DeviceTreeItem } from "./models/device-tree-item";

export class BaseEditUnitDialogComponent extends BaseDialogContent {

  installationDevices: DeviceTreeItem[] = [];

  initialUnitDevicesAndOutputs: string[] = []; // current unit enabled permission before changes
  unitDevicesAndOutputs: string[] = []; // current unit enabled permission

  unitName: string = '';
  unitAlias: string = '';
  unitLocation: string = '';
  unitNotes: string = '';

  permissionSelectionChanged(event: any) {
    for (const key of event.currentSelectedRowKeys) {
      const device = this.installationDevices.find((d) => d.id == key);
      if (device && (!this.initialUnitDevicesAndOutputs.includes(key) || event.currentSelectedRowKeys.length == 1)) {
        // If the selected item is a device, we automatically select all its children
        for (const output of device.children || []) {
          if (!this.unitDevicesAndOutputs.includes(output.id)) {
            this.unitDevicesAndOutputs.push(output.id);
          }
        }
      } else {
        const parentDevice = this.installationDevices.find((d) => d.children?.some((c) => c.id == key));
        if (parentDevice && !this.unitDevicesAndOutputs.includes(parentDevice.id)) {
          // If the selected item is an output, we automatically select the parent device
          this.unitDevicesAndOutputs.push(parentDevice.id);
        }
      }
    }

    for (const key of event.currentDeselectedRowKeys) {
      const device = this.installationDevices.find((d) => d.id == key);
      if (device) {
        device.children?.forEach((output) => {
          const index = this.unitDevicesAndOutputs.indexOf(output.id);
          if (index >= 0) {
            this.unitDevicesAndOutputs.splice(index, 1);
          }
        });
      }
    }

    this.unitDevicesAndOutputs = [...this.unitDevicesAndOutputs];
    this.updateConfirmButtonState();
  }

  protected populateInstallationDevices(installation: Installation) {
    if (!installation) {
      return;
    }

    for (const device of installation.devices || []) {
      const children = device.available_outputs?.map((output) => ({
        id: output.id ?? "",
        name: output.name ?? "",
      })) || undefined;

      this.installationDevices.push({
        id: device.id,
        name: device.name,
        children: children,
      });
    }
  }

  protected buildEditUnitDevicesDto(installationDevices: FarfisaDevice[]): any[] {
    const devices = [];
    for (const selectedItem of this.unitDevicesAndOutputs) {
      const device = installationDevices.find((d) => d.id == selectedItem);
      if (!device) {
        continue;
      }

      const devicePermissions = {
        id: device.id,
        outputs: [] as string[],
      };

      device.available_outputs?.forEach((output) => {
        if (output?.id && this.unitDevicesAndOutputs.includes(output.id)) {
          devicePermissions.outputs.push(output.address);
        }
      });

      devices.push(devicePermissions);
    }

    return devices;
  }

  protected updateConfirmButtonState() { }

  protected handleAPIResponse(success: boolean, message: string, data?: any) {
    if (success) {
      this.dialogs.hideDialog();
      this.dialogs.displaySuccessNotification(message);
      this.dialogs.sendDialogResult(data);
    } else {
      this.dialogs.displayErrorNotification(message);
    }
  }

  protected isValidUnitAlias(): boolean {
    return this.unitAlias.trim() != '' && /^\d+$/.test(this.unitAlias);
  }

}