import { Injectable } from "@angular/core";
import { AccountManager } from "./account-manager";
import { Observable } from "rxjs";

@Injectable()
export class ApiAccountManager extends AccountManager {

    constructor() {
        super();
    }

    deleteAccount(): Observable<boolean> {
        return this.delete(`/account`);
    }
}

