import { Component, ElementRef, ViewChild } from '@angular/core';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { InstallationsManager } from '../services/installations-manager';
import { BaseInstallationDialog } from '../base-installation-dialog.component';
import { CreateInstallationDto } from '../dto/create-installation-dto';
import { finalize, switchMap } from 'rxjs';
import { Installation } from '../../shared/models/installations/installation';
import { ImageUploadService } from '../../shared/services/image-upload.service';

@Component({
  selector: 'app-create-installation-dialog',
  templateUrl: './create-installation-dialog.component.html',
  styleUrl: './create-installation-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class CreateInstallationDialogComponent extends BaseInstallationDialog {

  @ViewChild('fileInput') fileInput!: ElementRef;

  confirmButtonEnabled: boolean = false;
  createdInstallation?: Installation;


  constructor(dialogs: DialogsService, installations: InstallationsManager,
    private imageUploader: ImageUploadService) {
    super(dialogs, installations);
  }

  openFinderDialog() { this.fileInput.nativeElement.click(); }

  createInstallation() {
    if (!this.confirmButtonEnabled) {
      return;
    }

    const dto = this.buildCreateInstallationDTO();
    this.isBusy = true;
    this.installations.createInstallation(dto).subscribe({
      next: newInstallation => this.installationCreated(newInstallation),
      error: _ => this.completeError()
    });
  }

  override updateConfirmButtonState(): void {
    this.confirmButtonEnabled = this.installationName?.trim() != '' &&
      this.isValidPasscode(this.installationPasscode) &&
      this.installations.supportedLanguages().find(l => l.code == this.selectedLanguage) != null;
  }

  private installationCreated(installation: Installation) {
    this.createdInstallation = installation;
    if (!this.selectedImage) {
      this.completeSuccess();
      return;
    }

    this.installations.getInstallationImageUploadUrl(installation.id).pipe(
      switchMap(response => this.imageUploader.uploadFile(response, this.selectedImage!)),
      finalize(() => this.isBusy = false)
    ).subscribe({
      next: _ => this.completeSuccess(),
      error: _ => this.completeError()
    });
  }

  private completeSuccess() {
    this.dialogs.displaySuccessNotification("Installations.InstallationCreated");
    this.dialogs.sendDialogResult(true);
    this.dialogs.hideDialog();
  }

  private completeError() {
    this.dialogs.displayErrorNotification("Installations.InstallationCreated");
    this.dialogs.hideDialog();
  }

  private buildCreateInstallationDTO(): CreateInstallationDto {
    return {
      name: this.installationName,
      language: this.selectedLanguage,
      passcode: this.installationPasscode
    };
  }

}

