import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrl: './label.component.scss'
})
export class LabelComponent {

  @Input() text: string = '';

  @Input() color: string = '#F8BFE3';

  @Input() textColor: string = '#000000';

}
