import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { InstallationLicensesDto } from "../dto/installation-licenses-dto";
import { License } from "../models/license";
import { Installation } from "../../shared/models/installations/installation";
import { LicenseViewModel } from "../view-models/license-view-model";

@Component({
  selector: "app-licenses-list",
  templateUrl: "./licenses-list.component.html",
  styleUrl: "./licenses-list.component.scss",
})
export class LicensesListComponent implements OnChanges {
  
  @Input()
  installationLicenses: InstallationLicensesDto[] = [];

  @Input()
  installations: Installation[] = [];

  licenses: LicenseViewModel[] = [];

  showInstallationColumn: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.installationLicenses || this.installationLicenses.length === 0) {
      return;
    }

    let items: LicenseViewModel[] = [];
    for (const license of this.installationLicenses) {
      const installation = this.installations.find((i) => i.id === license.installation?.id);
      const newLicenses = license.licenses.map((l) => {
        return {
          id: l.id,
          createdOn: l.created_on,
          activatedOn: l.activated_on,
          enabledUnits: l.enabled_units,
          installation: installation?.name || undefined,
          version: l.version,
          kind: l.kind
        } as LicenseViewModel;
      });

      items = items.concat(newLicenses);
    }

    this.licenses = items;
    this.showInstallationColumn = this.licenses.some((l) => l.installation != null);
  }
}
