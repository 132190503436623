import { BaseDialogContent } from "../dialogs/base-dialog-component";
import { DialogsService } from "../dialogs/services/dialogs.service";
import { InstallationsManager } from "./services/installations-manager";


export class BaseInstallationDialog extends BaseDialogContent {

  imageContent: string | ArrayBuffer = 'assets/illustrations/installation_placeholder.svg';
  installationName: string = '';
  installationPasscode: string = '';

  passcodeFieldMode: "password" | "text" = "password";
  passcodeButtonOptions = {
    icon: 'eyeopen',
    stylingMode: 'text',
    onClick: () => this.passcodeFieldMode = this.passcodeFieldMode === "password" ? "text" : "password"
  };

  selectedLanguage: string = '';
  languages = this.installations.supportedLanguages();

  selectedImage: File | undefined;

  constructor(dialogs: DialogsService, protected installations: InstallationsManager) {
    super(dialogs);
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedImage = file;
      const reader = new FileReader();
      reader.onload = e => this.imageContent = reader.result!;
      reader.readAsDataURL(file);
      this.updateConfirmButtonState();
    }
  }

  updateConfirmButtonState() { }

  isValidPasscode(passcode: string): boolean {
    return passcode.length >= 2 && passcode.length <= 8 && /^\d+$/.test(passcode);
  }

}