import { Pipe, PipeTransform } from "@angular/core";
import { FarfisaDevice } from "../models/devices/farfisa_device";
import { UnitDevice } from "../../units/models/unit-device";
import { DeviceOutput } from "../../devices/models/device_output";

@Pipe({ name: "farfisaDeviceToOutputsString" })
export class FarfisaDeviceToOutputsStringPipe implements PipeTransform {
  transform(device: FarfisaDevice): string {
    return device.available_outputs?.map((it) => it.name).join(", ") || "-";
  }
}

@Pipe({ name: "farfisaDevicesToString" })
export class FarfisaDevicesToStringPipe implements PipeTransform {

  transform(devices: FarfisaDevice[]): string {
    return devices
      .map((it) => `${it.name} [${this.outputsToString(it.available_outputs)}]`)
      .join(", ");
  }

  outputsToString(outputs: DeviceOutput[] | undefined) {
    return outputs?.map((it) => it.name).join(", ");
  }
}

@Pipe({ name: "unitDevicesToString" })
export class UnitDevicesToStringPipe implements PipeTransform {
  
  transform(devices: UnitDevice[], farfisaDevices: FarfisaDevice[] | null = null): string {
    return devices.map((it) => {
      if (farfisaDevices) {
        const outputs = this.unitDeviceOutputsToString(it, farfisaDevices).trim();
        const outputsString = outputs ? ` [${outputs}]` : "";
        return `${it.name}${outputsString}`;
      } else {
        return it.name;
      }
    }).join(", ");
  }

  unitDeviceOutputsToString(unitDevice: UnitDevice, farfisaDevices: FarfisaDevice[] | undefined) {
    const device = farfisaDevices?.find((it) => it.id == unitDevice.device_id);
    let outputStrings: string[] = [];
    unitDevice.outputs?.forEach((output) => {
      const availableOutput = device?.available_outputs?.find(available_output => available_output.address == output);
      outputStrings.push(availableOutput?.name || "-");
    });

    return outputStrings.join(", ");
  }
}

