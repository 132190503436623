import { Injectable } from '@angular/core';
import { Observable, debounceTime, from, fromEvent, map, switchMap, tap } from 'rxjs';

@Injectable()
export class ImageUploadService {

  constructor() { }

  uploadFile(response: any, file: File): Observable<any> {
    const formData = new FormData();
    Object.keys(response.fields).forEach(key => {
      formData.append(key, response.fields[key]);
    });

    return from(this.compressImage(file)).pipe(
      tap(compressedFile => formData.append('file', compressedFile)),
      switchMap(_ => from(fetch(response.url, {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      })))
    );
  }

  private compressImage(file: File): Promise<File> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0);

          canvas.toBlob(blob => {
            if (blob) {
              resolve(new File([blob], file.name, { type: 'image/jpeg', lastModified: Date.now() }));
            }
          }, 'image/jpeg', 0.7);
        };
      };

      reader.readAsDataURL(file);
    });
  }

}
