<div id="sidebar-header" class="with-image" [style.background-image]="'url(' + unit?.image_url + ')'">
    <div class="overlay">
        <div class="overlay-title">
            {{unit?.name}}
        </div>
        <div class="vspace">
            {{installation?.name}}
        </div>
        <div>
            {{installation?.address?.address}}
        </div>
    </div>
</div>

<div class="sidebar-section">

    <div class="sidebar-section-title vspace">Unit</div>

    <div class="sidebar-item">
        <svg-icon src="assets/icons/ic_device.svg" class="sidebar-item-icon"></svg-icon>
        <div class="sidebar-item-text">{{"Sidebar.Devices" | i18next}}</div>
    </div>

</div>

<div class="sidebar-section">

    <div class="sidebar-section-title vspace">{{"Sidebar.Users" | i18next}}</div>

    <div class="label-container vspace">
        @for (item of users; track item; let  i = $index) {    
            <app-label [text]="item.email | noDomainEmail" [color]="i | labelBackground"></app-label>
        }
    </div>

    <div class="sidebar-item">
        <svg-icon src="assets/icons/ic_add_action.svg" class="sidebar-item-icon"></svg-icon>
        <div class="sidebar-item-text sidebar-item-text-action">{{"Sidebar.AddUser" | i18next}}</div>
    </div>

</div>