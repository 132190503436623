import { Pipe, PipeTransform } from "@angular/core";
import { UserOrInvitation } from "../units/models/user-or-invitation";
import { AccessLevelTitlePipe } from "../shared/pipes/access-level-title.pipe";

@Pipe({ name: "userInvitationAccess" })
export class UserInvitationAccessPipeTsPipe implements PipeTransform {
  constructor(private userAccessPipe: AccessLevelTitlePipe) {}

  transform(value: UserOrInvitation): string {
    if (value.type === "invitation") {
      return "Invitations.InvitationPending";
    } else {
      return this.userAccessPipe.transform(value.accessLevel);
    }
  }
}
