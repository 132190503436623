import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DevicesManager } from '../services/devices-manager';
import { finalize } from 'rxjs';
import { PasscodeACAction } from '../models/ac_action/passcode_ac_action';
import { DeviceOutput } from '../models/device_output';
import { OutputAction } from '../models/ac_action/base_ac_action';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { AnimationOptions } from 'ngx-lottie';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';

@Component({
  selector: 'app-device-passcodes-settings',
  templateUrl: './device-passcodes-settings.component.html',
  styleUrl: './device-passcodes-settings.component.scss',
  animations: [openCloseAnimation]
})
export class DevicePasscodesSettingsComponent implements OnInit {

  @Input() installationId: string | null = null;
  @Input() deviceId: string | null = null;
  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();

  passcodes: PasscodeACAction[] = [];
  selectedPasscode: PasscodeACAction | null = null;
  deviceOutputs: DeviceOutput[] = [];
  selectedOutputs: OutputAction[] = [];
  code: string = '';

  // Utility variables
  configureDeviceSettings: boolean = false;
  isBusy: boolean = false;
  isLoading: boolean = false;

  options: AnimationOptions = {
    path: '/assets/animations/loading.json',
    loop: true,
    autoplay: true
  };

  codeFieldMode: "password" | "text" = "password";
  codeButtonOptions = {
    icon: 'eyeopen',
    stylingMode: 'text',
    onClick: () => {
      this.codeFieldMode = this.codeFieldMode === "password" ? "text" : "password";
    },
  };

  constructor(private devices: DevicesManager, private dialogs: DialogsService) { }

  ngOnInit() {
    if (!this.installationId) {
      this.dialogs.displayErrorNotification('Installations.InvalidInstallationId');
      return;
    }

    if (!this.deviceId) {
      this.dialogs.displayErrorNotification('Devices.InvalidDeviceId');
      return;
    }

    this.loadDevicePasscodes();
  }

  /**
   * Saves a new device passcode or updates an existing one.
   */
  saveDevicePasscode() {
    this.isLoading = true;
    this.devices.addDevicePasscode(this.installationId!, this.deviceId!, this.code, this.selectedOutputs)
      .subscribe({
        next: updatedPasscodes => {
          this.passcodes = updatedPasscodes;
          this.reset();
        },
        error: _ => {
          this.isLoading = false;
          this.dialogs.displayErrorNotification('Passcodes.ApiDevicePasscodeError');
        }
      });
  }

  deleteDevicePasscode() {
    if (!this.selectedPasscode || !this.code) {
      return;
    }

    this.isLoading = true;
    this.devices.deletePasscode(this.installationId!, this.deviceId!, this.code)
      .subscribe({
        next: _ => this.reset(),
        error: _ => {
          this.isLoading = false;
          this.dialogs.displayErrorNotification('Passcodes.ApiDeletePasscodeError');
        }
      });
  }

  /**
   * Updates the list of selected outputs when the selection changes.
   * @param event The event containing the selected row keys. 
   */
  onSelectionChanged(event: { selectedRowKeys: string[] }): void {
    this.selectedOutputs = event.selectedRowKeys.map(address => {
      const action = this.selectedOutputs.find(output => output.address === address)?.action || 'toggle';
      return { address, action };
    });
  }

  // #region Utility methods

  /**
   * Toggles the configure device settings view.
   */
  toggleConfigureDeviceSettings() {
    this.configureDeviceSettings = !this.configureDeviceSettings;
  }

  /**
   * Handles the back navigation logic, resetting the view to the initial state
   * if in configuration mode, otherwise emits the back event.
   */
  back() {
    if (this.configureDeviceSettings) {
      this.selectedOutputs = [];
      this.code = '';
      this.toggleConfigureDeviceSettings();
      return;
    }
    this.backEvent.emit();
  }

  configurePasscode(event: any) {
    this.getDeviceOutputs();
    this.selectedPasscode = event.itemData || null;
    if (this.selectedPasscode) {
      this.selectedOutputs = [...this.selectedPasscode.action.outputs];
      this.code = this.selectedPasscode.code;
    }

    this.codeFieldMode = this.selectedPasscode ? "text" : "password";
    this.toggleConfigureDeviceSettings();
  }

  /**
   * This method is called to get the selected row keys.
   * @returns The selected row keys.
   */
  getSelectedRowKeys(): string[] {
    return this.selectedOutputs.map(output => output.address);
  }

  /**
   * Validates the code input to ensure it meets length and format requirements.
   * @returns True if the code is valid, false otherwise.
   */
  isCodeValid(): boolean {
    return this.code.length >= 2 && this.code.length <= 8 && /^\d*$/.test(this.code);
  }

  // #endregion

  // #region Private methods

  /**
   * Loads the device passcodes for the current device.
   */
  private loadDevicePasscodes() {
    this.isBusy = true;

    this.devices.getDevicePasscodes(this.installationId!, this.deviceId!)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.isBusy = false;
      }))
      .subscribe({
        next: passcodes => this.passcodes = passcodes,
        error: _ => this.dialogs.displayErrorNotification('Devices.ApiGetDevicePasscodesError')
      });
  }

  /**
   * Fetches the device outputs for the current device.
   */
  private getDeviceOutputs() {
    this.isBusy = true;

    this.devices.getDeviceOutputs(this.installationId!, this.deviceId!)
      .pipe(finalize(() => this.isBusy = false))
      .subscribe({
        next: outputs => this.deviceOutputs = outputs.filter(output => output.id),
        error: _ => this.dialogs.displayErrorNotification('Devices.ApiGetDeviceOutputsError')
      });
  }

  /**
   * Resets the form, clears selections, and reloads the passcodes.
   */
  private reset() {
    this.back();
    this.loadDevicePasscodes();
  }
}
