import { Component, Input, SimpleChanges } from '@angular/core';
import { Unit } from '../../shared/models/units/unit';
import { User } from '../../shared/models/users/user';
import { UnitsManager } from '../../units/services/units-manager';
import { InstallationsManager } from '../../installations/services/installations-manager';
import { Installation } from '../../shared/models/installations/installation';

@Component({
  selector: 'app-unit-sidebar',
  templateUrl: './unit-sidebar.component.html',
  styleUrl: './unit-sidebar.component.scss'
})
export class UnitSidebarComponent {

  installation: Installation | null = null;
  unit: Unit | null = null;
  users: User[] = [];

  @Input()
  installationId: string | null = null;

  @Input()
  unitId: string | null = null;

  constructor(private units: UnitsManager, private installations: InstallationsManager) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.installationId && this.unitId) {
      this.units.getUnitDetails(this.installationId, this.unitId).subscribe({
        next: unit => this.unit = unit
      });

      this.units.getUnitUsers(this.installationId, this.unitId).subscribe({
        next: users => this.users = users
      });

      this.installations.getInstallation(this.installationId, false).subscribe({
        next: installation => this.installation = installation
      });
    }
  }

}
