import { Component, Input } from "@angular/core";
import { BaseDialogContent } from "../../dialogs/base-dialog-component";
import { InvitationsManager } from "../services/invitations-manager";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { finalize } from "rxjs";
import { openCloseAnimation } from "../../dialogs/animations/show-hide.animation";
import { Invitation } from "../../shared/models/invitations/invitation";

@Component({
  selector: "app-edit-user-invitation-dialog",
  templateUrl: "./edit-user-invitation-dialog.component.html",
  styleUrl: "./edit-user-invitation-dialog.component.scss",
  animations: [openCloseAnimation],
})
export class EditUserInvitationDialogComponent extends BaseDialogContent {
  @Input()
  invitation?: Invitation;

  @Input()
  installationId?: string;

  @Input()
  unitId?: string;

  email: string = "";
  note: string = "";
  expired: boolean = false;

  spinningMessage: string = "Invitations.DeletingWaitMessage";

  constructor(
    private invitationsManager: InvitationsManager,
    dialogs: DialogsService
  ) {
    super(dialogs);
  }

  override passData(data: any): void {
    this.invitation = data.invitation as Invitation;
    this.email = this.invitation?.email;
    this.note = this.invitation?.note || "";
    this.expired = this.invitation?.expiration < Date.now() / 1000 || false;
    this.installationId = data?.installationId;
    this.unitId = data?.unitId;
  }

  deleteInvitation() {
    if (!this.installationId || !this.invitation) {
      console.error("Missing required data");
      return;
    }

    this.startSpinning("Invitations.DeletingWaitMessage");
    let action = this.invitationsManager.deleteManagerInvitation(
      this.installationId,
      this.invitation.code
    );

    if (this.unitId != null) {
      action = this.invitationsManager.deleteUserInvitation(
        this.installationId,
        this.unitId,
        this.invitation.code
      );
    }

    action.pipe(finalize(() => (this.isBusy = false))).subscribe({
      next: (_) =>
        this.handleAPIResponse(true, "Invitations.ApiDeleteInvitationSuccess"),
      error: (_) =>
        this.handleAPIResponse(false, "Invitations.ApiDeleteInvitationError"),
    });
  }

  resend() {
    if (!this.installationId || !this.unitId || !this.invitation) {
      console.error("Missing required data");
      return;
    }

    this.startSpinning("Invitations.ResendWaitMessage");
    this.invitationsManager
      .resendUserInvitation(
        this.installationId,
        this.unitId,
        this.invitation.code
      )
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: () =>
          this.dialogs.displaySuccessNotification("Invitations.ResendSuccess"),
        error: (_) =>
          this.dialogs.displayErrorNotification("Invitations.ResendError"),
      });
  }

  private handleAPIResponse(success: boolean, message: string, data?: any) {
    if (success) {
      this.dialogs.hideDialog();
      this.dialogs.displaySuccessNotification(message);
      this.dialogs.sendDialogResult(data);
    } else {
      this.dialogs.displayErrorNotification(message);
    }
  }

  private startSpinning(message: string) {
    this.spinningMessage = message;
    this.isBusy = true;
  }
}
