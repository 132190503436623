import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-account-picture',
  templateUrl: './account-picture.component.html',
  styleUrl: './account-picture.component.scss'
})
export class AccountPictureComponent {

  @Input() image: string | ArrayBuffer | null = null;

}
