import { Pipe, PipeTransform } from "@angular/core";
import { UserAttributes } from "../shared/models/user-attributes";

@Pipe({
  name: "username",
})
export class UsernamePipe implements PipeTransform {
  transform(value: UserAttributes | null): string {
    if (value == null) {
      return "";
    }

    if (value.given_name && value.family_name) {
      return `${value.given_name} ${value.family_name}`;
    }

    return value.email;
  }
}
