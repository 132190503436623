export enum ControlLevel {
  Default = 0,

  Complete = 1,
}

export function controlLevelValues(): ControlLevel[] {
  return [ControlLevel.Default, ControlLevel.Complete];
}

export function controlLevelSelectValues(): any[] {
    return [
        {
            value: ControlLevel.Default,
            label: "Installations.ControlLevelDefault"
        },
        {
            value: ControlLevel.Complete,
            label: "Installations.ControlLevelComplete"
        }
    ];
  }
  