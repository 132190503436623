import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApllicationInfoComponent } from './apllication-info/apllication-info.component';
import { SharedModule } from '../shared/shared.module';
import { I18NextModule } from 'angular-i18next';
import { AngularSvgIconModule } from 'angular-svg-icon';


@NgModule({
  declarations: [
    ApllicationInfoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    I18NextModule,
    AngularSvgIconModule
  ]
})
export class PublicModule { }
