<div id="login-background">

    <div id="login-card">

        <div id="auth-card-content">
            <ng-content select="[slot=main-content]"></ng-content>
        </div>

        <div id="illustration-container">
            <img src="assets/icons/ic_app_icon_prod.svg" class="vertical-space"
                [alt]="'Accessibility.IPEvoLogoAltText' | i18next" />
            <p><a href="https://www.farfisa.com/ip-evo.html" target="_blank">{{"Auth.EvoFeatureDiscover" | i18next}}</a>
                {{"Auth.EvoFeatureDiscoverMessage" | i18next}}</p>
            <dx-gallery [dataSource]="evoFeaturesIllustrations" [height]="300" [width]="'auto'" [slideshowDelay]="2000"
                [loop]="true" class="vertical-space"></dx-gallery>
            <p class="vertical-space">{{"Auth.DashboardWelcomeTip" | i18next}}</p>
        </div>

    </div>

</div>