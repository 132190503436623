import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Injectable()
export class LocalizerService {

  constructor(@Inject(I18NEXT_SERVICE) private internationalization: ITranslationService) { }

  getLocalizedString(key: string, interpolationValues: any = null): string {
    if (!key) {
      throw new Error("TranslatorService: missing mandatory parameter key for getLocalizedString");
    }

    if (interpolationValues) {
      return this.internationalization.t(key, interpolationValues).toString();
    } else {
      return this.internationalization.t(key).toString();
    }
  }

  currentLanguage(): string {
    return this.internationalization.language;
  }

}
