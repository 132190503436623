@if (showAreaPlaceholder) {

    <div class="breadcrumb-placeholder">
    </div>

} @else {

<div id="breadcrumb">

    <a routerLink="/installations">
        <svg-icon class="breadcrumb-item" id="home-item" src="assets/icons/ic_home.svg">
        </svg-icon>
    </a>

    <div>-</div>

    <div *ngFor="let item of items; let last = last" [routerLink]="item.url"
        [ngClass]="{ 'breadcrumb-item': !last, 'breadcrumb-item-last': last }">
        <a [routerLink]="item.url">{{ item.label | i18next }}</a>
        <span *ngIf="!last" class="divider">/</span>
    </div>

</div>

}