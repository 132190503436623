<dx-data-grid [dataSource]="licenses" [hoverStateEnabled]="true">
  <dxo-paging [pageSize]="5"></dxo-paging>

  <dxi-column
    dataField="kind"
    alignment="center"
    caption=""
    width="auto"
    cellTemplate="kindTemplate"
  >
    <svg-icon
      [src]="data.value | licenseKind"
      *dxTemplate="let data of 'kindTemplate'"
      class="flex medium-icon fill-accent"
      [title]="data.value | licenseKind : true"
    >
    </svg-icon>
  </dxi-column>

  <dxi-column
    dataField="installation"
    alignment="center"
    [caption]="'General.Installation' | i18next"
    [visible]="showInstallationColumn"
  >
  </dxi-column>

  <dxi-column
    dataField="enabledUnits"
    [caption]="'General.Units' | i18next"
    alignment="center"
  >
  </dxi-column>

  <dxi-column
    dataField="activatedOn"
    [caption]="'General.ActivatedOn' | i18next"
    alignment="center"
    cellTemplate="dateTemplate"
  >
    <div *dxTemplate="let data of 'dateTemplate'">
      {{ data.value * 1000 | date : "longDate" }}
    </div>
  </dxi-column>

  <dxi-column dataField="version" alignment="center"> </dxi-column>
</dx-data-grid>
