import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationModule } from './authentication/authentication.module';
import { InstallationsComponent } from './installations/installations/installations.component';
import { InstallationsModule } from './installations/installations.module';
import { CompleteResetPasswordComponent, LoginComponent, ResetPasswordComponent, authenticatedGuard, unauthenticatedGuard } from './authentication';
import { SignupComponent } from './authentication/signup/signup.component';
import { ConfirmSignupComponent } from './authentication/confirm-signup/confirm-signup.component';
import { InstallationComponent } from './installations/installation/installation.component';
import { UnitDetailComponent } from './units/unit-detail/unit-detail.component';
import { IntercomDetailsComponent } from './intercoms/intercom-details/intercom-details.component';
import { InputsSettingsComponent } from './intercoms/inputs-settings/inputs-settings.component';
import { PreferencesSettingsComponent } from './intercoms/preferences-settings/preferences-settings.component';
import { DeviceInputsSettingsComponent } from './devices/device-inputs-settings/device-inputs-settings.component';
import { DeviceOutputsSettingsComponent } from './devices/device-outputs-settings/device-outputs-settings.component';
import { DevicePreferencesSettingsComponent } from './devices/device-preferences-settings/device-preferences-settings.component';
import { DevicePasscodesSettingsComponent } from './devices/device-passcodes-settings/device-passcodes-settings.component';

const routes: Routes = [
  {
    path: ':lng/installations',
    component: InstallationsComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId',
    component: InstallationComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId/units/:unitId',
    component: UnitDetailComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId/units/:unitId/intercoms/:intercomId',
    component: IntercomDetailsComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId/units/:unitId/intercoms/:intercomId/inputs',
    component: InputsSettingsComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId/units/:unitId/intercoms/:intercomId/status',
    component: PreferencesSettingsComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId/devices/:deviceId/inputs',
    component: DeviceInputsSettingsComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId/devices/:deviceId/outputs',
    component: DeviceOutputsSettingsComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId/devices/:deviceId/status',
    component: DevicePreferencesSettingsComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/installations/:installationId/devices/:deviceId/passcodes',
    component: DevicePasscodesSettingsComponent,
    canActivate: [authenticatedGuard]
  },
  {
    path: ':lng/account/login',
    component: LoginComponent,
    canActivate: [unauthenticatedGuard]
  },
  {
    path: ':lng/account/signup',
    component: SignupComponent,
    canActivate: [unauthenticatedGuard]
  },
  {
    path: ':lng/account/confirm-signup',
    component: ConfirmSignupComponent,
    canActivate: [unauthenticatedGuard]
  },
  {
    path: ':lng/account/reset-password',
    component: ResetPasswordComponent,
    canActivate: [unauthenticatedGuard]
  },
  {
    path: ':lng/account/complete-reset-password',
    component: CompleteResetPasswordComponent,
    canActivate: [unauthenticatedGuard]
  },
  {
    path: '**',
    redirectTo: 'it/installations'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true
    }),
    InstallationsModule,
    AuthenticationModule
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
