import { createReducer, on } from "@ngrx/store";
import { UserState } from "./user-state";
import { UserActions } from "./user.actions";

export const initialState: UserState = {
  licenses: [],
  isRetrieving: false,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.saveLicenses, (_, { licenses }) => {
    return {
      licenses: licenses,
      isRetrieving: false,
    };
  }),
  on(UserActions.startRetrieving, (_) => {
    return {
      licenses: [],
      isRetrieving: true,
    };
  }),
  on(UserActions.errorRetrieving, (_) => {
    return {
      licenses: [],
      isRetrieving: false,
    };
  })
);
