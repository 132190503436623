import notify from 'devextreme/ui/notify';
import { BaseResponsiveComponent } from './base-responsive.component';
import { LocalizerService } from '../services/localizer.service';
import { ScreenService } from '../services/screen.service';

export class BasePageComponent extends BaseResponsiveComponent {

    isBusy: boolean = false;

    errorMessage: string | null = null;


    constructor(screen: ScreenService, protected localizer: LocalizerService) {
        super(screen);
    }

    //#region static error management

    displayError(message: string | null = null) {
        this.errorMessage = message ? message : "General.Error";
    }

    resetError() {
        this.errorMessage = null;
    }

    //#endregion

    //#region notification messages methods

    displayLocalizedSuccessNotification(localizationKey: string) {
        const message = this.localizer.getLocalizedString(localizationKey);
        this.displaySuccessNotification(message);
    }

    displayLocalizedErrorNotification(localizationKey: string) {
        const message = this.localizer.getLocalizedString(localizationKey);
        this.displayErrorNotification(message);
    }

    displaySuccessNotification(message: string) {
        this.displayToastNotification(message, "success");
    }

    displayErrorNotification(message: string) {
        this.displayToastNotification(message, "error");
    }

    displayWarningNotification(message: string) {
        this.displayToastNotification(message, "warning");
    }

    private displayToastNotification(message: string, type: string) {
        const position: any = "bottom-right";
        const direction: any = "up-push";
        const stack = {
            position: position,
            direction: direction
        };

        const options = {
            message: message,
            width: "40vw",
            type: type,
            displayTime: 3500,
            animation: {
                show: {
                    type: 'fade', duration: 400, from: 0, to: 1,
                },
                hide: { type: 'fade', duration: 40, to: 0 },
            }
        };

        notify(options, stack);
    }

    //#endregion

}