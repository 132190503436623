import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicensesManagerService } from './licenses-manager.service';
import { LicensesListComponent } from './licenses-list/licenses-list.component';
import { DxButtonModule, DxDataGridModule, DxTextBoxModule } from 'devextreme-angular';
import { SharedModule } from '../shared/shared.module';
import { CreateLicenseDialogComponent } from './create-license-dialog/create-license-dialog.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LicenseKindPipe } from './pipes/license-kind.pipe';


@NgModule({
  declarations: [
    LicensesListComponent,
    CreateLicenseDialogComponent,
    LicenseKindPipe
  ],
  exports: [
    LicensesListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    DxDataGridModule,
    DxButtonModule,
    DxTextBoxModule,
    AngularSvgIconModule
  ],
  providers: [
    LicensesManagerService
  ]
})
export class LicensesModule { }
