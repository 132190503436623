import { Pipe, PipeTransform } from '@angular/core';
import { UnitImportState } from '../view-models/unit_imported_view_model';

@Pipe({ name: 'importErrorStatus' })
export class ImportErrorStatusPipe implements PipeTransform {

  transform(value: UnitImportState): string {
    switch (value) {
      case UnitImportState.UNIT_WRITE_SUCCESS_INVITATION_WRITE_FAILED:
      case UnitImportState.INVITATION_WRITE_FAILED:
        return 'ImportUnits.InvitationWriteRowError';
      case UnitImportState.UNIT_WRITE_FAILED:
        return 'ImportUnits.UnitWriteRowError';
      case UnitImportState.CSV_ROW_MALFORMED:
        return 'ImportUnits.CsvMalformedRowError';
      case UnitImportState.NO_LICENSE_AVAILABLE:
        return 'ImportUnits.NoLicenseAvailableRowError';
      case UnitImportState.UNIT_DUPLICATED:
        return 'ImportUnits.UnitDuplicatedRowError';
      default:
        return 'ImportUnits.GenericRowError';
    }
  }

}