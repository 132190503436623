<div [@openClose]="isLoading ? 'closed' : 'open'" class="dialog-vertial-layout">

    <div class="header">
        <svg-icon src="assets/icons/ic_user.svg" id="header-icon"></svg-icon>
        <span id="header-title">{{'Account.ConfigureProfileName' | i18next}}</span>
    </div>

    <dx-text-box class="full-width" [label]="'General.Name' | i18next" labelMode="static" [(value)]="name"
        [placeholder]="'Account.InsertProfileName' | i18next" valueChangeEvent="keyup" stylingMode="outlined"
        [showClearButton]="true">
    </dx-text-box>

    <dx-text-box class="full-width" [label]="'Account.FamilyName' | i18next" labelMode="static" [(value)]="familyName"
        [placeholder]="'Account.InsertProfileFamilyName' | i18next" valueChangeEvent="keyup" stylingMode="outlined"
        [showClearButton]="true">
    </dx-text-box>

    <div class="row-with-label">
        <span class="label"> {{'General.RequiredFieldDescription' | i18next}}</span>
    </div>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back(false)">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="updateUserAttributes()"
            [disabled]="!name || !familyName">
        </dx-button>
    </div>

</div>

<div *ngIf="isLoading" [@openClose]="'open'" class="dialog-spinner-container">
    <app-spinner [message]="'Account.ProfileConfigureWaitMessage'"></app-spinner>
</div>