import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class NetworkService {

    private _networkStatus = new BehaviorSubject<boolean>(window.navigator.onLine);

    constructor() {
        window.addEventListener('online', () => this._networkStatus.next(true));
        window.addEventListener('offline', () => this._networkStatus.next(false));
    }

    getNetworkStatus(): Observable<boolean> {
        return this._networkStatus.asObservable();
    }

}