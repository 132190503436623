import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DevicesManager } from '../services/devices-manager';
import { LocalizerService } from '../../shared';
import { debounceTime, finalize } from 'rxjs';
import { DeviceOutput } from '../models/device_output';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { AnimationOptions } from 'ngx-lottie';
import { DialogsService } from '../../dialogs/services/dialogs.service';

@Component({
  selector: 'app-device-outputs-settings',
  templateUrl: './device-outputs-settings.component.html',
  styleUrl: './device-outputs-settings.component.scss',
  animations: [openCloseAnimation]
})
export class DeviceOutputsSettingsComponent implements OnInit {

  @Input() installationId: string | null = null;
  @Input() deviceId: string | null = null;
  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();

  notConfiguredDeviceOutputs: DeviceOutput[] = [];
  configuredDeviceOutputs: DeviceOutput[] = [];
  selectedDeviceOutput: DeviceOutput | null = null;
  form!: FormGroup;

  // utility variables
  configureDeviceSettings: boolean = false;
  isBusy: boolean = false;
  isLoading = false;
  isConfiguringOutput = false;
  selectIcons: boolean = false;

  selectedIcon: string = '';
  icons: string[] = ['lock', 'door', 'gate', 'light'];

  mode = [
    { value: this.localizer.getLocalizedString('Outputs.Monostable'), code: 'monostable' },
    { value: this.localizer.getLocalizedString('Outputs.Bistable'), code: 'bistable' }
  ];

  address = [
    { value: this.localizer.getLocalizedString('Outputs.Capacitor0'), code: 'local:c:0' },
    { value: this.localizer.getLocalizedString('Outputs.Capacitor1'), code: 'local:c:1' },
    { value: this.localizer.getLocalizedString('Outputs.Standard0'), code: 'local:r:0' },
    { value: this.localizer.getLocalizedString('Outputs.Standard1'), code: 'local:r:1' }
  ];

  options: AnimationOptions = {
    path: '/assets/animations/loading.json',
    loop: true,
    autoplay: true
  };

  constructor(private devices: DevicesManager,
    private formBuilder: FormBuilder,
    private dialogs: DialogsService,
    private localizer: LocalizerService) { }

  ngOnInit() {
    if (!this.installationId) {
      this.dialogs.displayErrorNotification('Installations.InvalidInstallationId');
      return;
    }

    if (!this.deviceId) {
      this.dialogs.displayErrorNotification('Devices.InvalidDeviceId');
      return;
    }

    this.loadDeviceOutputs();
  }

  // #region Update device outputs

  /**
   * This method is called to add the selected device output.
   */
  addDeviceOutput() {
    if (!this.selectedDeviceOutput) {
      return;
    }

    this.isLoading = true;
    this.selectedDeviceOutput!.icon = this.selectedIcon;
    const deviceOutputToAdd = { ...this.selectedDeviceOutput, ...this.form.value };

    this.devices.addDeviceOutput(this.installationId!, this.deviceId!, deviceOutputToAdd)
      .subscribe({
        next: _ => {
          this.selectedDeviceOutput = deviceOutputToAdd;
          this.apiSuccessAction();
        },
        error: _ => {
          this.isLoading = false;
          this.dialogs.displayErrorNotification('Outputs.ApiAddDeviceOutputsError');
        }
      });
  }

  /**
   * This method is called to delete the selected device output.
   */
  deleteDeviceOutput() {
    if (!this.selectedDeviceOutput) {
      return;
    }

    this.isLoading = true;
    this.devices.deleteDeviceOutput(this.installationId!, this.deviceId!, this.selectedDeviceOutput.id!)
      .subscribe({
        next: _ => this.apiSuccessAction(),
        error: _ => {
          this.isLoading = false;
          this.dialogs.displayErrorNotification('Outputs.ApiDeleteDeviceOutputsError');
        }
      });
  }

  onValuesChanged() {
    if (!this.selectedDeviceOutput) {
      this.isConfiguringOutput = false;
      return;
    }

    const { mode, timeout } = this.form.getRawValue();
    this.isConfiguringOutput = this.form.valid && !(mode === 'monostable' && !timeout);
  }

  // #endregion

  // #region Utility methods

  toggleIconSelection() {
    this.selectIcons = !this.selectIcons;
  }

  /**
   * Sets the selected icon for the device output.
   * @param icon The icon selected by the user.
   */
  selectIcon(icon: string) {
    this.selectedIcon = icon;
    this.selectIcons = false;
  }

  toggleConfigureDeviceSettings() {
    this.configureDeviceSettings = !this.configureDeviceSettings;
  }

  /**
   * Displays the content for configuring the selected device output.
   * @param event The event object that contains the selected device output.
   */
  outputConfiguration(event: any) {
    this.selectedDeviceOutput = event.itemData;

    if (!this.selectedDeviceOutput!.id) {
      this.selectedDeviceOutput!.name = '';
      this.selectedDeviceOutput!.mode = '';
      this.selectedDeviceOutput!.icon = 'lock';
      this.checkDeviceKind();
    }

    this.selectedIcon = this.selectedDeviceOutput!.icon!;
    this.initForm();
    this.toggleConfigureDeviceSettings();
  }

  /**
   * This method is called to navigate back to the previous page.
   */
  back() {
    if (this.configureDeviceSettings) {
      this.selectIcons = false;
      this.toggleConfigureDeviceSettings();
      return;
    }
    this.backEvent.emit();
  }

  /**
   * Utility method used to format strings where the first letter needs to be capitalized.
   * @param value The input string to be capitalized.
   * @returns The input string to be capitalized.
   */
  capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  /**
   * Utility method used to get the address name.
   * @param value The address code.
   * @returns The address name.
   */
  getDataAddressName(value: string) {
    return this.address.find(x => x.code === value)?.value;
  }

  // #endregion

  // #region Private methods

  /**
   * Fetches the device outputs from the API.
   */
  private loadDeviceOutputs() {
    this.isBusy = true;

    this.devices.getDeviceOutputs(this.installationId!, this.deviceId!)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.isBusy = false;
      }))
      .subscribe({
        next: deviceOutputs => {
          this.configuredDeviceOutputs = deviceOutputs.filter(output => output.id);
          this.notConfiguredDeviceOutputs = deviceOutputs.filter(output => !output.id);
        },
        error: _ => this.dialogs.displayErrorNotification('Devices.ApiGetIntercomInputsError')
      });
  }

  /**
   * Initializes the form with the selected device output values.
   */
  private initForm() {
    const timeout = this.selectedDeviceOutput?.timeout != null && this.selectedDeviceOutput?.timeout !== 0
      ? (Number.isInteger(this.selectedDeviceOutput.timeout / 1000)
        ? (this.selectedDeviceOutput.timeout / 1000).toFixed(1)
        : (this.selectedDeviceOutput.timeout / 1000).toString())
      : null;

    this.form = this.formBuilder.group({
      name: [this.selectedDeviceOutput!.name, Validators.required],
      mode: [this.selectedDeviceOutput!.mode, Validators.required],
      timeout: [timeout, Validators.pattern('^(?!0$)([1-9]\d*|[0-9]*\.[0-9][0-9]*)$')]
    });

    if (this.selectedDeviceOutput?.kind === 'capacitor') {
      this.form.get('mode')?.disable();
    }

    this.onValuesChanged();
    this.subscribeToFormChanges();
  }

  /**
   * Subscribes to the form changes to enable the save button when the form is dirty.
   */
  private subscribeToFormChanges() {
    this.form.valueChanges.pipe(
      debounceTime(50)
    ).subscribe(() => {
      this.onValuesChanged();
    });
  }

  /**
   * Checks the kind of the selected device output.
   * If the kind is 'capacitor', the mode is set to 'monostable'
   * and the timeout is set to 500 milliseconds.
   */
  private checkDeviceKind() {
    if (this.selectedDeviceOutput!.kind === 'capacitor') {
      this.selectedDeviceOutput!.mode = 'monostable';
      this.selectedDeviceOutput!.timeout = 500;
    }
  }

  /**
   * Handles the actions to be performed after a successful API operation.
   */
  private apiSuccessAction() {
    this.configuredDeviceOutputs = [];
    this.notConfiguredDeviceOutputs = [];
    this.back();
    this.loadDeviceOutputs();
  }

}