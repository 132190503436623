import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Injectable()
export class NavigationService {

  constructor(private router: Router, @Inject(I18NEXT_SERVICE) private internationalization: ITranslationService) { }

  navigateToLogin(): void {
    this.navigateToRelativePath("account/login");
  }

  navigateToConfirmSignup(): void {
    this.navigateToRelativePath("account/confirm-signup");
  }

  navigateToResetPassword(): void {
    this.navigateToRelativePath("account/reset-password");
  }

  navigateToResetPasswordCompletion(): void {
    this.navigateToRelativePath("account/complete-reset-password");
  }

  navigateToInstallationsList(): void {
    this.navigateToRelativePath("installations");
  }

  navigateToInstallationDetail(installationId: string): void {
    this.navigateToRelativePath(`installations/${installationId}`);
  }

  navigateToUnitDetail(installationId: string, unitId: string): void {
    this.navigateToRelativePath(`installations/${installationId}/units/${unitId}`);
  }

  navigateToIntercomDetails(installationId: string, unitId: string, intercomId: string): void {
    this.navigateToRelativePath(`installations/${installationId}/units/${unitId}/intercoms/${intercomId}`);
  }

  navigateToInputSettings(installationId: string, unitId: string, intercomId: string): void {
    this.navigateToRelativePath(`installations/${installationId}/units/${unitId}/intercoms/${intercomId}/inputs`);
  }

  navigateToPreferencesSettings(installationId: string, unitId: string, intercomId: string): void {
    this.navigateToRelativePath(`installations/${installationId}/units/${unitId}/intercoms/${intercomId}/status`);
  }

  navigateToDeviceInputSettings(installationId: string, deviceId: string): void {
    this.navigateToRelativePath(`installations/${installationId}/devices/${deviceId}/inputs`);
  }

  navigateToDeviceOutputSettings(installationId: string, deviceId: string): void {
    this.navigateToRelativePath(`installations/${installationId}/devices/${deviceId}/outputs`);
  }

  navigateToDevicePreferencesSettings(installationId: string, deviceId: string): void {
    this.navigateToRelativePath(`installations/${installationId}/devices/${deviceId}/status`);
  }

  navigateToDevicePasscodesSettings(installationId: string, deviceId: string): void {
    this.navigateToRelativePath(`installations/${installationId}/devices/${deviceId}/passcodes`);
  }

  navigateTo(path: string) {
    if (!path) {
      throw new Error("NavigationService: null or empty path on private method navigateTo");
    }

    this.navigateToRelativePath(path);
  }

  private navigateToRelativePath(path: string) {
    if (!path) {
      throw new Error("NavigationService: null or empty path on private method navigateToRelativePath");
    }

    this.router.navigateByUrl(`${this.internationalization.language}/${path}`);
  }


}