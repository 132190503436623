import { Pipe, PipeTransform } from "@angular/core";
import { Permissions } from "../../shared/models/users/permissions";
import { isInstallerAccess } from "../../shared/models/users/access-level";

@Pipe({
  name: "installerAccess",
})
export class InstallerAccessPipe implements PipeTransform {
  transform(value?: Permissions): boolean {
    return value?.access != null ? isInstallerAccess(value.access) : false;
  }
}
