import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthenticationModule } from "./authentication/authentication.module";
import { InstallationsComponent } from "./installations/installations/installations.component";
import { InstallationsModule } from "./installations/installations.module";
import {
  CompleteResetPasswordComponent,
  LoginComponent,
  ResetPasswordComponent,
  authenticatedGuard,
  unauthenticatedGuard,
} from "./authentication";
import { SignupComponent } from "./authentication/signup/signup.component";
import { ConfirmSignupComponent } from "./authentication/confirm-signup/confirm-signup.component";
import { InstallationComponent } from "./installations/installation/installation.component";
import { UnitDetailComponent } from "./units/unit-detail/unit-detail.component";
import { IntercomDetailsComponent } from "./intercoms/intercom-details/intercom-details.component";
import { InputsSettingsComponent } from "./intercoms/inputs-settings/inputs-settings.component";
import { PreferencesSettingsComponent } from "./intercoms/preferences-settings/preferences-settings.component";
import { DeviceInputsSettingsComponent } from "./devices/device-inputs-settings/device-inputs-settings.component";
import { DeviceOutputsSettingsComponent } from "./devices/device-outputs-settings/device-outputs-settings.component";
import { DevicePreferencesSettingsComponent } from "./devices/device-preferences-settings/device-preferences-settings.component";
import { DevicePasscodesSettingsComponent } from "./devices/device-passcodes-settings/device-passcodes-settings.component";
import { ApllicationInfoComponent } from "./public/apllication-info/apllication-info.component";
import { PublicModule } from "./public/public.module";
import { appWebLicenseGuard } from "./shared/guards/app-web-license.guard";
import { redirectGuard } from "./shared/guards/redirect.guard";
import { RedirectingPageComponent } from "./shared/components/redirecting-page/redirecting-page.component";

// TODO: remove all unused paths!

const routes: Routes = [
  {
    path: ":lng/installations",
    component: InstallationsComponent,
    canActivate: [authenticatedGuard],
  },
  {
    path: ":lng/installations/:installationId",
    component: InstallationComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/installations/:installationId/units/:unitId",
    component: UnitDetailComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/installations/:installationId/units/:unitId/intercoms/:intercomId",
    component: IntercomDetailsComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/installations/:installationId/units/:unitId/intercoms/:intercomId/inputs",
    component: InputsSettingsComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/installations/:installationId/units/:unitId/intercoms/:intercomId/status",
    component: PreferencesSettingsComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/installations/:installationId/devices/:deviceId/inputs",
    component: DeviceInputsSettingsComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/installations/:installationId/devices/:deviceId/outputs",
    component: DeviceOutputsSettingsComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/installations/:installationId/devices/:deviceId/status",
    component: DevicePreferencesSettingsComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/installations/:installationId/devices/:deviceId/passcodes",
    component: DevicePasscodesSettingsComponent,
    canActivate: [authenticatedGuard, appWebLicenseGuard],
  },
  {
    path: ":lng/account/login",
    component: LoginComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    path: ":lng/account/signup",
    component: SignupComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    path: ":lng/account/confirm-signup",
    component: ConfirmSignupComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    path: ":lng/account/reset-password",
    component: ResetPasswordComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    path: ":lng/account/complete-reset-password",
    component: CompleteResetPasswordComponent,
    canActivate: [unauthenticatedGuard],
  },
  {
    path: "alexa",
    component: RedirectingPageComponent,
    canActivate: [redirectGuard],
    data: {
      targetUrl: "https://noprod-infinity-farfisa.auth.eu-central-1.amazoncognito.com/oauth2/authorize"
    }
  },
  {
    path: ":lng/application/info",
    component: ApllicationInfoComponent,
  },
  {
    path: "**",
    redirectTo: "it/installations",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
    InstallationsModule,
    PublicModule,
    AuthenticationModule,
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
