import { Component } from '@angular/core';
import { BasePageComponent } from '../../shared/components/base-page.component';
import { AuthenticationManager } from '../../shared/services/authentication-manager';
import { inOutAnimation } from '../animations/in-out.animation';
import { NavigationService, LocalizerService, ScreenService } from '../../shared';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
  animations: [inOutAnimation]
})
export class ResetPasswordComponent extends BasePageComponent {

  email: string = "";

  passwordFieldMode: "password" | "text" = "password";
  passwordButtonOptions = {
    icon: 'eyeopen',
    stylingMode: 'text',
    onClick: () => {
      this.passwordFieldMode = this.passwordFieldMode === "password" ? "text" : "password";
    },
  };

  constructor(private auth: AuthenticationManager,
    private navigation: NavigationService,
    screen: ScreenService,
    localizer: LocalizerService) {
    super(screen, localizer);
    this.auth.cancelResetPassword();
  }

  resetPassword() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.auth.resetPassword(this.email).subscribe({
      next: this.resetCodeSent.bind(this),
      error: this.codeSendingError.bind(this)
    });
  }

  private resetCodeSent() {
    this.isBusy = false;
    this.displaySuccessNotification(this.localizer.getLocalizedString("Auth.SendEmailSuccess"));
    this.navigation.navigateToResetPasswordCompletion();
  }

  private codeSendingError(error: any) {
    console.log(error);
    this.isBusy = false;
    const message = (typeof error === "string") ? error : null;
    this.displayError(message);
  }

}
