import { animate, style, transition, trigger } from "@angular/animations";

export const inOutAnimation = trigger(
    'inOutAnimation',
    [
        transition(
            ':enter',
            [
                style({ height: 0, opacity: 0 }),
                animate('100ms ease-out', style({ height: "auto", opacity: 1 }))
            ]
        ),
        transition(
            ':leave',
            [
                style({ height: "auto", opacity: 1 }),
                animate('100ms ease-out', style({ height: 0, opacity: 0 }))
            ]
        )
    ]
);