import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'thingNameToSerialNumber' })
export class ThingNameToSerialNumberPipe implements PipeTransform {

    transform(value: string): string {
        const serialNumber = value.split('-')[2];
        if (serialNumber) return serialNumber.toUpperCase();
        return serialNumber;
    }
}
