import { createReducer, on } from "@ngrx/store";
import { InstallationActions } from "./installation.actions";
import { InstallationState } from "./installation-state";
import { isInstallerAccess } from "../../shared/models/users/access-level";

export const initialState: InstallationState = {
  installation: null,
  hasFullAccess: false,
  licenses: [],
};

export const installationReducer = createReducer(
  initialState,
  on(InstallationActions.selectInstallation, (state, { installation }) => {
    return {
      ...state,
      installation: installation,
      hasFullAccess: isInstallerAccess(installation.permissions?.access)
    };
  }),
  on(InstallationActions.saveLicenses, (state, { licenses }) => {
    return {
      ...state,
      licenses: licenses,
    };
  }),
  on(InstallationActions.resetInstallation, (_) => {
    return {
      installation: null,
      hasFullAccess: false,
      licenses: [],
    };
  })
);
