import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'password' })
export class PasswordPipe implements PipeTransform {

    transform(value: string, enabled: boolean = true): string {
        if (!value) return '';

        if (enabled) return value.replace(/./g, '*');

        return value;
    }
}
