import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { Store } from "@ngrx/store";
import { selectUserState } from "../../state/user/user.selector";
import { filter, map, tap } from "rxjs";
import { LicenseKind } from "../../licenses/models/license";
import { NavigationService } from "../services/navigation.service";

export const appWebLicenseGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const navigation = inject(NavigationService);
  const installationId = route.paramMap.get("installationId");
  return store.select(selectUserState).pipe(
    filter((state) => state.isRetrieving === false),
    map((state) => {
      const installationLicenses = state.licenses.find(
        (l) => l.installation?.id == installationId
      );

      const appWebLicense = installationLicenses?.licenses?.find(
        (l) => l.kind == LicenseKind.APP_WEB
      );

      if (appWebLicense == null) {
        console.error("This installation has no app web license!");
      }

      return appWebLicense != null;
    }),
    tap((canActivate) => {
      if (!canActivate) {
        navigation.navigateToInstallationsList();
      }
    })
  );
};
