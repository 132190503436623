import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { InstallationLicensesDto } from "../../licenses/dto/installation-licenses-dto";

export const UserActions = createActionGroup({
  source: "User",
  events: {
    "Save licenses": props<{ licenses: InstallationLicensesDto[] }>(),
    "Start retrieving": emptyProps(),
    "Error retrieving": emptyProps(),
  },
});
