import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InvitationsManager } from "./services/invitations-manager";
import { ApiInvitationsManager } from "./services/api-invitations-manager";
import {
  DxButtonModule,
  DxDataGridModule,
  DxLoadIndicatorModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxTextBoxModule,
  DxTreeListModule,
  DxValidatorModule,
} from "devextreme-angular";
import { SharedModule } from "../shared/shared.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CreateUserInvitationDialogComponent } from "./create-user-invitation/create-user-invitation-dialog.component";
import { EditUserInvitationDialogComponent } from "./edit-user-invitation/edit-user-invitation-dialog.component";
import { CreateManagerInvitationDialogComponent } from "./create-manager-invitation-dialog/create-manager-invitation-dialog.component";
import { AcceptInvitationDialogComponent } from "./accept-invitation-dialog/accept-invitation-dialog.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { UserInvitationAccessPipeTsPipe } from "./user-invitation-access.pipe";

@NgModule({
  declarations: [
    CreateUserInvitationDialogComponent,
    EditUserInvitationDialogComponent,
    CreateManagerInvitationDialogComponent,
    AcceptInvitationDialogComponent,
    UserInvitationAccessPipeTsPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    AngularSvgIconModule,

    DxPopupModule,
    DxTextBoxModule,
    DxButtonModule,
    DxRadioGroupModule,
    DxLoadIndicatorModule,
    DxDataGridModule,
    DxTreeListModule,
    DxValidatorModule,
  ],
  exports: [
    CreateManagerInvitationDialogComponent,
    UserInvitationAccessPipeTsPipe,
  ],
  providers: [{ provide: InvitationsManager, useClass: ApiInvitationsManager }],
})
export class InvitationsModule {}
