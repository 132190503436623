<div class="widget-card" [ngClass]="{'card-area-placeholder': showAreaPlaceholder}"
    [ngStyle]="{'height': height, 'min-height': minHeight}">

    @if (icon || title || action || actionIcon) {
    <div class="title-bar">
        <svg-icon [src]="icon" class="icon" *ngIf="icon"></svg-icon>
        <div class="title" *ngIf="title">{{title}}</div>
        <dx-button type="default" [icon]="actionIcon" [text]="action" *ngIf="action && actionIcon"
            (onClick)="actionClick.emit(true)"></dx-button>
    </div>
    }

    @if (subtitle) {
    <div class="subtitle-bar" *ngIf="subtitle">
        <svg-icon [src]="subtitleIcon ?? ''" class="icon"></svg-icon>
        <div class="title">{{subtitle}}</div>
    </div>
    }

    @if (info || infoIcon) {
    <div class="info-bar" *ngIf="info">
        <svg-icon [src]="infoIcon ?? ''" class="icon"></svg-icon>
        <div class="title">{{info}}</div>
    </div>
    }

    <div class="card-body" [ngClass]="{'no-padding': bodyWithoutPadding}">
        <ng-content></ng-content>
    </div>

</div>