import themes from 'devextreme/ui/themes';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { Amplify } from 'aws-amplify';
import { environment } from './environments/environment';
 
import { licenseKey } from "./devextreme-license";
import config from 'devextreme/core/config';

// Register devexpress licenseKey. The constant is not under version control!
config({
  licenseKey
})

themes.initialized(() => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});


Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: environment.identityPoolId, // required to call APIs with AWS_IAM Authorizer
      userPoolId: environment.userPoolId,
      userPoolClientId: environment.userPoolClientId
    }
  },
  API: {
    REST: {
      "server_api": {
        endpoint: environment.apiUrl,
        region: 'eu-central-1'
      }
    }
  }
});