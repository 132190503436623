import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideBarComponent } from './side-bar/side-bar.component';
import { HeaderComponent } from './header/header.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DxButtonModule, DxContextMenuModule, DxToolbarModule } from 'devextreme-angular';
import { SharedModule } from '../shared/shared.module';
import { InstallationSidebarComponent } from './installation-sidebar/installation-sidebar.component';
import { UnitSidebarComponent } from './unit-sidebar/unit-sidebar.component';
import { GeneralSidebarComponent } from './general-sidebar/general-sidebar.component';
import { SidebarService } from './services/sidebar.service';
import { AccountModule } from '../account/account.module';
import { HomeSidebarComponent } from './home-sidebar/home-sidebar.component';



@NgModule({
  declarations: [
    HeaderComponent,
    SideBarComponent,
    InstallationSidebarComponent,
    UnitSidebarComponent,
    GeneralSidebarComponent,
    HomeSidebarComponent
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    DxButtonModule,
    DxToolbarModule,
    DxContextMenuModule,
    SharedModule,
    AccountModule
  ],
  exports: [
    HeaderComponent,
    SideBarComponent
  ],
  providers: [
    SidebarService
  ]
})
export class ApplicationLayoutModule { }
