import { Observable } from "rxjs";
import { BaseApiService } from "../../shared/utils/base-api-service";
import { Unit } from "../../shared/models/units/unit";
import { User } from "../../shared/models/users/user";
import { UnitImportedViewModel } from "../../installations/view-models/unit_imported_view_model";

export abstract class UnitsManager extends BaseApiService {

    /**
     * Retrieves unit details for a specific installation.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @returns the unit details.
     */
    abstract getUnitDetails(installation_id: string, unit_id: string): Observable<Unit>;

    /**
     * Retrieves all users associated to a specific unit.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @returns the list of users associated to the unit.
     */
    abstract getUnitUsers(installation_id: string, unit_id: string): Observable<User[]>;

    /**
     * Remove the user from the unit.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param user_id the user id
     * @returns success or failure.
     */
    abstract deleteUnitUser(installation_id: string, unit_id: string, user_id: string): Observable<boolean>;

    /**
     * Creates a unit for a specific installation.
     * @param installation_id the installation id
     * @param content the unit details
     * @returns the created unit.
     */
    abstract createUnit(installation_id: string, content: any): Observable<Unit>;

    /**
     * Deletes a unit for a specific installation.
     * @param installation_id the installation id
     * @param unit_id the unit id to delete
     * @returns true if the unit was deleted successfully, false otherwise.
     */
    abstract deleteUnit(installation_id: string, unit_id: string): Observable<boolean>;

    /**
     * Updates a unit for a specific installation.
     * @param installation_id the installation id
     * @param unit_id the unit id to update
     * @param content the unit details
     * @returns true if the unit was updated successfully, false otherwise.
     */
    abstract updateUnit(installation_id: string, unit_id: string, content: any): Observable<Unit>;

    /**
     * Updates devices permissions for a specific unit user.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param user_id the user id
     * @param content list of devices ids to update permissions.
     */
    abstract updateUnitUserDevicesPermissions(installation_id: string, unit_id: string, user_id: string, content: any): Observable<boolean>;

    /**
     * Imports units from a CSV file.
     * @param installation_id the installation id
     * @param csv the CSV file content
     * @returns the list of imported units.
     */
    abstract importUnits(installation_id: string, csv: string): Observable<UnitImportedViewModel[]>;
}