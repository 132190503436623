@if (userLoggedIn$ | async) {
<app-header (sidebarButtonClicked)="toggleSidebar()"></app-header>
}

<div id="page-content">

  <dx-drawer template="template" height="100%" [opened]="sidebarOpened">

    <app-side-bar *dxTemplate="let data of 'template'"></app-side-bar>

    <div class="main-page" [ngClass]="{'logged-in-content': userLoggedIn$ | async}">

      @if(missingInternetConnection$ | async) {
      <div class="offline-message">
        <h1 style="font-size: 40px;">{{'General.InternetOffline' | i18next}}</h1>
        <p>{{'General.InternetOfflineMessage' | i18next}}</p>
      </div>
      } @else {
      <router-outlet></router-outlet>
      }

    </div>

  </dx-drawer>

</div>


<app-dialog></app-dialog>