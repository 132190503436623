import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Installation } from '../../shared/models/installations/installation';
import { InstallationsManager } from '../../installations/services/installations-manager';
import { User } from '../../shared/models/users/user';
import { isManagerAccess } from '../../shared/models/users/access-level';
import { SidebarService } from '../services/sidebar.service';

@Component({
  selector: 'app-installation-sidebar',
  templateUrl: './installation-sidebar.component.html',
  styleUrl: './installation-sidebar.component.scss'
})
export class InstallationSidebarComponent implements OnChanges {

  installation: Installation | null = null;
  users: User[] = [];

  @Input()
  installationId: string | null = null;

  constructor(private installations: InstallationsManager, private sidebar: SidebarService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.installationId) {
      this.installations.getInstallation(this.installationId, false).subscribe({
        next: i => this.installation = i
      });

      this.installations.getInstallationUsers(this.installationId).subscribe({
        next: users => this.users = users.filter(u => isManagerAccess(u.access_level))
      });
    }
  }

  installationDevicesClicked() {
    this.sidebar.installationDevicesClicked();
  }

  installationDisassociateClicked() {
    this.sidebar.installationDisassociateClicked();
  }

  createManagerInvitationClicked() {
    this.sidebar.createManagerInvitationClicked();
  }

  openUserLicenses = () => this.sidebar.userLicensesClicked();

}
