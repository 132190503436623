import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizerService } from '../../shared';
import { InvitationsManager } from '../services/invitations-manager';
import { AccessLevel } from '../../shared/models/users/access-level';
import { ManagerInvitation } from '../../shared/models/invitations/manager-invitation';

@Component({
  selector: 'app-installation-invitation-details',
  templateUrl: './installation-invitation-details.component.html',
  styleUrl: './installation-invitation-details.component.scss'
})
export class InstallationInvitationDetailsComponent {

  @Input() isVisible: boolean = false;

  @Input() installationId: string | null = null;

  @Input() invitation: ManagerInvitation | null = null;

  @Output() isVisibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() deleteManagerInvitationResult = new EventEmitter<{ type: string, message: string }>();

  isLoading: boolean = false;

  invitation_roles = [
    {
      title: this.localizer.getLocalizedString('General.Manager'),
      subtitle: this.localizer.getLocalizedString('Invitations.ManagerDetails'),
      value: AccessLevel.MANAGER
    },
    {
      title: this.localizer.getLocalizedString('General.Installer'),
      subtitle: this.localizer.getLocalizedString('Invitations.InstallerDetails'),
      value: AccessLevel.INSTALLER
    }
  ];

  constructor(private localizer: LocalizerService,
    private invitationsManager: InvitationsManager
  ) { }

  deleteInvitation() {
    if (!this.installationId || !this.invitation) {
      return;
    }

    this.isLoading = true;
    this.invitationsManager.deleteManagerInvitation(this.installationId, this.invitation.code).subscribe({
      next: _ => this.apiResponseHandler({ type: 'success', message: 'Invitations.ApiDeleteInvitationSuccess' }),
      error: _ => this.apiResponseHandler({ type: 'error', message: 'Invitations.ApiDeleteInvitationError' })
    });
  }

  closePopup() {
    this.isVisibleChange.emit(false);
  }

  isExpired(expirationDate: number): boolean {
    const currentTime = Date.now();
    return expirationDate * 1000 < currentTime;
}


  // Private methods

  private apiResponseHandler(response : { type: string, message: string }) {
    this.isVisibleChange.emit(false)
    this.isLoading = false;
    this.deleteManagerInvitationResult.emit(response);
  }

}
