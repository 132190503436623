<div class="import-dialog-content">

    <svg-icon [src]="icon" class="icon" [ngClass]="iconClass"></svg-icon>

    <div class="headline-small">{{title | i18next}}</div>

    <div>{{message | i18next : {"rows": successRows, "totalRows": totalRows, "fileName": fileName} }}</div>

    <div *ngIf="(errorItems?.length ?? 0) > 0" class="rows-error-box">
        <div *ngFor="let item of errorItems" class="row">
            <div>{{"ImportUnits.Row" | i18next}} {{item.row_id}}</div>
            <div class="reason">{{item.state | importErrorStatus | i18next}}</div>
        </div>
    </div>

    <div class="actions">
        <dx-button text="Ok" type="default" (onClick)="confirmButtonClicked.emit()">
        </dx-button>
    </div>

</div>