import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationManager } from '../../authentication';
import { SidebarService } from '../services/sidebar.service';
import { Subscription } from 'rxjs';
import { UserAttributes } from '../../shared/models/user-attributes';

@Component({
  selector: 'app-general-sidebar',
  templateUrl: './general-sidebar.component.html',
  styleUrl: './general-sidebar.component.scss'
})
export class GeneralSidebarComponent implements OnInit, OnDestroy {

  private _userLoggedInSubscription: Subscription | null = null;
  private _userAttributesSubscription: Subscription | null = null;

  currentUserAttributes: UserAttributes | null = null;

  constructor(private auth: AuthenticationManager, private sidebar: SidebarService) { }

  ngOnInit(): void {
    this.loadCurrentUserAttributes();
    this._userLoggedInSubscription = this.auth.userLoggedInUpdates().subscribe({
      next: () => this.loadCurrentUserAttributes()
    });

    this.listenUserAttributesUpdates();
  }

  ngOnDestroy(): void {
    this._userLoggedInSubscription?.unsubscribe();
    this._userAttributesSubscription?.unsubscribe();
  }

  openProfileSettings = () => this.sidebar.profileSettingsClicked();

  openUserLicenses = () => this.sidebar.userLicensesClicked();

  private loadCurrentUserAttributes() {
    this.auth.currentUserAttributes().subscribe({
      next: (attributes) => this.currentUserAttributes = attributes
    });
  }

  private listenUserAttributesUpdates() {
    this._userAttributesSubscription = this.auth.userAttributesUpdates().subscribe({
      next: (attributes: UserAttributes | null) => {
        if (attributes) {
          this.currentUserAttributes = attributes;
        }
      }
    });
  }
}
