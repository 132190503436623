import { Injectable } from "@angular/core";
import { InvitationsManager } from "./invitations-manager";
import { Observable, throwError } from "rxjs";
import { ManagerInvitation } from "../../shared/models/invitations/manager-invitation";
import { UserInvitation } from "../../shared/models/invitations/user-invitation";
import { CreateManagerInvitationDTO } from "../dto/create-manager-invitation-dto";

@Injectable()
export class ApiInvitationsManager extends InvitationsManager {
  getInvitations(
    installation_id: string,
    unit_id: string
  ): Observable<UserInvitation[]> {
    if (!installation_id || !unit_id) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    return this.get<UserInvitation[]>(
      `/installations/${installation_id}/units/${unit_id}/invitations`
    );
  }

  createUserInvitation(
    installation_id: string,
    unit_id: string,
    content: any
  ): Observable<UserInvitation[]> {
    if (!installation_id || !unit_id) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    return this.post<UserInvitation[]>(
      `/installations/${installation_id}/units/${unit_id}/invitations`,
      content
    );
  }

  deleteUserInvitation(
    installation_id: string,
    unit_id: string,
    invitation_code: string
  ): Observable<boolean> {
    if (!installation_id || !unit_id || !invitation_code) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    return this.delete(
      `/installations/${installation_id}/units/${unit_id}/invitations/${invitation_code}`
    );
  }

  resendUserInvitation(
    installationId: string,
    unitId: string,
    invitationCode: string
  ): Observable<void> {
    if (!installationId || !unitId || !invitationCode) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    const url = `/installations/${installationId}/units/${unitId}/invitations/${invitationCode}/resend`;
    return this.post(url);
  }

  getManagerInvitations(
    installation_id: string
  ): Observable<ManagerInvitation[]> {
    if (!installation_id) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    return this.get<ManagerInvitation[]>(
      `/installations/${installation_id}/invitations`
    );
  }

  getManagerInvitationDetails(
    installation_id: string,
    invitation_code: string
  ): Observable<ManagerInvitation> {
    if (!installation_id || !invitation_code) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    return this.get<ManagerInvitation>(
      `/installations/${installation_id}/invitations/${invitation_code}`
    );
  }

  createManagerInvitation(
    installation_id: string,
    content: CreateManagerInvitationDTO
  ): Observable<ManagerInvitation[]> {
    if (!installation_id) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    return this.post<ManagerInvitation[]>(
      `/installations/${installation_id}/invitations`,
      content
    );
  }

  deleteManagerInvitation(
    installation_id: string,
    invitation_code: string
  ): Observable<boolean> {
    if (!installation_id || !invitation_code) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    return this.delete(
      `/installations/${installation_id}/invitations/${invitation_code}`
    );
  }

  acceptManagerInvitation(
    installation_id: string,
    invitation_code: string
  ): Observable<boolean> {
    if (!installation_id || !invitation_code) {
      const message = "ApiInvitationsManager invalid parameters";
      return throwError(() => new Error(message));
    }

    return this.post<boolean>(
      `/installations/${installation_id}/invitations/${invitation_code}/accept`
    );
  }
}
