import { from, map, Observable, Subject, tap } from 'rxjs';
import { signIn, signOut, resetPassword, confirmResetPassword, getCurrentUser, signUp, confirmSignUp, updatePassword, updateUserAttributes } from 'aws-amplify/auth';
import { UserAttributes } from '../models/user-attributes';

export class BaseAuthService {

    protected _userLoggedInUpdateSubject = new Subject<boolean>();

    protected _userAttributesSubject = new Subject<UserAttributes | null>();

    protected baseSignIn(username: string, password: string): Observable<boolean> {
        return from(signIn({ username, password })).pipe(
            map(res => res.isSignedIn),
            tap(loggedIn => this._userLoggedInUpdateSubject.next(loggedIn))
        );
    }

    protected baseSignUp(username: string, password: string): Observable<boolean> {
        return from(signUp({ username, password })).pipe(
            map(res => res.nextStep.signUpStep === "CONFIRM_SIGN_UP")
        );
    }

    protected baseConfirmSignUp(username: string, code: string): Observable<boolean> {
        return from(confirmSignUp({ username, confirmationCode: code })).pipe(
            map(res => res.isSignUpComplete)
        );
    }

    protected baseSignOut(): Observable<void> {
        return from(signOut()).pipe(
            tap(() => this._userLoggedInUpdateSubject.next(false))
        );
    }

    protected baseResetPassword(email: string): Observable<boolean> {
        return from(resetPassword({ username: email })).pipe(
            map(res => res.nextStep.resetPasswordStep === "CONFIRM_RESET_PASSWORD_WITH_CODE")
        );
    }

    protected baseConfirmResetPassword(username: string, code: string, newPassword: string): Observable<boolean> {
        return from(confirmResetPassword({ username, confirmationCode: code, newPassword })).pipe(
            map(() => true),
        );
    }

    protected baseUpdatePassword(oldPassword: string, newPassword: string): Observable<boolean> {
        return from(updatePassword({ oldPassword, newPassword })).pipe(
            map(() => true)
        );
    }

    protected baseGetCurrentUser(): Observable<string> {
        return from(getCurrentUser()).pipe(
            map(user => user.userId)
        );
    }

    protected baseUpdateUserAttributes(name: string, family_name: string): Observable<boolean> {
        const userAttributes = {
            userAttributes: {
                given_name: name,
                family_name: family_name
            }
        }

        return from(updateUserAttributes(userAttributes)).pipe(
            map(res => Object.values(res).every(attr => attr.isUpdated && attr.nextStep.updateAttributeStep === 'DONE'))
        );
    }
}
