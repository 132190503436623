import { Injectable } from "@angular/core";
import { DevicesManager } from "./devices-manager";
import { catchError, map, Observable, of, throwError } from "rxjs";
import { DeviceStatus } from "../models/iot/device_status";
import { DeviceInput, OutputAction } from "../models/device_input";
import { DeviceOutput } from "../models/device_output";
import { FarfisaDevice } from "../../shared/models/devices/farfisa_device";
import { DevicePreferences } from "../models/iot/device_status_preferences";
import { PasscodeACAction } from "../models/ac_action/passcode_ac_action";
import { Store } from "@ngrx/store";
import { selectInstallation } from "../../state/installation/installation.selector";

@Injectable()
export class ApiDevicesManager extends DevicesManager {
  constructor(private store: Store) {
    super();
  }

  getDevice(
    installation_id: string,
    device_id: string
  ): Observable<FarfisaDevice | null> {
    if (!installation_id) {
      return throwError(() => new Error("Invalid installation id"));
    }

    if (!device_id) {
      return throwError(() => new Error("Invalid device id"));
    }

    return this.fetchDeviceFromInstallation(device_id);
  }

  getDeviceStatus(
    installation_id: string,
    device_id: string
  ): Observable<DeviceStatus> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    return this.get<DeviceStatus>(
      `/installations/${installation_id}/devices/${device_id}/status`
    );
  }

  updateDevice(
    installation_id: string,
    device_id: string,
    name: string
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    const updated_device = { name: name };

    return this.put(
      `/installations/${installation_id}/devices/${device_id}`,
      updated_device
    );
  }

  deleteDevice(
    installation_id: string,
    device_id: string
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    return this.delete(
      `/installations/${installation_id}/devices/${device_id}`
    );
  }

  getDeviceInputs(
    installation_id: string,
    device_id: string
  ): Observable<DeviceInput[]> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    return this.get<DeviceInput[]>(
      `/installations/${installation_id}/devices/${device_id}/inputs`
    );
  }

  addDeviceInput(
    installation_id: string,
    device_id: string,
    device_input: DeviceInput,
    actions: OutputAction[]
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    device_input.action = { outputs: actions };

    return this.post(
      `/installations/${installation_id}/devices/${device_id}/inputs`,
      device_input
    );
  }

  deleteDeviceInput(
    installation_id: string,
    device_id: string,
    input_id: string
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    if (!input_id) return throwError(() => new Error("Invalid input id"));

    return this.delete(
      `/installations/${installation_id}/devices/${device_id}/inputs/${input_id}`
    );
  }

  getDeviceOutputs(
    installation_id: string,
    device_id: string
  ): Observable<DeviceOutput[]> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    return this.get<DeviceOutput[]>(
      `/installations/${installation_id}/devices/${device_id}/outputs`
    );
  }
  addDeviceOutput(
    installation_id: string,
    device_id: string,
    device_output: DeviceOutput
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    device_output.timeout = this.parseTimeout(device_output.timeout);

    return this.post(
      `/installations/${installation_id}/devices/${device_id}/outputs`,
      device_output
    );
  }

  deleteDeviceOutput(
    installation_id: string,
    device_id: string,
    output_id: string
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    if (!output_id) return throwError(() => new Error("Invalid output id"));

    return this.delete(
      `/installations/${installation_id}/devices/${device_id}/outputs/${output_id}`
    );
  }

  updateDevicePreferences(
    installation_id: string,
    device_id: string,
    updated_device_preferences: DevicePreferences
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    return this.post(
      `/installations/${installation_id}/devices/${device_id}/preferences`,
      updated_device_preferences
    ).pipe(
      map((response) => {
        return response === true;
      }),
      catchError((_) => {
        return of(false);
      })
    );
  }

  getDevicePasscodes(
    installation_id: string,
    device_id: string
  ): Observable<PasscodeACAction[]> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    return this.get<PasscodeACAction[]>(
      `/installations/${installation_id}/devices/${device_id}/passcodes`
    );
  }

  addDevicePasscode(
    installation_id: string,
    device_id: string,
    code: string,
    outputs: OutputAction[]
  ): Observable<PasscodeACAction[]> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    const content = { code: code, outputs: outputs };

    return this.post<PasscodeACAction[]>(
      `/installations/${installation_id}/devices/${device_id}/passcodes`,
      content
    );
  }

  deletePasscode(
    installation_id: string,
    device_id: string,
    passcode_id: string
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!device_id) return throwError(() => new Error("Invalid device id"));

    if (!passcode_id) return throwError(() => new Error("Invalid passcode id"));

    return this.delete(
      `/installations/${installation_id}/devices/${device_id}/passcodes/${passcode_id}`
    );
  }

  // Private methods

  /**
   * Fetches the device from the installation.
   * @param deviceId the device id
   * @returns the device or null if not found in the installation.
   */
  private fetchDeviceFromInstallation(
    deviceId: string
  ): Observable<FarfisaDevice | null> {
    return this.store.select(selectInstallation).pipe(
      map((state) => state.installation),
      map((installation) => {
        if (!installation || !installation.devices) {
          return null;
        }

        return installation.devices.find((d) => d.id === deviceId) || null;
      })
    );
  }

  /**
   * Parses the timeout value from the form.
   * Converts the timeout to milliseconds if valid, otherwise returns null.
   * @param timeout the timeout value from the form.
   * @returns the timeout in milliseconds or null.
   */
  private parseTimeout(timeout: any): number | undefined {
    return timeout != null && timeout !== "" ? timeout * 1000 : undefined;
  }
}
