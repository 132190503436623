import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  I18NextLoadResult,
  I18NEXT_SERVICE,
  ITranslationService,
} from "angular-i18next";
import HttpApi from "i18next-http-backend";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AngularSvgIconModule } from "angular-svg-icon";
import { AuthenticationModule } from "./authentication/authentication.module";
import { CognitoAuthenticationManager } from "./shared/services/cognito-authentication-manager";
import { registerLocaleData } from "@angular/common";

import { AuthenticationManager } from "./shared/services/authentication-manager";
import { i18nextOptions } from "./internalization/i18next-options";
import { LanguageDetectorService } from "./internalization/language-detector.service";
import { SharedModule } from "./shared/shared.module";
import { DxDrawerModule } from "devextreme-angular";
import { ApplicationLayoutModule } from "./application-layout/application-layout.module";

import localeIt from "@angular/common/locales/it";
import localeEn from "@angular/common/locales/en";
import { DialogsModule } from "./dialogs/dialogs.module";
import { provideLottieOptions } from "ngx-lottie";
import { StoreModule } from "@ngrx/store";
import { installationReducer } from "./state/installation/installation.reducers";
import { userReducer } from "./state/user/user.reducers";

registerLocaleData(localeIt); // Register the locale data for Italian. Needed for the date pipe.
registerLocaleData(localeEn);

export function appInit(i18next: ITranslationService) {
  return () => {
    const promise: Promise<I18NextLoadResult> = i18next
      .use(HttpApi)
      .use(LanguageDetectorService)
      .init(i18nextOptions);
    return promise;
  };
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DxDrawerModule,
    SharedModule,
    ApplicationLayoutModule,
    AngularSvgIconModule.forRoot(),
    DialogsModule,
    AppRoutingModule,
    AuthenticationModule,
    StoreModule.forRoot({
      installation: installationReducer,
      user: userReducer,
    }),
  ],
  providers: [
    I18N_PROVIDERS,
    { provide: AuthenticationManager, useClass: CognitoAuthenticationManager },
    provideLottieOptions({
      player: () => import("lottie-web"),
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
