<div class="dialog-vertial-layout">
  <div class="row-with-label full-width">
    <dx-text-box
      class="full-width"
      [value]="user?.email ?? ''"
      [label]="'General.Email' | i18next"
      labelMode="static"
      stylingMode="outlined"
      [placeholder]="'Invitations.EmailsPlaceholder' | i18next"
      [disabled]="true"
    >
    </dx-text-box>
  </div>

  <div>
    <div>{{ "Auth.Role" | i18next }}</div>
    <dx-radio-group
      [value]="selectedRole"
      [items]="invitationRoles"
      displayExpr="title"
      valueExpr="value"
      class="radio-group"
      [disabled]="true"
    >
      <div *dxTemplate="let data of 'item'">
        <div class="radio-item">
          <div class="radio-item-title">{{ data.title | i18next }}</div>
          <div class="radio-item-subtitle">{{ data.subtitle | i18next }}</div>
        </div>
      </div>
    </dx-radio-group>
  </div>

  <div class="dialog-action-bar">
    <dx-button
      [text]="'General.Cancel' | i18next"
      (onClick)="closeDialog()"
      stylingMode="text"
    >
    </dx-button>
    <dx-button text="Ok" type="default" (onClick)="closeDialog()"> </dx-button>
  </div>
</div>
