<div id="sidebar-header">
    <div class="overlay">
        <div class="overlay-title email-title">
            {{currentUserAttributes?.given_name || ''}} {{currentUserAttributes?.family_name || ''}}
        </div>
    </div>
</div>

<div class="sidebar-section">

    <div class="sidebar-section-title vspace">{{"Sidebar.Account" | i18next}}</div>

    <div class="sidebar-item vspace" (click)="openProfileSettings()">
        <svg-icon src="assets/icons/ic_account.svg" class="sidebar-item-icon"></svg-icon>
        <div class="sidebar-item-text">{{"Sidebar.ProfileSettings" | i18next}}</div>
    </div>

    <div class="sidebar-item" (click)="openUserLicenses()">
        <svg-icon src="assets/icons/ic_license.svg" class="sidebar-item-icon"></svg-icon>
        <div class="sidebar-item-text">{{"Installations.Licenses" | i18next}}</div>
    </div>

</div>

<div class="sidebar-section">

    <div class="sidebar-section-title vspace">Info</div>

    <div class="sidebar-item vspace">
        <svg-icon src="assets/icons/ic_faq.svg" class="sidebar-item-icon"></svg-icon>
        <div class="sidebar-item-text">FAQ</div>
    </div>

    <div class="sidebar-item">
        <svg-icon src="assets/icons/ic_info.svg" class="sidebar-item-icon"></svg-icon>
        <div class="sidebar-item-text">INFO</div>
    </div>

</div>