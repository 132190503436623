import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BasePageComponent } from "../../shared/components/base-page.component";
import { InstallationsManager } from "../services/installations-manager";
import {
  LocalizerService,
  NavigationService,
  ScreenService,
} from "../../shared";
import { AuthenticationManager } from "../../authentication";
import { Installation } from "../../shared/models/installations/installation";
import { ActivatedRoute } from "@angular/router";
import { finalize, takeUntil } from "rxjs";
import { DxScrollViewComponent } from "devextreme-angular/ui/scroll-view";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { CreateInstallationDialogComponent } from "../create-installation-dialog/create-installation-dialog.component";
import { AcceptInvitationDialogComponent } from "../../invitations/accept-invitation-dialog/accept-invitation-dialog.component";
import { InstallationLicensesDto } from "../../licenses/dto/installation-licenses-dto";
import { ProfileSettingsComponent } from "../../account/profile-settings/profile-settings.component";
import { UserAttributes } from "../../shared/models/user-attributes";
import { SidebarService } from "../../application-layout/services/sidebar.service";
import { AccountService } from "../../account/services/account.service";
import { Store } from "@ngrx/store";
import { InstallationActions } from "../../state/installation/installation.actions";
import { selectUserState } from "../../state/user/user.selector";
import { CreateLicenseDialogComponent } from "../../licenses/create-license-dialog/create-license-dialog.component";
import { UserActions } from "../../state/user/user.actions";
import { LicensesManagerService } from "../../licenses/licenses-manager.service";

@Component({
  selector: "app-installations",
  templateUrl: "./installations.component.html",
  styleUrl: "./installations.component.scss",
})
export class InstallationsComponent
  extends BasePageComponent
  implements OnInit, OnDestroy
{
  // properties populated from the query parameters to accept an invitation
  private _installationId: string | null = null;
  private _invitationCode: string | null = null;

  userInstallations: Installation[] = [];

  userAttributes: UserAttributes | null = null;
  imageContent: string | ArrayBuffer | null = null;

  viewTypeCards: boolean = true;
  showBackButton: boolean = false;
  showForwardButton: boolean = false;

  displayLicenses: boolean = false;
  userLicenses: InstallationLicensesDto[] = [];

  @ViewChild("scrollView") scrollView!: DxScrollViewComponent;

  constructor(
    private installations: InstallationsManager,
    private auth: AuthenticationManager,
    private navigation: NavigationService,
    private route: ActivatedRoute,
    private dialogs: DialogsService,
    private sidebar: SidebarService,
    private account: AccountService,
    private store: Store,
    private licenseManager: LicensesManagerService,
    localizer: LocalizerService,
    screen: ScreenService
  ) {
    super(screen, localizer);
  }

  ngOnInit() {
    this.store.dispatch(InstallationActions.resetInstallation());
    this.store
      .select(selectUserState)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((state) => {
        this.userLicenses = state.licenses;
      });

    this.initComponent();
    this.retrieveInstallations();
    this.retrieveCurrentUserAttributes();
    this.checkInvitation();
    this.listenSidebarEvents();
    this.retrieveAccountImage();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  updateScrollButtonsVisibility() {
    if (this.scrollView) {
      const scrollWidth = this.scrollView.instance.scrollWidth();
      const clientWidth = this.scrollView.instance.clientWidth();
      const leftOffset = this.scrollView.instance.scrollLeft();
      this.showBackButton = leftOffset > 0;
      this.showForwardButton = leftOffset + clientWidth < scrollWidth;
    }
  }

  scrollLeft = () => this.scrollBy(-300);

  scrollRight = () => this.scrollBy(300);

  viewAsCards = () => (this.viewTypeCards = true);

  viewAsList = () => (this.viewTypeCards = false);

  /**
   * Opens the installation detail page.
   * @param event The event that triggered the action.
   */
  openInstallationDetail(installation: Installation) {
    this.navigation.navigateToInstallationDetail(installation.id);
  }

  createInstallation() {
    const configuration = {
      title: "Installations.CreateInstallation",
      icon: "assets/icons/ic_add_directory.svg",
    };

    this.dialogs
      .showDialog(CreateInstallationDialogComponent, {}, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.retrieveInstallations(true));
  }

  editProfile() {
    if (!this.userAttributes) {
      return;
    }

    const configuration = {
      title: "Account.EditProfile",
      icon: "assets/icons/ic_edit.svg",
    };

    const data = {
      userAttributes: this.userAttributes,
      imageContent: this.imageContent,
    };

    this.dialogs
      .showDialog(ProfileSettingsComponent, data, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.retrieveCurrentUserAttributes());
  }

  showAddLicenseDialog(installation: Installation) {
    const configuration = {
      title: "Installations.AddLicense",
      icon: "assets/icons/ic_license.svg",
    };

    this.dialogs
      .showDialog(CreateLicenseDialogComponent, installation, configuration)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.retrieveUserLicenses());
  }

  // Private methods

  private initComponent() {
    this._installationId =
      this.route.snapshot.queryParamMap.get("installation_id");
    this._invitationCode =
      this.route.snapshot.queryParamMap.get("invitation_code");
  }

  private retrieveUserLicenses() {
    this.store.dispatch(UserActions.startRetrieving());
    this.licenseManager.getUserLicenses().subscribe({
      next: (licenses) =>
        this.store.dispatch(UserActions.saveLicenses({ licenses: licenses })),
      error: (_) => this.store.dispatch(UserActions.errorRetrieving()),
    });
  }

  /**
   * This method retrieves the installations from the API.
   */

  private retrieveInstallations(silently = false) {
    if (!silently) {
      this.isBusy = true;
    }

    this.installations
      .getInstallations()
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (installations) => {
          this.userInstallations = installations;
          setTimeout(() => this.updateScrollButtonsVisibility(), 0); // Wait for the view to render
        },
        error: () =>
          this.displayLocalizedErrorNotification("Installations.ApiListError"),
      });
  }

  private retrieveCurrentUserAttributes() {
    this.auth.currentUserAttributes().subscribe({
      next: (attributes) => {
        this.userAttributes = attributes;
      },
    });
  }

  /**
   * Checks if the user has an invitation to the installation.
   */

  private checkInvitation() {
    if (this._installationId && this._invitationCode) {
      const configuration = {
        title: "Invitations.AcceptInvitationTitle",
        icon: "assets/icons/ic_home.svg",
      };
      const data = {
        installation_id: this._installationId,
        invitation_code: this._invitationCode,
      };
      this.dialogs.showDialog(
        AcceptInvitationDialogComponent,
        data,
        configuration
      );
    }
  }

  /**
   * Scrolls the view by the specified distance.
   * @param distance
   */

  private scrollBy(distance: number) {
    var nativeScrollDiv = this.scrollView.instance
      .element()
      .querySelector(".dx-scrollable-container");
    nativeScrollDiv?.scrollBy({ left: distance, behavior: "smooth" });
  }

  private listenSidebarEvents() {
    this.sidebar
      .onProfileSettingsClick()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((_) => this.editProfile());

    this.sidebar
      .onUserLicensesClick()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((visible) => (this.displayLicenses = visible));
  }

  private retrieveAccountImage() {
    this.imageContent = JSON.parse(localStorage.getItem("userImage") || "null");
    this.account
      .imageSourceUpdated()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((image) => (this.imageContent = image));
  }
}
