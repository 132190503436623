import { Inject, Pipe, PipeTransform } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

@Pipe({ name: 'i18nRoute' })
export class I18nRoutePipe implements PipeTransform {

  constructor(@Inject(I18NEXT_SERVICE) private internationalization: ITranslationService) { }

  transform(relativePath: string): string {
    if (!relativePath) {
      throw new Error("I18nRoutePipe invalid null or empty relativePath");
    }

    if (relativePath.startsWith("/it") || relativePath.startsWith("/en")) {
      return relativePath;
    }

    return `/${this.internationalization.language}/${relativePath}`;
  }

}
