<div *ngIf="!activeSection" class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">

    <div class="horizontal-flex-row full-width">

        <div class="row-with-label">
            <span class="headline text-accent">{{device!.product_line | productLineName}}</span>
            <span class="label">{{device!.product_line | productTypeName}}</span>
        </div>

        <dx-button [text]="'Devices.DeleteDevice' | i18next" type="danger" stylingMode="outlined"
            (onClick)="deleteDevice()" [disabled]="!canEditDevice">
        </dx-button>

    </div>

    <div class="row-with-label full-width">
        <dx-text-box class="full-width" [(value)]="deviceName" [label]="'General.Name' | i18next" labelMode="static"
            valueChangeEvent="keyup" (onValueChanged)="updateConfirmButtonState()" stylingMode="outlined"
            [showClearButton]="true" [placeholder]="'Devices.NamePlaceholder' | i18next" [disabled]="!canEditDevice">
        </dx-text-box>
        <span class="label">{{'Devices.SerialNumber' | i18next}} {{device!.thing_name | thingNameToSerialNumber}}</span>
    </div>

    <div class="flex space-between center full-width">
        <div class="row-with-label">
            <div class="flex center gap-16">
                <svg-icon src="assets/icons/ic_input.svg" class="small-icon fill-accent"></svg-icon>
                <span class="text-xl text-accent">{{'General.Inputs' | i18next}}</span>
            </div>
            <span class="label">{{'Inputs.ConfigureInputMessage' | i18next}}</span>
        </div>

        <dx-button type="default" stylingMode="outlined" [text]="'General.Configure' | i18next"
            (onClick)="toggleSection('inputs')" [disabled]="!canEditDevice">
        </dx-button>
    </div>

    <div class="flex space-between center full-width">
        <div class="row-with-label">
            <div class="flex center gap-16">
                <svg-icon src="assets/icons/ic_output.svg" class="small-icon fill-accent"></svg-icon>
                <span class="text-xl text-accent">{{'General.Outputs' | i18next}}</span>
            </div>
            <span class="label">{{'Outputs.ConfigureOutputMessage' | i18next}}</span>
        </div>

        <dx-button type="default" stylingMode="outlined" [text]="'General.Configure' | i18next"
            (onClick)="toggleSection('outputs')" [disabled]="!canEditDevice">
        </dx-button>
    </div>

    <div class="flex space-between center full-width">
        <div class="row-with-label">
            <div class="flex center gap-16">
                <svg-icon src="assets/icons/ic_lock.svg" class="small-icon fill-accent"></svg-icon>
                <span class="text-xl text-accent">{{'General.Passcodes' | i18next}}</span>
            </div>
            <span class="label">{{'Passcodes.ManagePasscodesMessage' | i18next}}</span>
        </div>

        <dx-button type="default" stylingMode="outlined" [text]="'General.Manage' | i18next"
            (onClick)="toggleSection('passcodes')">
        </dx-button>
    </div>

    <div class="flex space-between center full-width">
        <div class="row-with-label">
            <div class="flex center gap-16">
                <svg-icon src="assets/icons/ic_preferences.svg" class="small-icon fill-accent"></svg-icon>
                <span class="text-xl text-accent">{{'General.Preferences' | i18next}}</span>
            </div>
            <span class="label">{{'Preferences.ManagePreferencesMessage' | i18next}}</span>
        </div>

        <dx-button type="default" stylingMode="outlined" [text]="'General.Manage' | i18next"
            (onClick)="toggleSection('preferences')" [disabled]="!canEditDevice">
        </dx-button>
    </div>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="updateDevice()"
            [disabled]="!thereAreChanges">
        </dx-button>
    </div>

</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Devices.DeviceEditWaitMessage'"></app-spinner>
</div>

<div *ngIf="activeSection == 'inputs'" class="dialog-settings-container">
    <app-device-inputs-settings [deviceId]="device!.id" [installationId]="installationId!"
        (backEvent)="toggleSection()"></app-device-inputs-settings>
</div>

<div *ngIf="activeSection == 'outputs'" class="dialog-settings-container">
    <app-device-outputs-settings [deviceId]="device!.id" [installationId]="installationId!"
        (backEvent)="toggleSection()"></app-device-outputs-settings>
</div>

<div *ngIf="activeSection == 'passcodes'" class="dialog-settings-container">
    <app-device-passcodes-settings [deviceId]="device!.id" [installationId]="installationId!"
        (backEvent)="toggleSection()"></app-device-passcodes-settings>
</div>

<div *ngIf="activeSection == 'preferences'" class="dialog-settings-container">
    <app-device-preferences-settings [deviceId]="device!.id" [installationId]="installationId!"
        (backEvent)="toggleSection()"></app-device-preferences-settings>
</div>