@if (showAreaPlaceholder) {

<div class="area-placeholder">
  <div class="title"></div>
  <div class="actions"></div>
</div>

} @else {

<div class="flex-col gap-24">
  <div class="flex center space-between">
    <div class="flex center gap-24">
      <ng-content select="[pre-title-view]"></ng-content>
      <div class="headline text-black">{{ title }}</div>
    </div>
    <div class="flex gap-32">
      <ng-content></ng-content>
    </div>
  </div>

  <div
    class="flex center gap-8 text-gray"
    *ngIf="locationInfo && locationInfoIcon"
  >
    <svg-icon
      [src]="locationInfoIcon"
      class="flex small-icon fill-accent"
    ></svg-icon>
    <div>{{ locationInfo }}</div>
  </div>

  <div class="flex center gap-8 text-gray" *ngIf="info && infoIcon">
    <svg-icon [src]="infoIcon" class="flex small-icon fill-accent"></svg-icon>
    <div>{{ info }}</div>
  </div>
</div>

}
