<div [@openClose]="isLoading ? 'closed' : 'open'" *ngIf="!isBusy">
    <div *ngIf="!isLoading && !configureDeviceSettings" class="dialog-vertial-layout">

        <div class="header">
            <svg-icon src="assets/icons/ic_lock.svg" id="header-icon"></svg-icon>
            <span id="header-title">{{'General.Passcodes' | i18next}}</span>
        </div>

        <dx-button type="default" stylingMode="outlined" [text]="'Passcodes.CreatePasscode' | i18next" icon="add"
            (onClick)="configurePasscode($event)">
        </dx-button>

        @if(passcodes.length > 0) {
        <dx-list id="dataConfigurationList" [dataSource]="passcodes" keyExpr="code" [hoverStateEnabled]="true"
            (onItemClick)="configurePasscode($event)" style="height: 100%;" itemTemplate="template">
            <div *dxTemplate="let data of 'template'" class="cell">
                <div class="cell-box">
                    <svg-icon src="assets/icons/ic_key.svg" id="key-icon"></svg-icon>
                    <span class="title">{{data.code}}</span>
                </div>
                <svg-icon src="assets/icons/ic_arrow_bottom.svg" class="action-icon"></svg-icon>
            </div>
        </dx-list>
        }@else {
        <div class="no-data-message">
            {{'Passcodes.NoPasscodes' | i18next}}
        </div>
        }

        <div class="dialog-action-bar">
            <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back()">
            </dx-button>
        </div>

    </div>

    <div *ngIf="!isLoading && configureDeviceSettings" class="dialog-vertial-layout">

        <div class="horizontal-flex-row full-width">
            <div class="header">
                <svg-icon src="assets/icons/ic_configure.svg" id="header-icon"></svg-icon>
                <span id="header-title">{{'Passcodes.ConfigurePasscode' | i18next}}</span>
            </div>

            @if(selectedPasscode) {
            <dx-button [text]="'Passcodes.DeletePasscode' | i18next" type="danger" stylingMode="outlined"
                (onClick)="deleteDevicePasscode()">
            </dx-button>
            }
        </div>

        <div class="row-with-label full-width">
            <dx-text-box class="full-width" [label]="'Passcodes.Code' | i18next" labelMode="static"
                [placeholder]="'Passcodes.InsertCode' | i18next" [mode]="codeFieldMode" maxLength="8" [(value)]="code"
                valueChangeEvent="keyup" stylingMode="outlined" [disabled]="selectedPasscode != null">
                <dxi-button name="toggleVisibility" location="after" [options]="codeButtonOptions">
                </dxi-button>
            </dx-text-box>
            <span class="label">{{'Passcodes.CodeMessage' | i18next}}</span>
        </div>

        <span class="title">{{'General.Outputs' | i18next}}</span>
        @if (deviceOutputs.length > 0) {
        <dx-data-grid keyExpr="address" [dataSource]="deviceOutputs" [hoverStateEnabled]="true" [showBorders]="false"
            [showColumnHeaders]="false" [selectedRowKeys]="getSelectedRowKeys()"
            (onSelectionChanged)="onSelectionChanged($event)">
            <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
            <dxi-column dataField="name"></dxi-column>
        </dx-data-grid>
        } @else {
        <div class="no-data-message">
            {{'Passcodes.EmptyOutputsList' | i18next}}
        </div>
        }

        <div class="row-with-label">
            <span class="label"> {{'General.RequiredFieldDescription' | i18next}}</span>
        </div>

        <div class="dialog-action-bar">
            <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back()">
            </dx-button>
            <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="saveDevicePasscode()"
                [disabled]="deviceOutputs.length == 0 || selectedOutputs.length == 0 || !code || !isCodeValid()">
            </dx-button>
        </div>

    </div>
</div>

<ng-lottie *ngIf="isBusy && !isLoading" [options]="options"></ng-lottie>

<div *ngIf="isLoading" [@openClose]="'open'" class="dialog-spinner-container">
    <app-spinner [message]="'Passcodes.PasscodeConfigureWaitMessage'"></app-spinner>
</div>