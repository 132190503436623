import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { BaseDialogContent } from '../../dialogs/base-dialog-component';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { NavigationService } from '../../shared';
import { UserAttributes } from '../../shared/models/user-attributes';
import { CognitoAuthenticationManager } from '../../shared/services/cognito-authentication-manager';
import { finalize } from 'rxjs';
import { AccountService } from '../services/account.service';
import { AccountManager } from '../services/account-manager';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrl: './profile-settings.component.scss',
  animations: [openCloseAnimation]
})
export class ProfileSettingsComponent extends BaseDialogContent {

  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() currentUserAttributes: UserAttributes | null = null;
  @Input() imageContent: string | ArrayBuffer = '';
  @Output() updateProfile: EventEmitter<UserAttributes> = new EventEmitter<UserAttributes>();

  selectedImage: File | undefined;

  name: string = '';
  familyName: string = '';

  activeSection: string = '';

  askForDeletion: boolean = false;

  constructor(dialogs: DialogsService,
    private account: AccountManager,
    private auth: CognitoAuthenticationManager,
    private accountService: AccountService,
    private navigation: NavigationService) {
    super(dialogs);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.currentUserAttributes = data.userAttributes as UserAttributes;
    this.imageContent = data.imageContent || this.retrieveAccountImage();
    if (this.currentUserAttributes) {
      this.name = this.currentUserAttributes.given_name || '';
      this.familyName = this.currentUserAttributes.family_name || '';
    }
  }

  //#endregion

  // #region account deletion methods

  deleteAccount() {
    this.hideTitleRequested.emit(true);
    this.askForDeletion = true;
  }

  completeAccountRemoval() {
    this.isBusy = true;

    this.account.deleteAccount()
      .pipe(finalize(() => this.isBusy = false))
      .subscribe({
        next: _ => {
          this.dialogs.hideDialog();
          this.auth.logout().subscribe({
            next: _ => {
              this.navigation.navigateToLogin();
              this.dialogs.displaySuccessNotification('Account.ApiDeleteAccountSuccess');
            }
          });
        },
        error: _ => this.dialogs.displayErrorNotification('Account.ApiDeleteAccountError')
      });
  }

  // #endregion

  //#region installation image edit methods

  openFinderDialog() { this.fileInput.nativeElement.click(); }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.selectedImage = file;
      const reader = new FileReader();
      reader.onload = _ => {
        this.imageContent = reader.result!;
        this.accountService.updateImage(this.imageContent);
      };
      reader.readAsDataURL(file);
    }
  }

  //#endregion

  userAttributesUpdated(event: boolean) {
    this.toggleSection();
    event ? (this.dialogs.sendDialogResult(true), this.closeDialog()) : null;
  }


  toggleSection(section?: string) {
    this.activeSection = section || '';
  }

  back() {
    this.askForDeletion = false;
    this.hideTitleRequested.emit(false);
  }

  private retrieveAccountImage(): string {
    return JSON.parse(localStorage.getItem("userImage") || "null");
  }

}
