import { Pipe, PipeTransform } from "@angular/core";
import { InstallationUnitViewModel } from "../view-models/installation_unit_view_model";
import { FarfisaDevice } from "../../shared/models/devices/farfisa_device";

@Pipe({
  name: "filterByDevice",
})
export class FilterByDevicePipe implements PipeTransform {
  transform(
    units: InstallationUnitViewModel[],
    devices: FarfisaDevice[]
  ): InstallationUnitViewModel[] {
    if (!units || !devices || devices.length === 0) {
      return units;
    }

    return units.filter((u) => devices.find(d => u.devices.indexOf(d.name) > -1) != null);
  }
}
