import { Observable } from "rxjs";
import { BaseApiService } from "../../shared/utils/base-api-service";
import { ManagerInvitation } from "../../shared/models/invitations/manager-invitation";
import { UserInvitation } from "../../shared/models/invitations/user-invitation";
import { CreateManagerInvitationDTO } from "../dto/create-manager-invitation-dto";

export abstract class InvitationsManager extends BaseApiService {

    /**
     * Retrieves list of user invitations.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @returns a list of user invitations.
     */
    abstract getInvitations(installation_id: string, unit_id: string): Observable<UserInvitation[]>;

    /**
     * Creates a user invitation for a specific unit.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param content the invitation details
     * @returns a list of user invitations.
    */
    abstract createUserInvitation(installation_id: string, unit_id: string, content: any): Observable<UserInvitation[]>;

    /**
     * Deletes a user invitation for a specific unit.
     * @param installation_id the installation id
     * @param unit_id the unit id
     * @param invitation_code the invitation code to delete
     * @returns true if the invitation was deleted successfully, false otherwise.
     */
    abstract deleteUserInvitation(installation_id: string, unit_id: string, invitation_code: string): Observable<boolean>;

    /**
     * Resend the email to the user with the invitation code.
     * @param installationId the installation id
     * @param unitId the unit id
     * @param invitationCode the invitation code to resend
     * @returns true if the email was resent successfully, false otherwise.
     */
    abstract resendUserInvitation(installationId: string, unitId: string, invitationCode: string): Observable<void>;

    /**
     * Retrieves list of manager invitations for a specific installation.
     * @param installation_id the installation id
     * @returns a list of manager invitations
     */
    abstract getManagerInvitations(installation_id: string): Observable<ManagerInvitation[]>;

    /**
     * Retrieves a manager invitation for a specific installation.
     * @param installation_id the installation id
     * @param invitation_code the invitation code
     * @returns the manager invitation
     */
    abstract getManagerInvitationDetails(installation_id: string, invitation_code: string): Observable<ManagerInvitation>;

    /**
     * Creates a manager invitation for a specific installation.
     * @param installation_id the installation id
     * @param content the invitation details
     * @returns a list of manager invitations.
     */
    abstract createManagerInvitation(installation_id: string, content: CreateManagerInvitationDTO): Observable<ManagerInvitation[]>;

    /**
     * Deletes a manager invitation for a specific installation.
     * @param installation_id the installation id
     * @param invitation_code the invitation code to delete
     * @returns true if the invitation was deleted successfully, false otherwise.
     */
    abstract deleteManagerInvitation(installation_id: string, invitation_code: string): Observable<boolean>;

    /**
     * Accepts a manager invitation for a specific installation.
     * @param installation_id the installation id
     * @param invitation_code the invitation code to accept
     * @returns true if the invitation was accepted successfully, false otherwise.
     */
    abstract acceptManagerInvitation(installation_id: string, invitation_code: string): Observable<boolean>;

}