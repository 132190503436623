import { Pipe, PipeTransform } from "@angular/core";
import { profileColorPalette } from "../constants";

@Pipe({ name: 'labelBackground' })
export class LabelBackgroundPipe implements PipeTransform {

  transform(index: number): string {
    return profileColorPalette[index % profileColorPalette.length];
  }

}