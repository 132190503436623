<app-auth-card>

    <div id="auth-form-container" slot="main-content">

        <img src="assets/icons/ic_farfisa_logo.png" class="item" style="width: auto; max-width: 100%;"
            [alt]="'Accessibility.FarfisaLogoAltText' | i18next" />
        <p class="item large-top-space">{{message}}</p>

        <ng-content></ng-content>

        <div *ngIf="errorMessage" [@inOutAnimation] class="item error-box">{{errorMessage | i18next}}</div>

        <dx-button type="default" class="item large-top-space" (onClick)="onButtonClick()" height="40">
            <div *dxTemplate="let data of 'content'" style="display: flex;">
                <dx-load-indicator class="button-indicator" height="24" width="24" [visible]="isBusy">
                </dx-load-indicator>
                <span class="dx-button-text" style="margin-left: 16px;">{{action}}</span>
            </div>
        </dx-button>

    </div>

</app-auth-card>