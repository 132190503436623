import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitsManager } from './services/units-manager';
import { ApiUnitsManager } from './services/api-units-manager';
import { DxButtonModule, DxDataGridModule, DxLoadIndicatorModule, DxPopupModule, DxTextBoxModule, DxTreeListModule } from 'devextreme-angular';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { UnitDetailComponent } from './unit-detail/unit-detail.component';
import { InvitationsModule } from '../invitations/invitations.module';
import { IntercomsModule } from '../intercoms/intercoms.module';
import { UnitUserSubtitlePipe } from './pipes/unit-user-subtitle.pipe';
import { UnitUserSubtitleStylePipe } from './pipes/unit-user-subtitle-style.pipe';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EditUnitDialogComponent } from './edit-unit-dialog/edit-unit-dialog.component';
import { EditUnitUserDialogComponent } from './edit-unit-user-dialog/edit-unit-user-dialog.component';
import { CreateUnitDialogComponent } from './create-unit-detail/create-unit-dialog.component';


@NgModule({
  declarations: [
    UnitDetailComponent,
    EditUnitDialogComponent,
    EditUnitUserDialogComponent,
    CreateUnitDialogComponent,
    UnitUserSubtitlePipe,
    UnitUserSubtitleStylePipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    InvitationsModule,
    IntercomsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    DxPopupModule,
    DxTextBoxModule,
    DxButtonModule,
    DxLoadIndicatorModule,
    DxDataGridModule,
    DxTreeListModule
  ],
  exports: [
    UnitDetailComponent,
    CreateUnitDialogComponent
  ],
  providers: [
    { provide: UnitsManager, useClass: ApiUnitsManager }
  ]
})
export class UnitsModule { }
