import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { AuthCardComponent } from './auth-card/auth-card.component';
import { BaseAuthFormComponent } from './base-auth-form/base-auth-form.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CompleteResetPasswordComponent } from './complete-reset-password/complete-reset-password.component';
import { SharedModule } from '../shared/shared.module';
import { DxButtonModule, DxGalleryModule, DxLoadIndicatorModule, DxTextBoxModule } from 'devextreme-angular';
import { SignupComponent } from './signup/signup.component';
import { ConfirmSignupComponent } from './confirm-signup/confirm-signup.component';



@NgModule({
  declarations: [
    LoginComponent,
    AuthCardComponent,
    BaseAuthFormComponent,
    ResetPasswordComponent,
    CompleteResetPasswordComponent,
    SignupComponent,
    ConfirmSignupComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,

    DxButtonModule,
    DxTextBoxModule,
    DxGalleryModule,
    DxLoadIndicatorModule
  ],
  exports: [
    LoginComponent
  ]
})
export class AuthenticationModule { }
