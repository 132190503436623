import { Component, Input } from '@angular/core';
import { BaseDialogContent } from '../../dialogs/base-dialog-component';
import { Unit } from '../../shared/models/units/unit';
import { LocalizerService } from '../../shared';
import { InvitationsManager } from '../services/invitations-manager';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { finalize } from 'rxjs';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { AccessLevel } from '../../shared/models/users/access-level';
import { emailRegex } from '../../shared';

@Component({
  selector: 'app-create-user-invitation-dialog',
  templateUrl: './create-user-invitation-dialog.component.html',
  styleUrl: './create-user-invitation-dialog.component.scss',
  animations: [openCloseAnimation]
})
export class CreateUserInvitationDialogComponent extends BaseDialogContent {

  @Input()  
  unit: Unit | undefined;

  emails: string = '';
  notes: string = '';
  thereAreChanges: boolean = false;

  constructor(private localizer: LocalizerService, private invitationsManager: InvitationsManager, dialogs: DialogsService) {
    super(dialogs);
  }

  //#region BaseDialogContent overrides

  override passData(data: any): void {
    this.unit = data as Unit;
  }

  private hasFormChanges(): boolean {
    if (this.emails != '') return true;
    if (this.notes != '') return true;
    return false;
    
  }
  updateConfirmButtonState() {
    this.thereAreChanges = this.hasFormChanges();

  }
  /**
   * Get the valid emails from the input.
   * @param emails The emails input.
   * @returns List of valid emails.
   */
    private getValidEmails(emails: string): string[] {
      return emails
        .split(',')
        .map(email => email.trim())
        .filter(email => emailRegex.test(email));
    }
  
  /**
   * Create the content to send to the API to create the invitation.
   * @returns The content to send to the API to create the invitation.
   */
  private createSendInvitationContent() {
    return {
      "emails": this.getValidEmails(this.emails),
      "access": AccessLevel.ADMIN_USER,
      "devices": this.unit?.devices,
      "note": this.notes,
    }
  }  
  createInvitation() {
    if (!this.unit) {
      return;
    }

    if (this.hasFormChanges()) {
      this.isBusy = true;
      const content = this.createSendInvitationContent();
      this.invitationsManager.createUserInvitation(this.unit.installation_id, this.unit.id, content).pipe(
        finalize(() => this.isBusy = false)
      ).subscribe({
        next: data => this.handleAPIResponse(true, 'Invitations.ApiCreateInvitationSuccess', data),
        error: _ => this.handleAPIResponse(false, 'Invitations.ApiCreateInvitationError')
      });
    }
  }

  private handleAPIResponse(success: boolean, message: string, data?: any) {
    if (success) {
      this.dialogs.hideDialog();
      this.dialogs.displaySuccessNotification(message);
      this.dialogs.sendDialogResult(data);
    } else {
      this.dialogs.displayErrorNotification(message);
    }
  }

}
