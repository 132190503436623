import { Injectable } from "@angular/core";
import { Installation } from "../models/installations/installation";
import { Unit } from "../models/units/unit";
import { FarfisaDevice } from "../models/devices/farfisa_device";
import { Intercom } from "../../intercoms/models/intercom";

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private _installations: Installation[] = [];

    private _devices: FarfisaDevice[] = [];

    private _intercoms: Intercom[] = [];

    private _units: Unit[] = [];


    // Installations region

    addInstallation(new_installation: Installation) {
        if (!new_installation) {
            return;
        }

        const existingInstallation = this._installations.find(inst => inst.id === new_installation.id);
        if (!existingInstallation) {
            this._installations.push(new_installation);
        }
    }

    updateInstallation(updated_installation: Installation) {
        const index = this._installations.findIndex(installation => installation.id === updated_installation.id);
        if (index >= 0) {
            this._installations[index] = updated_installation;
        }
    }

    removeInstallation(installation_id: string) {
        this._installations = this._installations.filter(installation => installation.id !== installation_id);
    }

    setInstallations(installations: Installation[]) {
        this._installations = installations;
    }

    getInstallations(): Installation[] {
        return this._installations;
    }

    getInstallation(installation_id: string): Installation | null {
        return this._installations.find(installation => installation.id === installation_id) || null;
    }

    // Devices region
    addDevice(new_device: FarfisaDevice) {
        const existingDevice = this._devices.find(device => device.id === new_device.id);
        if (!existingDevice) {
            this._devices.push(new_device);
        }
    }

    updateDevice(updated_device: FarfisaDevice) {
        const index = this._devices.findIndex(device => device.id === updated_device.id);
        if (index >= 0) {
            this._devices[index] = updated_device;
        }
    }

    removeDevice(device_id: string) {
        this._devices = this._devices.filter(device => device.id !== device_id);
    }

    setDevices(devices: FarfisaDevice[]) {
        this._devices = devices;
    }

    getDevices(): FarfisaDevice[] {
        return this._devices;
    }

    getDevice(device_id: string): FarfisaDevice | null {
        return this._devices.find(device => device.id === device_id) || null;
    }

    // Units region
    addUnit(new_unit: Unit) {
        const existingUnit = this._units.find(unit => unit.id === new_unit.id);
        if (!existingUnit) {
            this._units.push(new_unit);
        }
    }

    updateUnit(updated_unit: Unit) {
        const index = this._units.findIndex(unit => unit.id === updated_unit.id);
        if (index >= 0) {
            this._units[index] = updated_unit;
        }
    }

    removeUnit(unit_id: string) {
        this._units = this._units.filter(unit => unit.id !== unit_id);
    }

    setUnits(units: Unit[]) {
        this._units = units;
    }

    getUnits(): Unit[] {
        return this._units;
    }

    getUnit(unit_id: string): Unit | null {
        return this._units.find(unit => unit.id === unit_id) || null;
    }

    // Intercoms region
    addIntercom(new_intercom: Intercom) {
        const existingIntercom = this._intercoms.find(intercom => intercom.id === new_intercom.id);
        if (!existingIntercom) {
            this._intercoms.push(new_intercom);
        }
    }

    updateIntercom(updated_intercom: Intercom) {
        const index = this._intercoms.findIndex(intercom => intercom.id === updated_intercom.id);
        if (index >= 0) {
            this._intercoms[index] = updated_intercom;
        }
    }

    removeIntercom(intercom_id: string) {
        this._intercoms = this._intercoms.filter(intercom => intercom.id !== intercom_id);
    }

    setIntercoms(intercoms: Intercom[]) {
        this._intercoms = intercoms;
    }

    getIntercoms(): Intercom[] {
        return this._intercoms;
    }

    getIntercom(intercom_id: string): Intercom | null {
        return this._intercoms.find(intercom => intercom.id === intercom_id) || null;
    }

    clearUnitSession() {
        this._intercoms = [];
    }

    clearInstallationSession() {
        this._devices = [];
    }

}