import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DxButtonModule, DxDataGridModule, DxListModule, DxLoadIndicatorModule, DxNumberBoxModule, DxPopupModule, DxRadioGroupModule, DxSelectBoxModule, DxSliderModule, DxSwitchModule, DxTextBoxModule } from 'devextreme-angular';
import { DevicesManager } from './services/devices-manager';
import { ApiDevicesManager } from './services/api-devices-manager';
import { DeviceInputsSettingsComponent } from './device-inputs-settings/device-inputs-settings.component';
import { DevicePreferencesSettingsComponent } from './device-preferences-settings/device-preferences-settings.component';
import { DeviceOutputsSettingsComponent } from './device-outputs-settings/device-outputs-settings.component';
import { DevicePasscodesSettingsComponent } from './device-passcodes-settings/device-passcodes-settings.component';
import { EditDeviceDialogComponent } from './edit-device-dialog/edit-device-dialog.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LottieComponent } from 'ngx-lottie';

@NgModule({
  declarations: [
    DeviceInputsSettingsComponent,
    DeviceOutputsSettingsComponent,
    DevicePreferencesSettingsComponent,
    DevicePasscodesSettingsComponent,
    EditDeviceDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    LottieComponent,

    DxLoadIndicatorModule,
    DxButtonModule,
    DxTextBoxModule,
    DxPopupModule,
    DxSliderModule,
    DxRadioGroupModule,
    DxNumberBoxModule,
    DxSwitchModule,
    DxSelectBoxModule,
    DxDataGridModule,
    DxListModule
  ],
  exports: [
    DeviceInputsSettingsComponent,
    DeviceOutputsSettingsComponent,
    DevicePreferencesSettingsComponent,
    DevicePasscodesSettingsComponent
  ],
  providers: [
    { provide: DevicesManager, useClass: ApiDevicesManager }
  ]
})
export class DevicesModule { }
