<div class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">
  <div class="row-with-label full-width">
    <dx-text-box
      class="full-width"
      [value]="email"
      [label]="'General.Email' | i18next"
      labelMode="static"
      stylingMode="outlined"
      [placeholder]="'Invitations.EmailsPlaceholder' | i18next"
      [disabled]="true"
    >
    </dx-text-box>
  </div>

  <dx-tree-list
    [dataSource]="devices!"
    [showColumnHeaders]="false"
    [hoverStateEnabled]="true"
    [selectedRowKeys]="currentSelectedDevices"
    (onSelectionChanged)="onSelectionChanged($event)"
  >
    <dxi-column dataField="name"></dxi-column>
    <dxo-selection mode="multiple"></dxo-selection>
  </dx-tree-list>

  <div class="dialog-action-bar">
    <dx-button
      [text]="'General.Delete' | i18next"
      (onClick)="deleteUnitUser()"
      stylingMode="outlined"
      type="danger"
      style="margin-right: auto"
    >
    </dx-button>
    <dx-button
      [text]="'General.Cancel' | i18next"
      (onClick)="closeDialog()"
      stylingMode="text"
    >
    </dx-button>
    <dx-button
      [text]="'General.Save' | i18next"
      type="default"
      (onClick)="updatePermission()"
    >
    </dx-button>
  </div>
</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
  <app-spinner [message]="busyMessage"></app-spinner>
</div>
