import { Pipe, PipeTransform } from '@angular/core';
import { Invitation } from '../../shared/models/invitations/invitation';
import { User } from '../../shared/models/users/user';

@Pipe({ name: 'unitUserSubtitle' })
export class UnitUserSubtitlePipe implements PipeTransform {

  transform(value: User | Invitation): string {
    if (value.hasOwnProperty('expiration')) { // Invitation
      const invitation = value as Invitation;
      if (this.isExpired(invitation.expiration)) {
        return 'Invitations.InvitationExpired';
      } else {
        return 'Invitations.InvitationPending';
      }
    } else {
      return 'Invitations.InvitationAccepted';
    }
  }

  private isExpired(expirationDate: number): boolean {
    const currentTime = Date.now();
    return expirationDate * 1000 < currentTime;
  }

}
