import { Component, Input, OnInit } from '@angular/core';
import { BasePageComponent } from '../../shared/components/base-page.component';
import { LocalizerService, NavigationService, ScreenService } from '../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, finalize } from 'rxjs';
import { IntercomStatus } from '../models/iot/intercom_status';
import { IntercomsManager } from '../services/intercoms-manager';
import { Intercom } from '../models/intercom';
import { SessionService } from '../../shared/services/session.service';

@Component({
  selector: 'app-intercom-details',
  templateUrl: './intercom-details.component.html',
  styleUrl: './intercom-details.component.scss'
})
export class IntercomDetailsComponent extends BasePageComponent implements OnInit {

  @Input() installationId: string | null = null;
  @Input() unitId: string | null = null;
  @Input() intercomId: string | null = null;

  intercom: Intercom | null = null;
  intercom_status: IntercomStatus | null = null;
  form!: FormGroup;

  // utility variables
  saveDetailsButtonDisabled: boolean = false;
  isDeletingIntercom: boolean = false;
  isUpdatingIntercom: boolean = false;
  isPreferencesNotAvailable: boolean = false;

  constructor(private intercomManager: IntercomsManager,
    private navigation: NavigationService,
    private formBuilder: FormBuilder,
    private sessionService: SessionService,
    localizer: LocalizerService,
    screen: ScreenService) {
    super(screen, localizer);
  }

  ngOnInit() {
    if (!this.installationId) {
      this.displayLocalizedErrorNotification('Installations.InvalidInstallationId');
      return;
    }

    if (!this.unitId) {
      this.displayLocalizedErrorNotification('Units.InvalidUnitId');
      return;
    }

    if (!this.intercomId) {
      this.displayLocalizedErrorNotification('Devices.InvalidIntercomId');
      return;
    }

    this.getIntercom();
  }

  onFormValueChanged() {
    if (!this.intercom || !this.intercomId) return;

    const changes = this.form.value;

    this.saveDetailsButtonDisabled =
      changes.name !== this.intercom!.name ||
      changes.description !== this.intercom!.description;
  }

  updateIntercomDetails() {
    if (!this.intercom || !this.intercomId) return;

    this.isUpdatingIntercom = true;
    const formvalue = this.form.value;
    const changes = {
      "name": formvalue.name,
      "thing_name": this.intercom!.thing_name,
      "description": formvalue.description
    }

    this.intercomManager.updateIntercom(this.installationId!, this.unitId!, this.intercomId, changes)
      .pipe(finalize(() => this.isUpdatingIntercom = false))
      .subscribe({
        next: () => {
          this.displayLocalizedSuccessNotification('Devices.ApiUpdateIntercomSuccess');
          this.saveDetailsButtonDisabled = false;
          this.intercom!.name = formvalue.name;
          this.sessionService.updateIntercom(this.intercom!);
        },
        error: () => this.displayLocalizedErrorNotification('Devices.ApiUpdateIntercomError')
      });
  }

  deleteIntercom() {
    if (!this.intercom || !this.intercomId) return;

    this.isDeletingIntercom = true;
    this.intercomManager.deleteIntercom(this.installationId!, this.unitId!, this.intercomId)
      .pipe(finalize(() => this.isDeletingIntercom = false))
      .subscribe({
        next: () => {
          this.displayLocalizedSuccessNotification('Devices.ApiDeleteIntercomSuccess');
          this.sessionService.removeIntercom(this.intercomId!);
          this.navigation.navigateToUnitDetail(this.installationId!, this.unitId!);
        },
        error: () => this.displayLocalizedErrorNotification('Devices.ApiDeleteIntercomError')
      });
  }

  navigateToIntercomInputsSettings() {
    if (!this.intercomId) return;
    this.navigation.navigateToInputSettings(this.installationId!, this.unitId!, this.intercomId);
  }

  navigateToIntercomPreferencesSettings() {
    if (!this.intercomId) return;
    this.navigation.navigateToPreferencesSettings(this.installationId!, this.unitId!, this.intercomId);
  }

  // Private methods

  /**
   * This method gets the specified intercom.
   */
  private getIntercom() {
    this.isBusy = true;

    this.intercomManager.getIntercom(this.installationId!, this.unitId!, this.intercomId!).subscribe({
      next: intercom => {
        this.intercom = intercom;
        this.initForm();
        this.loadIntercomDetails();
      },
      error: () => this.displayLocalizedErrorNotification('Devices.ApiGetDeviceError')
    });
  }

  /**
   * Loads the intercom details.
   */
  private loadIntercomDetails() {
    this.intercomManager.getIntercomStatus(this.installationId!, this.unitId!, this.intercomId!)
      .pipe(finalize(() => this.isBusy = false))
      .subscribe({
        next: intercomStatus => {
          this.intercom_status = intercomStatus;
          if (!this.intercom_status.device.input) {
            this.isPreferencesNotAvailable = true;
          }
        },
        error: () => this.displayLocalizedErrorNotification('Devices.ApiGetIntercomDetailsError')
      });
  }

  /**
   * Initializes the form with the intercom details.
   */
  private initForm() {
    this.form = this.formBuilder.group({
      name: [this.intercom!.name, Validators.required],
      description: [this.intercom!.description],
    });
    this.subscribeToFormChanges();
  }

  /**
   * Subscribes to the form changes to enable the save button when the form is dirty.
   */
  private subscribeToFormChanges() {
    this.form.valueChanges.pipe(
      debounceTime(50)
    ).subscribe(() => {
      this.onFormValueChanged();
    });
  }

}
