import { Component } from '@angular/core';

@Component({
  selector: 'app-redirecting-page',
  templateUrl: './redirecting-page.component.html',
  styleUrl: './redirecting-page.component.scss'
})
export class RedirectingPageComponent {

}
