<div class="dialog-vertial-layout" [@openClose]="isBusy || askForDeletion ? 'closed' : 'open'">

    <div class="horizontal-flex-row full-width">
        <div></div>
        <dx-button [text]="'Units.DeleteUnit' | i18next" type="danger" stylingMode="outlined" (onClick)="deleteUnit()">
        </dx-button>
    </div>

    <dx-text-box [(value)]="unitName" [label]="'General.Name' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Units.NamePlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-text-box [(value)]="unitAlias" [label]="'Units.Alias' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Units.AliasPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>


    <dx-text-box [(value)]="unitLocation" [label]="'Units.Location' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Units.LocationPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-text-box [(value)]="unitNotes" [label]="'General.Notes' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Units.LocationPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-tree-list dataStructure="tree" [dataSource]="installationDevices" keyExpr="id" itemsExpr="children"
        [showColumnHeaders]="false" [hoverStateEnabled]="true" [autoExpandAll]="true"
        [(selectedRowKeys)]="unitDevicesAndOutputs" style="height: 100%;"
        (onSelectionChanged)="permissionSelectionChanged($event)">
        <dxi-column dataField="name"></dxi-column>
        <dxo-selection mode="multiple"></dxo-selection>
    </dx-tree-list>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="updateUnit()"
            [disabled]="!thereAreChanges">
        </dx-button>
    </div>

</div>

<!-- Delete unit content -->
<div class="dialog-vertial-layout" [@openClose]="askForDeletion && !isBusy ? 'open' : 'closed'">
    <svg-icon src="assets/icons/ic_warning.svg" class="warning-icon"></svg-icon>
    <div class="headline-small">{{"General.Attention" | i18next}}</div>
    <div>{{"Units.DeleteUnitMessage" | i18next}}</div>
    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Delete' | i18next" type="danger" (onClick)="completeUnitRemoval()">
        </dx-button>
    </div>
</div>


<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Units.EditWaitMessage'"></app-spinner>
</div>