import { Observable, from, map, of, switchMap } from "rxjs";
import { get, post, put, del } from 'aws-amplify/api';

export class BaseApiService {

    protected get<Type>(path: string): Observable<Type> {
        return from(get({
            apiName: 'server_api',
            path: path
        }).response).pipe(
            switchMap(response => from(response.body.text())),
            map(response => JSON.parse(response) as Type)
        );
    }

    protected post<T>(path: string, content: any | null = null): Observable<T> {
        let request;

        if (content) {
            request = {
                apiName: 'server_api',
                path: path,
                options: {
                    body: content
                }
            };

            return from(post(request).response).pipe(
                switchMap(response => from(response.body.text())),
                map(response => JSON.parse(response))
            );
        } else {
            request = {
                apiName: 'server_api',
                path: path
            };
            return from(post(request).response).pipe(
                map(response => (response.statusCode === 200) as T)
            );
        }
    }

    protected put(path: string, content: any | null = null): Observable<boolean> {
        return from(put({
            apiName: 'server_api',
            path: path,
            options: {
                body: content
            }
        }).response).pipe(
            map(response => response.statusCode === 200)
        );
    }

    protected putWithResponse<T>(path: string, content: any | null = null): Observable<T> {
        return from(put({
            apiName: 'server_api',
            path: path,
            options: {
                body: content
            }
        }).response).pipe(
            switchMap(response => from(response.body.text())),
            map(response => JSON.parse(response) as T)
        );
    }

    protected delete(path: string): Observable<boolean> {
        return from(del({
            apiName: 'server_api',
            path: path
        }).response).pipe(
            map(response => response.statusCode === 200)
        );
    }
}