import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { PreferencesSettingsComponent } from './preferences-settings/preferences-settings.component';
import { DxButtonModule, DxLoadIndicatorModule, DxPopupModule, DxSliderModule, DxTextBoxModule, DxTreeListModule } from 'devextreme-angular';
import { IntercomsManager } from './services/intercoms-manager';
import { ApiIntercomsManager } from './services/api-intercoms-manager';
import { InputsSettingsComponent } from './inputs-settings/inputs-settings.component';
import { IntercomDetailsComponent } from './intercom-details/intercom-details.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    IntercomDetailsComponent,
    PreferencesSettingsComponent,
    InputsSettingsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,

    DxLoadIndicatorModule,
    DxButtonModule,
    DxTreeListModule,
    DxTextBoxModule,
    DxPopupModule,
    DxSliderModule
  ],
  exports: [
    IntercomDetailsComponent,
    PreferencesSettingsComponent,
    InputsSettingsComponent
  ],
  providers: [
    { provide: IntercomsManager, useClass: ApiIntercomsManager }
  ]
})
export class IntercomsModule { }
