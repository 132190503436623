<div class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">

    <div class="label">{{'Invitations.InviteResponsibleMessage' | i18next}}</div>

    <dx-text-box [(value)]="email" [label]="'Auth.Email' | i18next" labelMode="static" stylingMode="outlined"
        valueChangeEvent="keyup" [showClearButton]="true" (valueChange)="valueChanged()"
        [placeholder]="'Invitations.EmailPlaceholder' | i18next" class="full-width">
    </dx-text-box>

    <dx-text-box [(value)]="notes" [label]="'General.Notes' | i18next" labelMode="static" stylingMode="outlined"
        valueChangeEvent="keyup" [showClearButton]="true" [placeholder]="'Invitations.NotesPlaceholder' | i18next"
        class="full-width">
    </dx-text-box>

    <div>
        <div>{{'Auth.Role' | i18next}} *</div>
        <dx-radio-group [(value)]="selectedRole" [items]="invitation_roles" displayExpr="title" valueExpr="value"
            class="radio-group" (valueChange)="valueChanged()">
            <div *dxTemplate="let data of 'item'">
                <div class="radio-item">
                    <div class="radio-item-title">{{ data.title | i18next }}</div>
                    <div class="radio-item-subtitle">{{ data.subtitle | i18next }}</div>
                </div>
            </div>
        </dx-radio-group>
    </div>

    <span class="label"> {{'General.RequiredFieldDescription' | i18next}}</span>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'Invitations.SendInvitation' | i18next" type="default" (onClick)="sendInvitation()"
            [disabled]="!sendInvitationButtonEnabled">
        </dx-button>
    </div>

</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Installations.InstallationEditWaitMessage'"></app-spinner>
</div>