import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DevicesManager } from '../services/devices-manager';
import { DeviceInput, OutputAction } from '../models/device_input';
import { finalize } from 'rxjs';
import { DeviceOutput } from '../models/device_output';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-device-inputs-settings',
  templateUrl: './device-inputs-settings.component.html',
  styleUrl: './device-inputs-settings.component.scss',
  animations: [openCloseAnimation]
})
export class DeviceInputsSettingsComponent implements OnInit {

  @Input() installationId: string | null = null;
  @Input() deviceId: string | null = null;
  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();

  notConfiguredDeviceInputs: DeviceInput[] = [];
  configuredDeviceInputs: DeviceInput[] = [];
  selectedDeviceInput: DeviceInput | null = null;
  selectedActions: OutputAction[] = [];
  deviceOutputs: DeviceOutput[] = [];

  // utility variables
  configureDeviceSettings: boolean = false;
  isBusy: boolean = false;
  isLoading: boolean = false;

  options: AnimationOptions = {
    path: '/assets/animations/loading.json',
    loop: true,
    autoplay: true
  };

  constructor(private devices: DevicesManager,
    private dialogs: DialogsService) { }

  ngOnInit() {
    if (!this.installationId || !this.deviceId) {
      return;
    }

    this.loadDeviceInputs();
  }

  /**
   * This method is called to add the selected device input.
   */
  addDeviceInput() {
    if (!this.selectedDeviceInput) {
      return;
    }

    this.isLoading = true;
    this.devices.addDeviceInput(this.installationId!, this.deviceId!, this.selectedDeviceInput, this.selectedActions)
      .subscribe({
        next: _ => this.apiSuccessAction(),
        error: _ => {
          this.isLoading = false;
          this.dialogs.displayErrorNotification('Inputs.ApiAddIntercomInputActionError');
        }
      });
  }

  /**
   * This method is called to delete the selected device input.
   */
  deleteDeviceInput() {
    if (!this.selectedDeviceInput) {
      return;
    }

    this.isLoading = true;
    this.devices.deleteDeviceInput(this.installationId!, this.deviceId!, this.selectedDeviceInput.id!)
      .subscribe({
        next: _ => this.apiSuccessAction(),
        error: _ => {
          this.isLoading = false;
          this.dialogs.displayErrorNotification('Inputs.ApiDeleteIntercomInputActionError');
        }
      });
  }

  /**
   * This method is called to update the selected device input.
   */
  onSelectionChanged(event: any) {
    const currentActionsSelected: OutputAction[] = [];

    event.selectedRowKeys.forEach((address: string) => {
      const existingAction = this.selectedActions.find(action => action.address === address);
      currentActionsSelected.push({
        address,
        action: existingAction ? existingAction.action : 'toggle'
      });
    });

    this.selectedActions = currentActionsSelected;
  }

  // Utiliy methods

  /**
   * This method is called to get the selected row keys.
   * @returns The selected row keys.
   */
  getSelectedRowKeys() {
    return this.selectedActions.map(action => action.address);
  }

  toggleConfigureDeviceSettings() {
    this.configureDeviceSettings = !this.configureDeviceSettings;
  }

  /**
   * Displays the popup for configuring actions associated with the selected device input.
   * @param event The event object that contains the selected device input.
   */
  showInputActionPopup(event: any) {
    this.toggleConfigureDeviceSettings();
    this.getDeviceOutputs();
    this.selectedDeviceInput = event.itemData || null;
    if (this.selectedDeviceInput) {
      (this.selectedDeviceInput.action?.outputs || []).forEach((output: OutputAction) => {
        this.selectedActions.push({ address: output.address, action: output.action });
      });
    }
  }

  /**
   * This method is called to navigate back to the previous page.
   */
  back() {
    if (this.configureDeviceSettings) {
      this.selectedActions = [];
      this.toggleConfigureDeviceSettings();
      return;
    }
    this.backEvent.emit();
  }

  /**
   * Utility method used to format strings where the first letter needs to be capitalized.
   * @param value The input string to be capitalized.
   * @returns The input string to be capitalized.
   */
  capitalizeFirstLetter(value: string | undefined) {
    if (!value) return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  // Private methods

  /**
   * Fetches the list of device inputs from the API.
   */
  private loadDeviceInputs() {
    this.isBusy = true;
    this.devices.getDeviceInputs(this.installationId!, this.deviceId!)
      .pipe(finalize(() => {
        this.isLoading = false;
        this.isBusy = false;
      }))
      .subscribe({
        next: deviceInputs => {
          this.configuredDeviceInputs = deviceInputs.filter(input => input.action);
          this.notConfiguredDeviceInputs = deviceInputs.filter(input => !input.action);
        },
        error: _ => this.dialogs.displayErrorNotification('Devices.ApiGetIntercomInputsError')
      });
  }

  /**
   * Fetches the list of device outputs from the API.
   */
  private getDeviceOutputs() {
    this.isBusy = true;
    this.devices.getDeviceOutputs(this.installationId!, this.deviceId!)
      .pipe(finalize(() => this.isBusy = false))
      .subscribe({
        next: deviceOutputs => this.deviceOutputs = deviceOutputs.filter(({ mode, status }) => mode && (status === undefined || status === true)),
        error: _ => this.dialogs.displayErrorNotification('Devices.ApiGetDeviceOutputsError')
      });
  }

  /**
   * Handles the actions to be performed after a successful API operation.
   */
  private apiSuccessAction() {
    this.configuredDeviceInputs = [];
    this.notConfiguredDeviceInputs = [];
    this.back();
    this.loadDeviceInputs();
  }

}
