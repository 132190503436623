import { Component, OnDestroy, OnInit } from "@angular/core";
import { AuthenticationManager } from "./authentication";
import { combineLatest, map, Observable, Subscription } from "rxjs";
import { NetworkService } from "./shared/services/network.service";
import { LicensesManagerService } from "./licenses/licenses-manager.service";
import { Store } from "@ngrx/store";
import { UserActions } from "./state/user/user.actions";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  private _userLoggedInSubscription: Subscription | null = null;

  userLoggedIn$: Observable<boolean> = this.auth.userLoggedInUpdates();
  missingInternetConnection$: Observable<boolean> = combineLatest([
    this.userLoggedIn$,
    this.networkService.getNetworkStatus(),
  ]).pipe(map(([loggedIn, connected]) => !connected && loggedIn));

  sidebarOpened = false;

  constructor(
    private auth: AuthenticationManager,
    private networkService: NetworkService,
    private licenses: LicensesManagerService,
    private store: Store
  ) {}

  ngOnInit() {
    this.auth
      .userLoggedIn()
      .subscribe((loggedIn) => this.updateUIForUser(loggedIn));

    this._userLoggedInSubscription = this.userLoggedIn$.subscribe((loggedIn) =>
      this.updateUIForUser(loggedIn)
    );

    this.retrieveUserLicenses();
  }

  ngOnDestroy(): void {
    this._userLoggedInSubscription?.unsubscribe();
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  private updateUIForUser(logged: boolean) {
    if (!logged) {
      this.sidebarOpened = false;
    }
  }

  private retrieveUserLicenses() {
    // We retrieve user licenses in background
    this.store.dispatch(UserActions.startRetrieving());
    this.licenses.getUserLicenses().subscribe({
      next: (licenses) =>
        this.store.dispatch(UserActions.saveLicenses({ licenses: licenses })),
      error: (_) => this.store.dispatch(UserActions.errorRetrieving()),
    });
  }
}
