import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationManager } from './authentication';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import { NetworkService } from './shared/services/network.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private _userLoggedInSubscription: Subscription | null = null;

  userLoggedIn$: Observable<boolean> = this.auth.userLoggedInUpdates();
  missingInternetConnection$: Observable<boolean> = combineLatest([this.userLoggedIn$, this.networkService.getNetworkStatus()]).pipe(
    map(([loggedIn, connected]) => !connected && loggedIn)
  );

  sidebarOpened = false;

  constructor(private auth: AuthenticationManager,
    private networkService: NetworkService) { }

  ngOnInit() {
    this._userLoggedInSubscription = this.userLoggedIn$.subscribe((loggedIn) => {
      if (!loggedIn) {
        this.sidebarOpened = false;
      }
    });
  }

  ngOnDestroy(): void {
    this._userLoggedInSubscription?.unsubscribe();
  }

  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

}
