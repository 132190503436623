import { Observable } from "rxjs";
import { Installation } from "../../shared/models/installations/installation";
import { BaseApiService } from "../../shared/utils/base-api-service";
import { User } from "../../shared/models/users/user";
import { SignedUploadUrl } from "../models/signed_upload_url";
import { CreateInstallationDto } from "../dto/create-installation-dto";

export abstract class InstallationsManager extends BaseApiService {

    /**
     * Retrieves all installations.
     * @returns a list of installations.
     */
    abstract getInstallations(): Observable<Installation[]>;

    /**
     * Retrieves a specific installation.
     * @param installationId the installation id
     * @param forceDownload true to force the retrieval from the server, false otherwise
     * @returns the installation.
     */
    abstract getInstallation(installationId: string): Observable<Installation>;

    /**
     * Retrieves all users associated with the installation.
     * @param installationId the installation id
     * @returns a list of users.
     */
    abstract getInstallationUsers(installationId: string): Observable<User[]>;

    /**
     * Updates the installation.
     * @param installationId the installation id to update
     * @param content the content to update
     * @returns true if the installation was updated successfully, false otherwise.
     */
    abstract updateInstallation(installationId: string, content: any): Observable<boolean>

    /**
     * Creates a new installation.
     * @param content the content to create the installation
     * @returns The created installation.
     */
    abstract createInstallation(content: CreateInstallationDto): Observable<Installation>

    /**
     * Deletes the installation.
     * @param installationId the installation id to delete
     * @returns true if the installation was deleted successfully, false otherwise.
     */
    abstract deleteInstallation(installationId: string): Observable<boolean>;

    /**
     * Disassociates the installation.
     * @param installationId the installation id to disassociate
     * @returns true if the installation was disassociated successfully, false otherwise.
     */
    abstract disassociateInstallation(installationId: string): Observable<boolean>;

    /**
     * Retrieves the url to upload installation image.
     * @param installationId the installation id
     * @returns the url to upload installation image.
     */
    abstract getInstallationImageUploadUrl(installationId: string): Observable<SignedUploadUrl>;

    /**
     * Retrieves the list of supported languages.
     * @returns a list of dictionary with name and ISO code.
     */
    abstract supportedLanguages(): any[];

    /**
     * Retrieves the list of supported event expirations.
     * @returns a list of dictionary with value and label
     * */
    abstract eventsExpirations(): any[];
}