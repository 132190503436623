import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  LocalizerService,
  NavigationService,
  profileColorPalette,
  ScreenService,
} from "../../shared";
import { finalize, forkJoin, Subscription } from "rxjs";

import { Installation } from "../../shared/models/installations/installation";
import { Unit } from "../../shared/models/units/unit";
import { ManagerInvitation } from "../../shared/models/invitations/manager-invitation";
import { User } from "../../shared/models/users/user";
import { FarfisaDevice } from "../../shared/models/devices/farfisa_device";

import { BasePageComponent } from "../../shared/components/base-page.component";
import { InstallationsManager } from "../services/installations-manager";
import { InvitationsManager } from "../../invitations/services/invitations-manager";
import { BreadcrumbItem } from "../../shared/utils/breadcrumb-item";
import { environment } from "../../../environments/environment";
import { MapMarker } from "../../shared/models/maps/map-marker";
import { InstallationUserViewModel } from "../models/installation_user";
import { AccessLevel } from "../../shared/models/users/access-level";
import { SidebarService } from "../../application-layout/services/sidebar.service";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { EditInstallationDialogComponent } from "../edit-installation-dialog/edit-installation-dialog.component";
import { EditDeviceDialogComponent } from "../../devices/edit-device-dialog/edit-device-dialog.component";
import { ImportUnitsDialogComponent } from "../import-units-dialog/import-units-dialog.component";
import { EditLocationDialogComponent } from "../edit-location-dialog/edit-location-dialog.component";
import { CreateManagerInvitationDialogComponent } from "../../invitations/create-manager-invitation-dialog/create-manager-invitation-dialog.component";
import { CreateUnitDialogComponent } from "../../units/create-unit-detail/create-unit-dialog.component";
import { LicensesManagerService } from "../../licenses/licenses-manager.service";
import { InstallationLicensesDto } from "../../licenses/dto/installation-licenses-dto";
import { CreateLicenseDialogComponent } from "../../licenses/create-license-dialog/create-license-dialog.component";

@Component({
  selector: "app-installation",
  templateUrl: "./installation.component.html",
  styleUrl: "./installation.component.scss",
})
export class InstallationComponent
  extends BasePageComponent
  implements OnInit, OnDestroy
{
  private _dialogResultSubscription?: Subscription;
  private _installationDevicesClickSubscription?: Subscription;
  private _createManagerInvitationClickSubscription?: Subscription;
  private _installationDisassociateClickSubscription?: Subscription;
  private _licensesSideMenuItemSubscription?: Subscription;

  private _invitations: ManagerInvitation[] = [];
  private _users: User[] = [];

  @Input() installationId: string | null = null;

  installation?: Installation;
  installationUnits: Unit[] = [];

  displayDevicesCard = false;
  installationDevices: FarfisaDevice[] = [];

  displayLicensesCard = true;
  installationLicenses: InstallationLicensesDto[] = [];

  installationUsers: InstallationUserViewModel[] = [];
  selectedUser: User | null = null;
  selectedInvitation: ManagerInvitation | null = null;

  // Utility variables
  hidePassword = true;
  showInvitationDetailsPopupVisible = false;
  showUserDetailsPopupVisible = false;

  breadcrumbItems: BreadcrumbItem[] = [
    { label: "General.Installations", url: "/installations" },
  ];

  searchButtonOptions = {
    icon: "search",
    stylingMode: "text",
  };

  googleMapsApiKey = environment.mapAPIKey;
  markers: MapMarker[] = [];

  constructor(
    private installationsManager: InstallationsManager,
    private invitationsManager: InvitationsManager,
    private navigation: NavigationService,
    private sidebar: SidebarService,
    private dialogs: DialogsService,
    private licenses: LicensesManagerService,
    localizer: LocalizerService,
    screen: ScreenService
  ) {
    super(screen, localizer);
  }

  ngOnInit() {
    this.retrieveInstallation();
    this.retrieveInstallationUsers();
    this.retrieveInstallationLicenses();
    this.listenSidebarEvents();
  }

  ngOnDestroy(): void {
    this._installationDevicesClickSubscription?.unsubscribe();
    this._createManagerInvitationClickSubscription?.unsubscribe();
    this._installationDisassociateClickSubscription?.unsubscribe();
    this._dialogResultSubscription?.unsubscribe();
    this._licensesSideMenuItemSubscription?.unsubscribe();
  }

  disassociateInstallation() {
    const data = {
      installation: this.installation,
      askForDisassociation: true,
    };
    this._dialogResultSubscription?.unsubscribe();
    this._dialogResultSubscription = this.dialogs
      .showDialog(EditInstallationDialogComponent, data)
      .subscribe((response) => this.applyInstallationDetailsChanges(response));
  }

  navigateToUnitDetails(event: any) {
    const unit = event.data as Unit;
    this.navigation.navigateToUnitDetail(this.installation!.id, unit.id);
  }

  editInstallationDetails() {
    const configuration = {
      title: "Installations.CustomizeInstallation",
      icon: "assets/icons/ic_star.svg",
    };
    const data = {
      installation: this.installation,
      askForDisassociation: false,
    };
    this._dialogResultSubscription?.unsubscribe();
    this._dialogResultSubscription = this.dialogs
      .showDialog(EditInstallationDialogComponent, data, configuration)
      .subscribe((response) => this.applyInstallationDetailsChanges(response));
  }

  editInstallationLocation() {
    const configuration = {
      title: "Installations.EditLocationTitle",
      icon: "assets/icons/ic_map.svg",
    };
    this._dialogResultSubscription?.unsubscribe();
    this._dialogResultSubscription = this.dialogs
      .showDialog(EditLocationDialogComponent, this.installation, configuration)
      .subscribe((_) => this.retrieveInstallation());
  }

  addLicense() {
    const configuration = {
      title: "Installations.AddLicense",
      icon: "assets/icons/ic_license.svg",
    };
    this._dialogResultSubscription?.unsubscribe();
    this._dialogResultSubscription = this.dialogs
      .showDialog(CreateLicenseDialogComponent, this.installation, configuration)
      .subscribe((_) => this.retrieveInstallationLicenses());
  }

  createUnit() {
    const configuration = {
      title: "Units.NewUnit",
      icon: "assets/icons/ic_home.svg",
    };
    this._dialogResultSubscription?.unsubscribe();
    this._dialogResultSubscription = this.dialogs
      .showDialog(CreateUnitDialogComponent, this.installation, configuration)
      .subscribe((_) => this.retrieveInstallation());
  }

  createManagerInvitation() {
    const configuration = {
      title: "Invitations.InviteResponsible",
      icon: "assets/icons/ic_ticket.svg",
    };
    this._dialogResultSubscription?.unsubscribe();
    this._dialogResultSubscription = this.dialogs
      .showDialog(
        CreateManagerInvitationDialogComponent,
        this.installation,
        configuration
      )
      .subscribe((_) => this.retrieveInstallationUsers(true));
  }

  private applyInstallationDetailsChanges(changes: any) {
    if (!this.installation) {
      return;
    }

    Object.keys(changes).forEach((key) => {
      if (key in this.installation!) {
        (this.installation as any)[key] = changes[key];
      }
    });
  }

  editDeviceDetails(device: FarfisaDevice) {
    const configuration = {
      title: "Devices.DeviceSettings",
      icon: "assets/icons/ic_settings.svg",
    };
    const data = { device: device, installationId: this.installationId };
    this._dialogResultSubscription?.unsubscribe();
    this._dialogResultSubscription = this.dialogs
      .showDialog(EditDeviceDialogComponent, data, configuration)
      .subscribe((response) =>
        this.applyDeviceDetailsChanges(response, device.id)
      );
  }

  private applyDeviceDetailsChanges(changes: any, deviceId: string) {
    if (!this.installationDevices) {
      return;
    }

    const deviceToUpdate = this.installationDevices.find(
      (device) => device.id === deviceId
    );
    if (deviceToUpdate) {
      Object.keys(changes).forEach((key) => {
        if (key in deviceToUpdate) {
          (deviceToUpdate as any)[key] = changes[key];
        }
      });
    }
  }

  showUserDetails(event: any) {
    const email = event.data.email;
    const invitationCode = event.data.invitationCode;
    if (invitationCode) {
      this.selectedInvitation =
        this._invitations.find((i) => i.code === invitationCode) || null;
      this.showInvitationDetailsPopupVisible = true;
    } else {
      this.selectedUser = this._users.find((u) => u.email === email) || null;
      this.showUserDetailsPopupVisible = true;
    }
  }

  showImportUnitsPopup() {
    const configuration = {
      title: "ImportUnits.ImportFromFile",
      icon: "assets/icons/ic_import.svg",
    };
    this._dialogResultSubscription?.unsubscribe();
    this._dialogResultSubscription = this.dialogs
      .showDialog(ImportUnitsDialogComponent, this.installation, configuration)
      .subscribe({
        next: () => this.retrieveInstallation(),
      });
  }

  aliasSortingMethod(a: string, b: string){
    return Number.parseInt(a) - Number.parseInt(b);
  }

  // Private methods

  /**
   * This method loads the installation.
   */
  private retrieveInstallation(silently = false) {
    if (!this.installationId) {
      this.displayLocalizedErrorNotification(
        "Installations.InvalidInstallationId"
      );
      return;
    }

    if (!silently) {
      this.isBusy = true;
    }

    this.installationsManager
      .getInstallation(this.installationId, true)
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (installation) => this.updateInstallationData(installation),
        error: () =>
          this.displayLocalizedErrorNotification(
            "Installations.ApiDetailError"
          ),
      });
  }

  private updateInstallationData(installation: Installation) {
    this.installation = installation;
    this.installationDevices = installation?.devices || [];
    const units = installation?.units || [];
    this.installationUnits = units.sort((a, b) => this.aliasSortingMethod(a.alias!, b.alias!));
    this.markers = installation?.address
      ? [
          {
            location:
              installation.address.latitude +
              "," +
              installation.address.longitude,
            tooltip: installation.name,
          },
        ]
      : [];

    if (this.breadcrumbItems.length > 1) {
      this.breadcrumbItems.pop();
    }

    this.breadcrumbItems.push({ label: installation?.name || "-" });
  }

  private retrieveInstallationLicenses() {
    if (!this.installationId) {
      return;
    }

    this.licenses.getInstallationLicenses(this.installationId).subscribe({
      next: (license) => (this.installationLicenses = [license]),
    });
  }

  /**
   * Retrieve installation managers and installers and invitations, all together.
   */
  private retrieveInstallationUsers(silently = false) {
    if (!silently) {
      this.isBusy = true;
    }

    forkJoin({
      managers: this.installationsManager.getInstallationUsers(
        this.installationId!
      ),
      invitations: this.invitationsManager.getManagerInvitations(
        this.installationId!
      ),
    })
      .pipe(finalize(() => (this.isBusy = false)))
      .subscribe({
        next: (data) =>
          this.populateInstallationUsers(data.managers, data.invitations),
        error: () =>
          this.displayLocalizedErrorNotification(
            "Installations.ApiManagerInvitationsError"
          ),
      });
  }

  private populateInstallationUsers(
    users: User[],
    invitations: ManagerInvitation[]
  ) {
    this._users = users;
    this._invitations = invitations;
    const result: InstallationUserViewModel[] = [];
    users.forEach((user, index) => {
      const permissionLocalizationKey =
        user.access_level == AccessLevel.INSTALLER ||
        user.access_level == AccessLevel.INSTALLER_USER
          ? "General.Installer"
          : "General.Manager";
      result.push({
        email: user.email,
        color: profileColorPalette[index % profileColorPalette.length],
        textColor: "#000000",
        permissions: this.localizer.getLocalizedString(
          permissionLocalizationKey
        ),
      });
    });

    invitations.forEach((invitation, index) => {
      result.push({
        email: invitation.email,
        color: profileColorPalette[index % profileColorPalette.length],
        textColor: "#000000",
        permissions: this.localizer.getLocalizedString(
          "Invitations.InvitationPending"
        ),
        invitationCode: invitation.code,
      });
    });

    this.installationUsers = result;
  }

  private listenSidebarEvents() {
    this._createManagerInvitationClickSubscription = this.sidebar
      .onCreateManagerInvitationClick()
      .subscribe((_) => this.createManagerInvitation());

    this._installationDisassociateClickSubscription = this.sidebar
      .onInstallationDisassociateClick()
      .subscribe((_) => this.disassociateInstallation());

    this._installationDevicesClickSubscription = this.sidebar
      .onInstallationDevicesClick()
      .subscribe((value) => {
        this.displayLicensesCard = false;
        this.displayDevicesCard = value;
      });

    this._licensesSideMenuItemSubscription = this.sidebar
      .onUserLicensesClick()
      .subscribe((value) => {
        this.displayLicensesCard = value;
        this.displayDevicesCard = false;
      });
  }
}
