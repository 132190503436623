import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-card',
  templateUrl: './auth-card.component.html',
  styleUrl: './auth-card.component.scss'
})
export class AuthCardComponent {

  evoFeaturesIllustrations = [
    "assets/illustrations/ic_features_performances.svg",
    "assets/illustrations/ic_features_privacy.svg",
    "assets/illustrations/ic_features_smart_access.svg",
    "assets/illustrations/ic_features_alexa.svg",
    "assets/illustrations/ic_features_flexibility.svg"
  ];

}
