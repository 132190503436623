import { Pipe, PipeTransform } from '@angular/core';
import { ProductLine } from '../models/devices/product-line';

@Pipe({ name: 'productLineName' })
export class ProductLineNamePipe implements PipeTransform {

    transform(value: ProductLine): string {
        switch (value) {
            case ProductLine.CONNECTO:
                return 'Connecto';
            case ProductLine.ALBA:
                return 'Alba';
            case ProductLine.ASTRO:
                return 'Astro';
            default:
                return 'Unknown';
        }
    }
}

@Pipe({ name: 'productTypeName' })
export class ProductTypeNamePipe implements PipeTransform {

    transform(value: ProductLine): string {
        switch (value) {
            case ProductLine.CONNECTO:
            case ProductLine.ALBA:
            case ProductLine.ASTRO:
                return 'Farfisa';
            default:
                return 'Others';
        }
    }
}
