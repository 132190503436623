import { Injectable } from "@angular/core";
import { UnitsManager } from "./units-manager";
import { catchError, map, Observable, of, share, tap, throwError } from "rxjs";
import { Unit } from "../../shared/models/units/unit";
import { User } from "../../shared/models/users/user";
import { UnitImportedViewModel } from "../../installations/view-models/unit_imported_view_model";

@Injectable()
export class ApiUnitsManager extends UnitsManager {
  private _unitRetrieval$: Observable<Unit> | null = null;
  private _unitUsersRetrieval$: Observable<User[]> | null = null;

  getUnitDetails(installation_id: string, unit_id: string): Observable<Unit> {
    if (!installation_id) {
      return throwError(() => new Error("Invalid installation id"));
    }

    if (!unit_id) {
      return throwError(() => new Error("Invalid unit id"));
    }

    if (!this._unitRetrieval$) {
      this._unitRetrieval$ = this.get<Unit>(
        `/installations/${installation_id}/units/${unit_id}`
      ).pipe(
        share(),
        tap(() => (this._unitRetrieval$ = null))
      );
    }

    return this._unitRetrieval$;
  }

  getUnitUsers(installation_id: string, unit_id: string): Observable<User[]> {
    if (!installation_id) {
      return throwError(() => new Error("Invalid installation id"));
    }

    if (!unit_id) {
      return throwError(() => new Error("Invalid unit id"));
    }

    if (!this._unitUsersRetrieval$) {
      this._unitUsersRetrieval$ = this.get<User[]>(
        `/installations/${installation_id}/units/${unit_id}/users`
      ).pipe(
        share(),
        tap(() => (this._unitUsersRetrieval$ = null))
      );
    }

    return this._unitUsersRetrieval$;
  }

  deleteUnitUser(
    installation_id: string,
    unit_id: string,
    user_id: string
  ): Observable<boolean> {
    if (!installation_id) {
      return throwError(() => new Error("Invalid installation id"));
    }

    if (!unit_id) {
      return throwError(() => new Error("Invalid unit id"));
    }

    if (!user_id) {
      return throwError(() => new Error("Invalid user id"));
    }

    return this.delete(
      `/installations/${installation_id}/units/${unit_id}/users/${user_id}`
    );
  }

  createUnit(installation_id: string, content: any): Observable<Unit> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    return this.post<Unit>(`/installations/${installation_id}/units`, content);
  }

  deleteUnit(installation_id: string, unit_id: string): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!unit_id) return throwError(() => new Error("Invalid unit id"));

    return this.delete(`/installations/${installation_id}/units/${unit_id}`);
  }

  updateUnit(
    installation_id: string,
    unit_id: string,
    content: any
  ): Observable<Unit> {
    if (!installation_id) {
      return throwError(() => new Error("Invalid installation id"));
    }

    if (!unit_id) {
      return throwError(() => new Error("Invalid unit id"));
    }

    return this.putWithResponse<Unit>(
      `/installations/${installation_id}/units/${unit_id}`,
      content
    );
  }

  updateUnitUserDevicesPermissions(
    installation_id: string,
    unit_id: string,
    user_id: string,
    content: any
  ): Observable<boolean> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    if (!unit_id) return throwError(() => new Error("Invalid unit id"));

    if (!user_id) return throwError(() => new Error("Invalid user id"));

    return this.post(
      `/installations/${installation_id}/units/${unit_id}/users/${user_id}/permissions`,
      content
    ).pipe(
      map((response) => {
        return response === true;
      }),
      catchError((_) => {
        return of(false);
      })
    );
  }

  importUnits(
    installation_id: string,
    csv: string
  ): Observable<UnitImportedViewModel[]> {
    if (!installation_id)
      return throwError(() => new Error("Invalid installation id"));

    const units_to_import = { imports: csv };

    return this.post<UnitImportedViewModel[]>(
      `/installations/${installation_id}/units/import`,
      units_to_import
    );
  }
}
