import { Injectable } from "@angular/core";
import { InstallationsManager } from "./installations-manager";
import { Installation } from "../../shared/models/installations/installation";
import { Observable, of, share, tap, throwError } from "rxjs";
import { User } from "../../shared/models/users/user";
import { SignedUploadUrl } from "../models/signed_upload_url";
import { SessionService } from "../../shared/services/session.service";
import { LocalizerService } from "../../shared";
import { CreateInstallationDto } from "../dto/create-installation-dto";

@Injectable()
export class ApiInstallationsManager extends InstallationsManager {

    private _installationRetrieval$: Observable<Installation> | null = null;
    private _installationUsersRetrieval$: Observable<User[]> | null = null;

    constructor(private session: SessionService, private localizer: LocalizerService) { super(); }

    getInstallations(): Observable<Installation[]> {
        return this.get<Installation[]>('/installations');
    }

    getInstallation(installationId: string, forceDownload: boolean = false): Observable<Installation> {
        if (!installationId) {
            return throwError(() => new Error('Invalid installation id'));
        }

        if (!forceDownload) {
            const installation = this.session.getInstallation(installationId);
            if (installation) {
                return of(installation);
            }
        }

        if (!this._installationRetrieval$) {
            this._installationRetrieval$ = this.get<Installation>(`/installations/${installationId}`).pipe(
                share(),
                tap(installation => this.cacheInstallation(installation)),
                tap(() => this._installationRetrieval$ = null)
            );
        }

        return this._installationRetrieval$;
    }

    getInstallationUsers(installationId: string): Observable<User[]> {
        if (!installationId) {
            return throwError(() => new Error('Invalid installation id'));
        }

        if (!this._installationUsersRetrieval$) {
            // We get only managers and installers
            const queryParams = `?access_level=0&access_level=1&access_level=4&access_level=5`;
            this._installationUsersRetrieval$ = this.get<User[]>(`/installations/${installationId}/users${queryParams}`).pipe(
                share(),
                tap(() => this._installationUsersRetrieval$ = null)
            );
        }

        return this._installationUsersRetrieval$;
    }

    updateInstallation(installationId: string, content: any): Observable<boolean> {
        if (!installationId) {
            return throwError(() => new Error('Invalid installation id'));
        }

        return this.put(`/installations/${installationId}`, content);
    }

    createInstallation(content: CreateInstallationDto): Observable<Installation> {
        return this.post('/installations', content);
    }

    deleteInstallation(installationId: string): Observable<boolean> {
        if (!installationId) {
            return throwError(() => new Error('Invalid installation id'));
        }

        return this.delete(`/installations/${installationId}`);
    }

    disassociateInstallation(installationId: string): Observable<boolean> {
        if (!installationId) {
            return throwError(() => new Error('Invalid installation id'));
        }

        return this.delete(`/installations/${installationId}/disassociate`);
    }

    getInstallationImageUploadUrl(installationId: string): Observable<SignedUploadUrl> {
        if (!installationId) {
            return throwError(() => new Error('Invalid installation id'));
        }

        return this.get<SignedUploadUrl>(`/installations/${installationId}/image_upload_url`);
    }

    supportedLanguages(): any[] {
        return [
            { name: this.localizer.getLocalizedString('Languages.English'), code: 'en' },
            { name: this.localizer.getLocalizedString('Languages.Italian'), code: 'it' },
            { name: this.localizer.getLocalizedString('Languages.French'), code: 'fr' },
            { name: this.localizer.getLocalizedString('Languages.Spanish'), code: 'es' },
            { name: this.localizer.getLocalizedString('Languages.German'), code: 'de' },
            { name: this.localizer.getLocalizedString('Languages.Polish'), code: 'pl' },
            { name: this.localizer.getLocalizedString('Languages.Czech'), code: 'cs' }
        ];
    }

    eventsExpirations(): any[] {
        return [
            { value: 365, label: '365'.concat(' ', this.localizer.getLocalizedString('General.Days')) },
            { value: 90, label: '90'.concat(' ', this.localizer.getLocalizedString('General.Days')) },
            { value: 30, label: '30'.concat(' ', this.localizer.getLocalizedString('General.Days')) },
            { value: 1, label: '1'.concat(' ', this.localizer.getLocalizedString('General.Days')) },
            { value: -1, label: this.localizer.getLocalizedString('General.Never') }
        ];
    }

    private cacheInstallation(installation: Installation) {
        if (installation) {
            this.session.addInstallation(installation);
            this.session.setDevices(installation.devices || []);
        }
    }
}