<dx-popup *ngIf="invitation" [width]="520" [height]="580" [showTitle]="false" [dragEnabled]="false"
    container=".dx-viewport" [(visible)]="isVisible" (onHiding)="closePopup()">

    <div class="popup-content">
        <div class="popup-title">{{"General.Responsible" | i18next}}</div>
        <i class="dx-icon-user"></i>
        <div class="email">{{invitation!.email}}</div>
        <div *ngIf="invitation?.expiration">
            <div *ngIf="isExpired(invitation.expiration)" class="expired">
                {{ 'Invitations.InvitationExpired' | i18next }}
            </div>
            <div *ngIf="!isExpired(invitation.expiration)" class="pending">
                {{ 'Invitations.InvitationPending' | i18next }}
            </div>
        </div>
        <div class="expiration" *ngIf="invitation?.expiration">{{"Invitations.InvitationExpiration" | i18next}}:
            {{invitation!.expiration * 1000 | date: 'longDate'}}</div>

        <div class="text-box-title">{{'General.Notes' | i18next}} ({{'General.Optional' | i18next}})</div>
        <dx-text-box class="note-text-box"
            [value]="invitation!.note ? invitation!.note! : 'Invitations.EmptyNote' | i18next" valueChangeEvent="keyup"
            [disabled]="true">
        </dx-text-box>

        <div class="text-box-title">{{'Auth.Role' | i18next}}</div>
        <dx-radio-group [value]="invitation!.access" [items]="invitation_roles" displayExpr="title" valueExpr="value"
            class="radio-group" [disabled]="true">
            <div *dxTemplate="let data of 'item'">
                <div class="radio-item">
                    <div class="radio-item-title">{{ data.title }}</div>
                    <div class="radio-item-subtitle">{{ data.subtitle }}</div>
                </div>
            </div>
        </dx-radio-group>

        <div class="popup-button">
            <dx-button class="cancel-button animated" [text]="'General.Close' | i18next" (onClick)="closePopup()">
            </dx-button>
            <dx-button type="default" class="delete-button animated" (onClick)="deleteInvitation()" [disabled]="isLoading">
                <div *dxTemplate="let data of 'content'" style="display: flex;">
                    <dx-load-indicator class="button-indicator" height="22" width="22" [visible]="isLoading">
                    </dx-load-indicator>
                    <span class="dx-button-text" style="padding: 8px;">{{'Invitations.DeleteInvitation' |
                        i18next}}</span>
                </div>
            </dx-button>
        </div>
    </div>
</dx-popup>