<div class="container">

    <div *ngIf="isBusy" class="loading">
        <dx-load-indicator></dx-load-indicator>
        <div>{{'General.RetrievingData' | i18next}}</div>
    </div>

    <div class="content" *ngIf="!isBusy">

        <div class="title">
            {{intercom?.name}}
        </div>

        <form [formGroup]="form" class="form">

            <div class="text-box-title">{{'General.Name' | i18next}}</div>
            <dx-text-box formControlName="name" (onValueChanged)="onFormValueChanged()" valueChangeEvent="keyup">
            </dx-text-box>

            <div class="text-box-title">{{'General.Description' | i18next}} ({{'General.Optional' | i18next}})</div>
            <dx-text-box class="note-text-box" formControlName="description" (onValueChanged)="onFormValueChanged()"
                labelMode="floating" valueChangeEvent="keyup">
            </dx-text-box>

        </form>

        <div class="box">

            <div class="title">{{'General.Inputs' | i18next}}</div>
            <dx-button type="default" class="button animated" (onClick)="navigateToIntercomInputsSettings()">
                <div *dxTemplate="let data of 'content'" style="display: flex;">
                    <div class="button-container">
                        <div class="text-button-container">
                            <span class="text-button">{{'General.Inputs' | i18next}}</span>
                            <span class="sub-text-button">{{'Devices.InputsMessage' | i18next}}</span>
                        </div>
                        <i class="dx-icon-chevronright"></i>
                    </div>
                </div>
            </dx-button>

            <div class="title top-space">{{'General.Preferences' | i18next}}</div>
            <dx-button type="default" class="button animated" (onClick)="navigateToIntercomPreferencesSettings()"
                [disabled]="isPreferencesNotAvailable" [ngClass]="{'disabled-overlay': isPreferencesNotAvailable}">
                <div *dxTemplate="let data of 'content'" style="display: flex;">
                    <div class="button-container">
                        <div class="text-button-container">
                            <span class="text-button">{{'General.Preferences' | i18next}}</span>
                            <span class="sub-text-button">{{'Devices.PreferencesMessage' | i18next}}</span>
                        </div>
                        <i class="dx-icon-chevronright"></i>
                    </div>
                </div>
            </dx-button>
            <div *ngIf="isPreferencesNotAvailable" class="sub-title">{{'Preferences.PreferencesNotAvailable' | i18next}}</div>

        </div>

        <div class="button-container">

            <dx-button type="default" class="save-button animated" (onClick)="updateIntercomDetails()"
                [disabled]="isUpdatingIntercom || form.invalid || !saveDetailsButtonDisabled">
                <div *dxTemplate="let data of 'content'" style="display: flex;">
                    <dx-load-indicator class="button-indicator" height="24" width="24" [visible]="isUpdatingIntercom">
                    </dx-load-indicator>
                    <span class="dx-button-text">{{'General.Save' | i18next}}</span>
                </div>
            </dx-button>

            <dx-button type="default" class="delete-button animated" (onClick)="deleteIntercom()"
                [disabled]="isDeletingIntercom">
                <div *dxTemplate="let data of 'content'" style="display: flex;">
                    <dx-load-indicator class="button-indicator" height="24" width="24" [visible]="isDeletingIntercom">
                    </dx-load-indicator>
                    <span class="dx-button-text">{{'Devices.DeleteIntercom' | i18next}}</span>
                </div>
            </dx-button>

        </div>

    </div>

</div>