import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountPictureComponent } from './account-picture/account-picture.component';
import { SharedModule } from '../shared/shared.module';
import { DxButtonModule, DxCheckBoxModule, DxListModule, DxTextBoxModule } from 'devextreme-angular';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EditProfilePasswordComponent } from './edit-profile-password/edit-profile-password.component';
import { EditUserAttributesComponent } from './edit-user-attributes/edit-user-attributes.component';
import { AccountManager } from './services/account-manager';
import { ApiAccountManager } from './services/api-account-manager';
import { AccountService } from './services/account.service';
import { UsernamePipe } from './username.pipe';


@NgModule({
  declarations: [
    AccountPictureComponent,
    ProfileSettingsComponent,
    EditProfilePasswordComponent,
    EditUserAttributesComponent,
    UsernamePipe
  ],
  imports: [
    CommonModule,
    SharedModule,
    AngularSvgIconModule,

    DxButtonModule,
    DxTextBoxModule,
    DxListModule,
    DxCheckBoxModule
  ],
  exports: [
    AccountPictureComponent,
    UsernamePipe
  ],
  providers: [
    AccountService,
    { provide: AccountManager, useClass: ApiAccountManager }
  ]
})
export class AccountModule { }
