<div class="spinner-content">

    <div class="headline-small">{{"General.OperationInProgress" | i18next}}</div>
    <div>{{message | i18next}}</div>

    <div class="animation-row">

        <img src="assets/illustrations/pc.svg" />
        <ng-lottie [options]="options" />
        <img src="assets/illustrations/antenna.png">

    </div>

    <div class="spinner-caption">{{"General.CommunicatingWithIPEvo" | i18next}}</div>

</div>