<div id="sidebar-content">
  @if (showUnitSidebar) {
  <app-unit-sidebar
    [installationId]="installationId"
    [unitId]="unitId"
  ></app-unit-sidebar>
  } @else if (showInstallationSidebar) {
  <app-installation-sidebar
    [installationId]="installationId"
  ></app-installation-sidebar>
  } @else if (showHomeSidebar) {
  <app-home-sidebar></app-home-sidebar>
  } @else {
  <app-general-sidebar></app-general-sidebar>
  }
</div>
