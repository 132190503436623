<dx-popup [width]="540" [height]="'auto'" [maxHeight]="'80%'" [showTitle]="false" [dragEnabled]="false"
    [(visible)]="visible">

    <div id="dialog-content">

        <div id="dialog-title-bar" *ngIf="title && !titleHidden">
            <svg-icon *ngIf="icon" [src]="icon" id="icon"></svg-icon>
            <span class="headline-small">{{title | i18next}}</span>
        </div>

        <ng-template #container></ng-template>

    </div>

</dx-popup>