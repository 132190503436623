import { Component, Input } from "@angular/core";
import { BaseDialogContent } from "../../dialogs/base-dialog-component";
import { DialogsService } from "../../dialogs/services/dialogs.service";
import { User } from "../../shared/models/users/user";
import {
  AccessLevel,
  isInstallerAccess,
} from "../../shared/models/users/access-level";
import { openCloseAnimation } from "../../dialogs/animations/show-hide.animation";

@Component({
  selector: "app-installation-user-dialog",
  templateUrl: "./installation-user-dialog.component.html",
  styleUrl: "./installation-user-dialog.component.scss",
  animations: [openCloseAnimation],
})
export class InstallationUserDialogComponent extends BaseDialogContent {
  @Input()
  user?: User;

  invitationRoles = [
    {
      title: "General.Manager",
      subtitle: "Invitations.ManagerDetails",
      value: AccessLevel.MANAGER,
    },
    {
      title: "General.Installer",
      subtitle: "Invitations.InstallerDetails",
      value: AccessLevel.INSTALLER,
    },
  ];

  selectedRole: any = this.invitationRoles[0].value;

  constructor(dialogs: DialogsService) {
    super(dialogs);
  }

  override passData(data: any): void {
    this.user = data as User;
    this.selectedRole = (
      isInstallerAccess(this.user?.access_level)
        ? this.invitationRoles[1]
        : this.invitationRoles[0]
    ).value;
  }
}
