<div class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">
  <div>{{ "Installations.AddLicenseMessage" | i18next }}</div>

  <dx-text-box
    [(value)]="licenseKey"
    [label]="'General.Key' | i18next"
    labelMode="static"
    stylingMode="outlined"
    valueChangeEvent="input"
    [showClearButton]="true"
    (valueChange)="validateForm()"
    class="full-width"
  >
  </dx-text-box>

  <div class="dialog-action-bar">
    <dx-button
      [text]="'General.Cancel' | i18next"
      (onClick)="closeDialog()"
      stylingMode="text"
    >
    </dx-button>
    <dx-button
      [text]="'General.Add' | i18next"
      type="default"
      [disabled]="!canAddKey"
      (onClick)="addLicense()"
    >
    </dx-button>
  </div>
</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
  <app-spinner [message]="'Installations.ApiActivatingLicenseMessage'"></app-spinner>
</div>
