<div class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">

    <dx-text-box [(value)]="unitName" [label]="'General.Name' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Units.NamePlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-text-box [(value)]="unitAlias" [label]="'Units.Alias' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Units.AliasPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>


    <dx-text-box [(value)]="unitLocation" [label]="'Units.Location' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Units.LocationPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-text-box [(value)]="unitNotes" [label]="'General.Notes' | i18next" labelMode="static" stylingMode="outlined"
        [placeholder]="'Units.LocationPlaceholder' | i18next" valueChangeEvent="keyup" [showClearButton]="true"
        (onValueChanged)="updateConfirmButtonState()" class="full-width">
    </dx-text-box>

    <dx-tree-list dataStructure="tree" [dataSource]="installationDevices" keyExpr="id" itemsExpr="children"
        [showColumnHeaders]="false" [hoverStateEnabled]="true" [autoExpandAll]="true"
        [(selectedRowKeys)]="unitDevicesAndOutputs" style="height: 100%;"
        (onSelectionChanged)="permissionSelectionChanged($event)">
        <dxi-column dataField="name"></dxi-column>
        <dxo-selection mode="multiple"></dxo-selection>
    </dx-tree-list>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" [disabled]="!createButtonEnabled"
            (onClick)="createUnit()">
        </dx-button>
    </div>

</div>


<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Units.CreateWaitMessage'"></app-spinner>
</div>