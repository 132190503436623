<div class="dialog-vertial-layout" [@openClose]="isBusy ? 'closed' : 'open'">
  <div class="row-with-label full-width">
    <dx-text-box
      class="full-width"
      [value]="email"
      [label]="'General.Email' | i18next"
      labelMode="static"
      stylingMode="outlined"
      [placeholder]="'Invitations.EmailsPlaceholder' | i18next"
      [disabled]="true"
    >
    </dx-text-box>
  </div>

  <div class="row-with-label full-width">
    <dx-text-box
      class="full-width"
      [value]="note"
      [label]="'General.Notes' | i18next"
      labelMode="static"
      stylingMode="outlined"
      [placeholder]="'Invitations.EmptyNote' | i18next"
      [disabled]="true"
    >
    </dx-text-box>
  </div>

  <div id="dialog-action-bar">
    <dx-button
      [text]="'Invitations.DeleteInvitation' | i18next"
      type="danger"
      stylingMode="outlined"
      (onClick)="deleteInvitation()"
      style="margin-right: auto"
    >
    </dx-button>

    <dx-button
      [text]="'General.Cancel' | i18next"
      (onClick)="closeDialog()"
      stylingMode="text"
    >
    </dx-button>

    <dx-button
      [text]="'Invitations.Resend' | i18next"
      type="default"
      (onClick)="resend()"
      [disabled]="expired"
    >
    </dx-button>
  </div>
</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
  <app-spinner [message]="spinningMessage"></app-spinner>
</div>
