<div [@openClose]="isLoading ? 'closed' : 'open'" class="dialog-vertial-layout">

    <div class="row-with-label">
        <div class="header">
            <svg-icon src="assets/icons/ic_lock.svg" id="header-icon"></svg-icon>
            <span id="header-title">{{'Account.UpdatePassword' | i18next}}</span>
        </div>
        <span class="label">{{'Account.UpdatePasswordMessage' | i18next}}</span>
    </div>

    <dx-text-box class="full-width" [label]="'Auth.OldPassword' | i18next" labelMode="static"
        [placeholder]="'Account.InsertOldPassword' | i18next" [mode]="codeFieldMode" [(value)]="oldPassword"
        valueChangeEvent="keyup" stylingMode="outlined">
        <dxi-button name="toggleVisibility" location="after" [options]="codeButtonOptions">
        </dxi-button>
    </dx-text-box>

    <dx-text-box class="full-width" [label]="'Auth.NewPassword' | i18next" labelMode="static"
        [placeholder]="'Account.InsertNewPassword' | i18next" [mode]="codeFieldMode" [(value)]="newPassword"
        valueChangeEvent="keyup" stylingMode="outlined" (onValueChanged)="updatePasswordRules()">
        <dxi-button name="toggleVisibility" location="after" [options]="codeButtonOptions">
        </dxi-button>
    </dx-text-box>

    <dx-text-box class="full-width" [label]="'Auth.RepeatPassword' | i18next" labelMode="static"
        [placeholder]="'Account.InsertRepeatPassword' | i18next" [mode]="codeFieldMode" [(value)]="repeatNewPassword"
        valueChangeEvent="keyup" stylingMode="outlined">
        <dxi-button name="toggleVisibility" location="after" [options]="codeButtonOptions">
        </dxi-button>
    </dx-text-box>

    <div class="content-list" *ngFor="let rule of passwordRules">
        <span class="label">{{ rule.description }}</span>
        <dx-check-box [activeStateEnabled]="false" [focusStateEnabled]="false" [readOnly]="true" [value]="rule.isValid">
        </dx-check-box>
    </div>

    <div class="row-with-label">
        <span class="label"> {{'General.RequiredFieldDescription' | i18next}}</span>
    </div>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back()">
        </dx-button>
        <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="updatePassword()"
            [disabled]="!oldPassword || !newPassword || !repeatNewPassword || newPassword != repeatNewPassword || !allRulesValid()">
        </dx-button>
    </div>

</div>

<div *ngIf="isLoading" [@openClose]="'open'" class="dialog-spinner-container">
    <app-spinner [message]="'Account.PasswordConfigureWaitMessage'"></app-spinner>
</div>