import { Component } from '@angular/core';
import { BasePageComponent } from '../../shared/components/base-page.component';
import { NavigationService, ScreenService, LocalizerService } from '../../shared';
import { AuthenticationManager } from '../../shared/services/authentication-manager';

@Component({
  selector: 'app-confirm-signup',
  templateUrl: './confirm-signup.component.html',
  styleUrl: './confirm-signup.component.scss'
})
export class ConfirmSignupComponent extends BasePageComponent {

  email: string = this.auth.currentSignUpUserEmail ?? "";
  verificationCode: string = "";

  constructor(private auth: AuthenticationManager,
    private navigation: NavigationService,
    localizer: LocalizerService,
    screen: ScreenService) {
    super(screen, localizer);
  }

  completeSignUp() {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    this.auth.confirmSignUp(this.email, this.verificationCode).subscribe({
      next: this.signUpCompleted.bind(this),
      error: this.signUpFailed.bind(this)
    });
  }

  private signUpCompleted() {
    this.isBusy = false;
    this.displaySuccessNotification(this.localizer.getLocalizedString("Auth.CompleteSignUpSuccess"));
    this.navigation.navigateToLogin();
  }

  private signUpFailed(error: any) {
    console.log(error);
    this.isBusy = false;
    const message = (typeof error === "string") ? error : null;
    this.displayError(message);
  }

}
