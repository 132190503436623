<dx-popup *ngIf="user" [width]="520" [height]="580" [showTitle]="false" [dragEnabled]="false" container=".dx-viewport"
    [hideOnOutsideClick]="true" [(visible)]="isVisible" (onHiding)="closePopup()">

    <div class="popup-content">

        <div class="popup-title">{{ "General.Responsible" | i18next }}</div>

        <i class="dx-icon-user"></i>
        <div class="email">{{ user!.email }}</div>

        <div class="text-box-title">{{ 'Auth.Role' | i18next }}</div>

        <dx-radio-group [items]="invitation_roles" displayExpr="title" [value]="getMatchingRole(user!.access_level)"
            [disabled]="true" class="radio-group">
            <div *dxTemplate="let data of 'item'">
                <div class="radio-item">
                    <div class="radio-item-title">{{ data.title }}</div>
                    <div class="radio-item-subtitle">{{ data.subtitle }}</div>
                </div>
            </div>
        </dx-radio-group>

    </div>

</dx-popup>