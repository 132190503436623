<div class="page">
  <app-widget-card
    height="200px"
    icon="assets/icons/ic_language.svg"
    [title]="'Languages.ApplicationLanguage' | i18next"
  >
    <div class="flex center justify-center full-height gap-16">
      <span class="text-xl">{{ "Languages.SelectLanguage" | i18next }}</span>
      @for (item of languages; track $index) {
      <img
        [src]="item.icon"
        class="language"
        [title]="item.name"
        [ngClass]="{ selected: item.ISOCode == currentLanguage.ISOCode }"
        (click)="selectLanguage(item)"
      />
      }
    </div>
  </app-widget-card>

  <div class="two-column-layout">
    <div class="left-column">
      <app-widget-card
        height="400px"
        icon="assets/icons/ic_web.svg"
        [title]="'ApplicationInfo.FrontendVersion' | i18next"
      >
        <div class="flex-col center justify-center full-height gap-16">
          <div class="software-version text-accent">
            {{ applicationVersion.version }}
          </div>
          <div class="flex gap-32">
            <span class="text-gray"
              >{{ "ApplicationInfo.BasedOn" | i18next }}
              <app-label
                [text]="applicationVersion.runtime"
                [color]="0 | labelBackground"
              ></app-label>
            </span>
            <span class="text-gray"
              >{{ "ApplicationInfo.ReleaseDate" | i18next }}
              <app-label
                [text]="
                  (applicationVersion.releaseDate | date : 'medium') ?? ''
                "
                [color]="1 | labelBackground"
              ></app-label>
            </span>
          </div>
        </div>
      </app-widget-card>
    </div>
    <div class="right-column">
      <app-widget-card
        height="400px"
        icon="assets/icons/ic_cloud.svg"
        [title]="'ApplicationInfo.BackendVersion' | i18next"
      >
        <div class="flex-col center justify-center full-height gap-16">
          <div class="software-version text-accent">
            v.{{ serverVersion?.release }}
          </div>
          <div class="flex gap-32">
            <span class="text-gray">
              {{ "ApplicationInfo.BasedOn" | i18next }}
              <app-label
                [text]="serverVersion?.environment ?? ''"
                [color]="2 | labelBackground"
              ></app-label>
            </span>
            <span class="text-gray"
              >{{ "ApplicationInfo.ReleaseDate" | i18next }}
              <app-label
                [text]="(serverVersion?.release_date | date : 'medium') ?? ''"
                [color]="3 | labelBackground"
              ></app-label>
            </span>
          </div>
        </div>
      </app-widget-card>
    </div>
  </div>
</div>

<!-- "ApplicationInfo": {
    "FrontendVersion": "Frontend version",
    "BackendVersion": "Backend version",
    "ReleaseDate": "Released on",
    "BasedOn": "Runtime"
} -->
