<div *ngIf="!activeSection" class="dialog-vertial-layout" [@openClose]="isBusy || askForDeletion ? 'closed' : 'open'">

    <div class="horizontal-flex-row full-width">
        <div id="image-container">
            <app-account-picture [image]="imageContent"></app-account-picture>
        </div>

        <dx-button [text]="'Account.DeleteAccout' | i18next" type="danger" stylingMode="outlined"
            (onClick)="deleteAccount()">
        </dx-button>
    </div>

    <div class="row-with-label">
        <dx-button class="profile-picture" type="default" stylingMode="outlined"
            [text]="'Account.ProfileImage' | i18next" icon="refresh" (onClick)="openFinderDialog()">
        </dx-button>
        <input type="file" #fileInput accept="image/*" style="display:none" (change)="onFileSelected($event)" />
    </div>

    <div class="content full-width">

        <div class="container full-width">
            <div class="box">
                <svg-icon src="assets/icons/ic_email.svg" class="profile-icons"></svg-icon>
                <span id="box-title">{{'General.Email' | i18next}}</span>
            </div>
            <span class="label">{{currentUserAttributes?.email}}</span>
        </div>

        <div class="container full-width">
            <div class="box">
                <svg-icon src="assets/icons/ic_lock.svg" class="profile-icons"></svg-icon>
                <span id="box-title">{{'Auth.Password' | i18next}}</span>
            </div>

            <dx-button type="default" stylingMode="outlined" [text]="'General.Edit' | i18next"
                (onClick)="toggleSection('password')">
            </dx-button>
        </div>

        <div class="container full-width">
            <div class="row-with-label">
                <div class="box">
                    <svg-icon src="assets/icons/ic_user.svg" class="profile-icons"></svg-icon>
                    <span id="box-title">{{'Account.ProfileName' | i18next}}</span>
                </div>
                <span class="label">{{name}} {{familyName}}</span>
            </div>

            <dx-button type="default" stylingMode="outlined" [text]="'General.Edit' | i18next"
                (onClick)="toggleSection('attributes')">
            </dx-button>
        </div>
    </div>

    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="closeDialog()" stylingMode="text">
        </dx-button>
    </div>

</div>

<div *ngIf="activeSection == 'password'" class="dialog-settings-container">
    <app-edit-profile-password (backEvent)="toggleSection()"></app-edit-profile-password>
</div>

<div *ngIf="activeSection == 'attributes'" class="dialog-settings-container">
    <app-edit-user-attributes [name]="name" [familyName]="familyName"
        (backEvent)="userAttributesUpdated($event)"></app-edit-user-attributes>
</div>

<!-- Delete account content -->
<div class="dialog-vertial-layout" [@openClose]="askForDeletion && !isBusy ? 'open' : 'closed'">
    <svg-icon src="assets/icons/ic_warning.svg" class="warning-icon"></svg-icon>
    <div class="headline-small">{{"General.Attention" | i18next}}</div>
    <div>{{"Account.DeleteAccountMessage" | i18next}}</div>
    <div class="dialog-action-bar">
        <dx-button [text]="'General.Cancel' | i18next" (onClick)="back()" stylingMode="text">
        </dx-button>
        <dx-button [text]="'General.Delete' | i18next" type="danger" (onClick)="completeAccountRemoval()">
        </dx-button>
    </div>
</div>

<div [@openClose]="isBusy ? 'open' : 'closed'" class="dialog-spinner-container">
    <app-spinner [message]="'Account.AccountRemovalWaitMessage'"></app-spinner>
</div>