import { Subscription } from "rxjs";
import { ScreenService } from "../services/screen.service";

export class BaseResponsiveComponent {

    protected _screenSubscription: Subscription | null = null;

    constructor(protected screenSizeService: ScreenService) { }

    protected listenScreenSizeChanges() {
        this._screenSubscription = this.screenSizeService.onScreenSizeChange().subscribe(() => this.checkScreenSize());
        setTimeout(() => this.checkScreenSize(), 1);
    }

    protected checkScreenSize() { }

    protected stopListeningScreenSizeChanges(): void {
        this._screenSubscription?.unsubscribe();
    }
}