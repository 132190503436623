import { Injectable } from "@angular/core";
import { InstallationLicensesDto } from "./dto/installation-licenses-dto";
import { Observable, throwError } from "rxjs";
import { BaseApiService } from "../shared/utils/base-api-service";

@Injectable()
export class LicensesManagerService extends BaseApiService {
  constructor() {
    super();
  }

  getInstallationLicenses(
    installationId: string
  ): Observable<InstallationLicensesDto> {
    if (!installationId) {
      return throwError(() => new Error("Invalid installation id"));
    }

    const endpoint = `/installations/${installationId}/licenses`;
    return this.get<InstallationLicensesDto>(endpoint);
  }

  activateLicense<License>(installationId: string, licenseKey: string) {
    if (!installationId) {
      return throwError(() => new Error("Invalid installation id"));
    }

    if (!licenseKey) {
      return throwError(() => new Error("Invalid license key"));
    }

    const endpoint = `/installations/${installationId}/licenses`;
    return this.post<License>(endpoint, { license_id: licenseKey });
  }

  getUserLicenses(): Observable<InstallationLicensesDto[]> {
    return this.get<InstallationLicensesDto[]>("/licenses");
  }
}
