@if (showAreaPlaceholder) {

<div class="area-placeholder">
    <div class="title"></div>
    <div class="actions">
    </div>
</div>

} @else {

<div class="page-header">

    <div class="page-title-container">
        <div class="pre-page-title">
            <ng-content select="[pre-title-view]"></ng-content>
            <div class="page-title">{{title}}</div>
        </div>
        <div class="actions-container">
            <ng-content></ng-content>
        </div>
    </div>

    <div class="infos location-infos" *ngIf="locationInfo && locationInfoIcon">
        <svg-icon [src]="locationInfoIcon" class="icon"></svg-icon>
        <div>{{locationInfo}}</div>
    </div>

    <div class="infos" *ngIf="info && infoIcon">
        <svg-icon [src]="infoIcon" class="icon"></svg-icon>
        <div>{{info}}</div>
    </div>

</div>

}