<div [@openClose]="isLoading ? 'closed' : 'open'" *ngIf="!isBusy">
    <div *ngIf="!isLoading && !configureDeviceSettings" class="dialog-vertial-layout">

        <div class="header">
            <svg-icon src="assets/icons/ic_input.svg" id="header-icon"></svg-icon>
            <span id="header-title">{{'General.Inputs' | i18next}}</span>
        </div>

        <div class="row-with-label">
            <span class="row-title">{{'General.NotConfigured' | i18next}}</span>
            <span class="label">{{'Inputs.NotConfiguredMessage' | i18next}}</span>
        </div>

        @if(notConfiguredDeviceInputs.length > 0) {
        <dx-list id="dataConfigurationList" [dataSource]="notConfiguredDeviceInputs" keyExpr="address"
            [hoverStateEnabled]="true" (onItemClick)="showInputActionPopup($event)" style="height: 100%;"
            itemTemplate="template">
            <div *dxTemplate="let data of 'template'" class="cell">
                <div class="cell-box">
                    <svg-icon src="assets/icons/ic_key.svg" id="key-icon"></svg-icon>
                    <div class="row-with-label">
                        <span class="title">{{'Inputs.ExternalButton' | i18next}}</span>
                        <span class="label">{{data.address}}</span>
                    </div>
                </div>
                <svg-icon src="assets/icons/ic_arrow_bottom.svg" class="action-icon"></svg-icon>
            </div>
        </dx-list>
        } @else {
        <div class="no-data-message">
            {{'Inputs.NoInputsToConfigure' | i18next}}
        </div>
        }

        <div class="row-with-label">
            <span class="row-title">{{'General.Configured' | i18next}}</span>
            <span class="label">{{'Inputs.ConfiguredMessage' | i18next}}</span>
        </div>

        @if(configuredDeviceInputs.length > 0) {
        <dx-list id="dataConfigurationList" [dataSource]="configuredDeviceInputs" keyExpr="address"
            [hoverStateEnabled]="true" (onItemClick)="showInputActionPopup($event)" style="height: 100%;"
            itemTemplate="template">
            <div *dxTemplate="let data of 'template'" class="cell">
                <div class="cell-box">
                    <svg-icon src="assets/icons/ic_key.svg" id="key-icon"></svg-icon>
                    <div class="row-with-label">
                        <span class="title">{{'Inputs.ExternalButton' | i18next}}</span>
                        <span class="label">{{data.address}}</span>
                    </div>
                </div>
                <svg-icon src="assets/icons/ic_arrow_bottom.svg" class="action-icon"></svg-icon>
            </div>
        </dx-list>
        } @else {
        <div class="no-data-message">
            {{'Inputs.NoInputsConfigured' | i18next}}
        </div>
        }

        <div class="dialog-action-bar">
            <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back()">
            </dx-button>
        </div>

    </div>

    <div *ngIf="!isLoading && configureDeviceSettings" class="dialog-vertial-layout">

        <div class="horizontal-flex-row full-width">

            <div class="header">
                <svg-icon src="assets/icons/ic_configure.svg" id="header-icon"></svg-icon>
                <div class="row-with-label">
                    <span class="row-title">{{'Inputs.ConfigureInput' | i18next}}</span>
                    <span class="label">{{'Inputs.ExternalButton' | i18next}}</span>
                </div>
            </div>

            @if(selectedDeviceInput?.action && !isLoading) {
            <dx-button [text]="'Inputs.DeleteConfiguration' | i18next" type="danger" stylingMode="outlined"
                (onClick)="deleteDeviceInput()">
            </dx-button>
            }
        </div>

        <dx-text-box class="full-width" [value]="'Inputs.' + capitalizeFirstLetter(selectedDeviceInput?.kind) | i18next"
            [label]="'General.Typology' | i18next" labelMode="static" valueChangeEvent="keyup" stylingMode="outlined"
            [disabled]="true">
        </dx-text-box>

        <div class="row-with-label">
            <span class="title">{{'Inputs.Action' | i18next}}</span>
            <span class="label">{{'Inputs.ConfigureInputActionsMessage' | i18next}}</span>
        </div>

        <dx-data-grid *ngIf="deviceOutputs.length > 0" keyExpr="address" [dataSource]="deviceOutputs"
            [hoverStateEnabled]="true" [showBorders]="false" [showColumnHeaders]="false"
            [selectedRowKeys]="getSelectedRowKeys()" (onSelectionChanged)="onSelectionChanged($event)">
            <dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>
            <dxi-column dataField="name"></dxi-column>
        </dx-data-grid>


        <div class="dialog-action-bar">
            <dx-button [text]="'General.Back' | i18next" stylingMode="text" (onClick)="back()">
            </dx-button>
            <dx-button [text]="'General.Save' | i18next" type="default" (onClick)="addDeviceInput()"
                [disabled]="selectedActions.length == 0">
            </dx-button>
        </div>

    </div>
</div>

<ng-lottie *ngIf="isBusy && !isLoading" [options]="options"></ng-lottie>

<div *ngIf="isLoading" [@openClose]="'open'" class="dialog-spinner-container">
    <app-spinner [message]="'Inputs.InputConfigureWaitMessage'"></app-spinner>
</div>