import { Component, EventEmitter, Output } from '@angular/core';
import { openCloseAnimation } from '../../dialogs/animations/show-hide.animation';
import { LocalizerService } from '../../shared';
import { CognitoAuthenticationManager } from '../../shared/services/cognito-authentication-manager';
import { finalize } from 'rxjs';
import { DialogsService } from '../../dialogs/services/dialogs.service';

@Component({
  selector: 'app-edit-profile-password',
  templateUrl: './edit-profile-password.component.html',
  styleUrl: './edit-profile-password.component.scss',
  animations: [openCloseAnimation]
})
export class EditProfilePasswordComponent {

  @Output() backEvent: EventEmitter<void> = new EventEmitter<void>();

  oldPassword: string = '';
  newPassword: string = '';
  repeatNewPassword: string = '';

  isLoading: boolean = false;

  codeFieldMode: "password" | "text" = "password";
  codeButtonOptions = {
    icon: 'eyeopen',
    stylingMode: 'text',
    onClick: () => {
      this.codeFieldMode = this.codeFieldMode === "password" ? "text" : "password";
    },
  };

  passwordRules = [
    { description: this.localizer.getLocalizedString('Account.MustBeAtLeast8Characters'), isValid: false, validate: (password: string) => password.length >= 8 },
    { description: this.localizer.getLocalizedString('Account.MustContainUppercase'), isValid: false, validate: (password: string) => /[A-Z]/.test(password) },
    { description: this.localizer.getLocalizedString('Account.MustContainNumber'), isValid: false, validate: (password: string) => /[0-9]/.test(password) },
    { description: this.localizer.getLocalizedString('Account.MustContainSpecialCharacter'), isValid: false, validate: (password: string) => /[\W_]/.test(password) }
  ];

  constructor(private localizer: LocalizerService,
    private auth: CognitoAuthenticationManager,
    private dialogs: DialogsService) { }

  updatePassword() {
    if (!this.oldPassword || !this.newPassword) {
      return;
    }

    this.isLoading = true;
    this.auth.updatePassword(this.oldPassword, this.newPassword)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe({
        next: _ => {
          this.dialogs.displaySuccessNotification('Account.ApiEditPasswordSuccess');
          this.back();
        },
        error: _ => this.dialogs.displayErrorNotification('Account.ApiEditPasswordError')
      });
  }

  // #region Password rules

  /**
   * Checks if all password rules are valid.
   * @returns True if all rules are valid, false otherwise.
   */
  allRulesValid(): boolean {
    return this.passwordRules.every(rule => rule.isValid);
  }

  /**
   * Updates the validity of each password rule.
   */
  updatePasswordRules() {
    this.passwordRules = this.passwordRules.map(rule => ({
      ...rule,
      isValid: rule.validate(this.newPassword)
    }));
  }

  // #endregion

  back() {
    this.backEvent.emit();
  }

}
