import { Inject, Injectable } from "@angular/core";
import { I18NEXT_SERVICE, ITranslationService } from "angular-i18next";
import { ApplicationLanguage } from "../utils/application-language";
import { Router } from "@angular/router";

@Injectable()
export class LocalizerService {
  constructor(
    @Inject(I18NEXT_SERVICE) private internationalization: ITranslationService,
    private router: Router
  ) {}

  getLocalizedString(key: string, interpolationValues: any = null): string {
    if (!key) {
      throw new Error(
        "TranslatorService: missing mandatory parameter key for getLocalizedString"
      );
    }

    if (interpolationValues) {
      return this.internationalization.t(key, interpolationValues).toString();
    } else {
      return this.internationalization.t(key).toString();
    }
  }

  currentLanguage(): string {
    return this.internationalization.language;
  }

  changeLanguage(language: string) {
    if (!language) {
      return;
    }

    if (language !== this.currentLanguage()) {
      let url;
      if (`/${this.currentLanguage()}` == this.router.url.substring(0, 3)) {
        url = language + this.router.url.substring(3, this.router.url.length);
      } else {
        url = language + this.router.url;
      }
      
      window.location.href = url;
    }
  }

  applicationLanguages(): ApplicationLanguage[] {
    return [
      { ISOCode: "it", icon: "assets/icons/ic_italian.svg", name: "Italiano" },
      { ISOCode: "en", icon: "assets/icons/ic_english.svg", name: "English" },
    ];
  }

  currentApplicationLanguage(): ApplicationLanguage {
    const languages = this.applicationLanguages();
    const currentLanguage = this.currentLanguage();
    return languages.find((l) => l.ISOCode == currentLanguage) || languages[0];
  }
}
