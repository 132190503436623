import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent implements OnInit {

  showGeneralSidebar = true;
  showInstallationSidebar = false;
  showUnitSidebar = false;

  installationId: string | null = null;
  unitId: string | null = null;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => this.displayNewUI(event.url));
  }

  private displayNewUI(url: string) {
    const unitSidebarRegex = /.*\/installations\/[^\/]+\/units\/[^\/]+$/;
    this.showUnitSidebar = unitSidebarRegex.test(url);
    const installationSidebarRegex = /.*\/installations\/[^\/]+$/;
    this.showInstallationSidebar = installationSidebarRegex.test(url) && !this.showUnitSidebar;
    this.showGeneralSidebar = !this.showInstallationSidebar && !this.showUnitSidebar;
    this.updatePathParams(url);
  }

  private updatePathParams(url: string) {
    const parts = url?.split("/");
    this.installationId = (parts?.length >= 4) ? parts[3] : null;
    this.unitId = (this.showUnitSidebar && parts?.length >= 6) ? parts[5] : null;
  }

}
