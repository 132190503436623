import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shimmer-overlay',
  templateUrl: './shimmer-overlay.component.html',
  styleUrl: './shimmer-overlay.component.scss'
})
export class ShimmerOverlayComponent {

}
