import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { InvitationsModule } from "../invitations/invitations.module";
import { InstallationsComponent } from "./installations/installations.component";
import { InstallationComponent } from "./installation/installation.component";
import { InstallationsManager } from "./services/installations-manager";
import { ApiInstallationsManager } from "./services/api-installations-manager";

import {
  DxTileViewModule,
  DxScrollViewModule,
  DxBoxModule,
  DxToolbarModule,
  DxButtonGroupModule,
  DxRadioGroupModule,
  DxTagBoxModule,
} from "devextreme-angular";
import {
  DxTooltipModule,
  DxButtonModule,
  DxDataGridModule,
  DxListModule,
  DxLoadIndicatorModule,
  DxMapModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTextBoxModule,
} from "devextreme-angular";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ReactiveFormsModule } from "@angular/forms";
import { UnitsModule } from "../units/units.module";
import { DevicesModule } from "../devices/devices.module";
import { AccountModule } from "../account/account.module";
import { EditInstallationDialogComponent } from "./edit-installation-dialog/edit-installation-dialog.component";
import { ImportUnitsDialogComponent } from "./import-units-dialog/import-units-dialog.component";
import { CsvImportService } from "./services/csv-import.service";
import { ImportResultComponent } from "./import-result/import-result.component";
import { ImportErrorStatusPipe } from "./pipes/import-error-status.pipe";
import { InstallationUnitsPipe } from "./pipes/installation-units.pipe";
import { EditLocationDialogComponent } from "./edit-location-dialog/edit-location-dialog.component";
import { CreateInstallationDialogComponent } from "./create-installation-dialog/create-installation-dialog.component";
import { LicensesModule } from "../licenses/licenses.module";
import { InstallationUserDialogComponent } from "./installation-user-dialog/installation-user-dialog.component";
import { HasLicensePipe } from "./pipes/has-license.pipe";
import { FilterByDevicePipe } from './pipes/filter-by-device.pipe';
import { InstallerAccessPipe } from './pipes/installer-access.pipe';

@NgModule({
  declarations: [
    InstallationsComponent,
    InstallationComponent,
    EditInstallationDialogComponent,
    ImportUnitsDialogComponent,
    ImportResultComponent,
    EditLocationDialogComponent,
    CreateInstallationDialogComponent,
    InstallationUserDialogComponent,
    ImportErrorStatusPipe,
    InstallationUnitsPipe,
    HasLicensePipe,
    FilterByDevicePipe,
    InstallerAccessPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    InvitationsModule,
    AccountModule,
    UnitsModule,
    DevicesModule,
    LicensesModule,
    AngularSvgIconModule,
    ReactiveFormsModule,
    DxBoxModule,
    DxDataGridModule,
    DxListModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxLoadIndicatorModule,
    DxMapModule,
    DxTextBoxModule,
    DxPopupModule,
    DxTileViewModule,
    DxScrollViewModule,
    DxToolbarModule,
    DxButtonGroupModule,
    DxTooltipModule,
    DxRadioGroupModule,
    DxTagBoxModule
  ],
  exports: [InstallationsComponent],
  providers: [
    { provide: InstallationsManager, useClass: ApiInstallationsManager },
    CsvImportService,
  ],
})
export class InstallationsModule {}
